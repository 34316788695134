import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary:'#26415d',
                secondary: '#2D3753',
                accent: '#3AE3D6',
                error: '#f44336',
                info: '#2d3753',
                success: '#7a904a',
                warning: '#f78012',
                sari:'#2E8B57',
                kahverengi:'#3b2313'

            },
        },
    },
});

