<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col>
            <v-text-field 
              v-model="searchKey"
              cache-items
              flat
              hide-no-data
              hide-details
              label="Aramak istediğiniz ürünü ekleyin..."
              solo-inverted
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
              large
              class="float-right brosurBtn mr-1"
              color="indigo darken-4"
              dark
              tile
              @click="newBrosur"
            >
              Broşür Oluştur
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense>
      <v-snackbar
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        center
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-row>
    <v-row dense class="mt-2">
      <v-col
        cols="6"
        sm="6"
        md="4"
        lg="3"
        v-for="product in filteredList"
        :key="product.id"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card tile color="grey lighten-4">
            <v-img
              :aspect-ratio="16 / 9"
              class="productImage"
              :src="product.blobUrl"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="
                    d-flex
                    transition-fast-in-fast-out
                    sari
                    v-card--reveal
                    display-3
                    white--text
                  "
                  style="height: 100%"
                >
                  <v-btn
                    class="ma-2"
                    tile
                    color="black"
                    dark
                    @click="selectedProduct(product)"
                  >
                    Ekle
                  </v-btn>
                </div>
              </v-expand-transition>
            </v-img>
            <v-card-text style="position: relative">
              <v-row no-gutters class="productImageRow">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="12" class="text-center">
                      <h3 class="text-center productUrunadi">
                        {{ product.urunadi }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider class="mt-1"></v-divider>
              <v-row no-gutters>
                <v-col cols="12" class="mt-1">
                  <h2 class="text-center">
                    <strong
                      class="font-weight-black productFiyat text-center"
                      style="font-weight: older"
                    >
                      {{ product.fiyat }} {{ product.paraBirimi }}
                    </strong>
                  </h2>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-dialog v-model="editTitleDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Başlığı Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="editTitleDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="brosurTitle"
                    label="Yeni Başlık"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">Yazı Boyutu</v-col>
                <v-col cols="6">
                  <input type="number" value="17" @input="changeFontSize" />
                </v-col>
                <v-col cols="6"><label>Yazı Rengi</label></v-col>
                <v-col cols="6">
                  <input
                    id="color-choos"
                    type="color"
                    name="color"
                    value="#FFED00"
                    @input="changeColor"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="adresKaydet()"
            :loading="adresButon"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="adresEditDiaog" max-width="800">
      <v-card>
        <v-card-title class="headline">
          Adres Bilgisi Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="adresEditDiaog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" lg="6">
                  <v-textarea
                    v-model="adres.alan1"
                    color="sari"
                    outlined
                    name="input-7-4"
                    label="Adres"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="6">Yazı Boyutu</v-col>
                    <v-col cols="6">
                      <input
                        type="number"
                        value="17"
                        @input="changeFontAdresAlan1"
                      />
                    </v-col>
                    <v-col cols="6"><label>Yazı Rengi</label></v-col>
                    <v-col cols="6">
                      <input
                        id="color-choos"
                        type="color"
                        name="color"
                        value="#000000"
                        @input="changeColorAdresAlan1"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    label="Numara"
                    v-model="adres.alan8"
                    color="sari"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="6">Yazı Boyutu</v-col>
                    <v-col cols="6">
                      <input
                        type="number"
                        value="17"
                        @input="changeFontAdresAlan8"
                      />
                    </v-col>
                    <v-col cols="6"><label>Yazı Rengi</label></v-col>
                    <v-col cols="6">
                      <input
                        id="color-choos"
                        type="color"
                        name="color"
                        value="#000000 "
                        @input="changeColorAdresAlan8"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="adresKaydet()"
            :loading="adresButon"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <!-- A1 Broşür-->
      <v-dialog v-model="brosurDialog" max-width="800">
        <v-card>
          <v-card-title class="headline">
            Boyutlar

            <v-spacer></v-spacer>
            <div id="editor"></div>
            <v-btn
              id="cmd"
              tile
              color="success"
              class="ma-2 white--text"
              @click="makePDF"
              :loading="loaderBrosur"
            >
              İNDİR
              <v-icon right dark> mdi-cloud-download </v-icon>
            </v-btn>
            <v-btn icon color="grey" text @click="brosurDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-chip-group
            v-model="selection"
            active-class="deep-purple--text text--accent-4"
            mandatory
            class="ml-3 mb-3"
          >
            <v-chip @click="boyutA4Export">A4</v-chip>
            <v-chip @click="boyutA5Export">A5</v-chip>
            <!-- 
              <img
              style="width: 40px; height: 40px"
              src="https://img.icons8.com/fluency/48/000000/instagram-new.png"
              @click="boyutInstagramExport"
            />
            <img
              style="width: 40px; height: 40px"
              src="https://img.icons8.com/color/48/000000/facebook.png"
              @click="boyutFacebookExport"
            />
            -->
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
          </v-chip-group>
          <v-card-text
            class="d-flex justify-center"
            id="test_1"
            :style="{ height: brosurBoyutHeight }"
          >
            <div
              id="capture"
              ref="capture"
              :style="{ width: brosurBoyutWidth, height: brosurBoyutHeight }"
            >
              <v-card  v-if="selectedProducts.length > 0">
                <v-img
                  class="brosurBackground"
                  :src="brosurBackground.fileName"
                >
                  <v-container fluid>
                    <v-row dense>
                      <v-flex
                        :class="{
                          brosurA4: brosurA4Class,
                          brosurA5: brosurA5Class,
                        }"
                        cols="12"
                      >
                        <a class="d-flex justify-center text-center">
                          <vue-draggable-resizable
                            w="500px"
                            h="400px"
                            :lock-aspect-ratio="true"
                            :resizable="true"
                          >
                            <h2
                              class="
                                font-weight-black
                                brosurTitle
                                d-flex
                                justify-center
                                text-center
                              "
                              :style="{ 'font-size': fontSize, color: color }"
                              @dblclick="editTitle"
                              v-text="brosurTitle"
                            ></h2>
                          </vue-draggable-resizable>
                        </a>
                      </v-flex>
                      <v-flex v-if="cubuk">
                        <vue-draggable-resizable
                          w="500px"
                          h="100px"
                          :lock-aspect-ratio="true"
                          :resizable="true"
                        >
                          <v-img
                            :class="{
                              cubukA4: cubukA4Class,
                              cubukA5: cubukA5Class,
                            }"
                            :height="cubukHeight"
                            src="../../assets/img/brosur-cubuk.png"
                          ></v-img>
                        </vue-draggable-resizable>
                      </v-flex>
                      <v-col>
                        <v-row no-gutters v-if="selectedProducts.length == 1">
                          <v-col
                            v-for="product in selectedProducts"
                            :key="product.id"
                            cols="12"
                          >
                            <vue-draggable-resizable
                              w="500px"
                              h="400px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                            >
                              <v-card>
                                <v-hover v-slot:default="{ hover }">
                                  <v-img
                                    :src="product.blobUrl"
                                    :style="{
                                      height: selectedProductImageUrun1,
                                    }"
                                  >
                                    <v-row dense>
                                      <v-col cols="12">
                                        <v-layout>
                                          <span>
                                            <h3
                                              dark
                                              :style="{
                                                'font-size':
                                                  selectedProductUrunAdiFontSizeUrun1,
                                              }"
                                              :class="{
                                                selectedProductUrunAdiA4:
                                                  selectedProductUrunAdiA4,
                                                selectedProductUrunAdiA5:
                                                  selectedProductUrunAdiA5,
                                              }"
                                            >
                                              {{ product.urunadi }}
                                            </h3>
                                          </span>
                                        </v-layout>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-layout>
                                          <div>
                                            <v-img
                                              :style="{
                                                'margin-top':
                                                  productFiyatAvatarMarginTopUrun1,
                                                'margin-left':
                                                  productFiyatAvatarMarginLeft,
                                              }"
                                              src="../../assets/img/fiyatBuyukBoy.png"
                                            >
                                              <span>
                                                <h3
                                                  :class="{
                                                    selectedProductFiyatTextUrun1A4:
                                                      selectedProductFiyatTextA4,
                                                    selectedProductFiyatTextA5:
                                                      selectedProductFiyatTextUrun1A5,
                                                  }"
                                                >
                                                  {{ product.fiyat }}
                                                  <span class="turkLirasiUrun1"
                                                    >TL</span
                                                  >
                                                </h3>
                                              </span>
                                            </v-img>
                                          </div>
                                        </v-layout>
                                      </v-col>
                                    </v-row>
                                    <v-expand-transition>
                                      <div
                                        v-if="hover"
                                        class="
                                          d-flex
                                          transition-fast-in-fast-out
                                          primary
                                          v-card--reveal
                                          display-3
                                          white--text
                                        "
                                        style="height: 100%"
                                      >
                                        <v-btn
                                          class="ma-2"
                                          tile
                                          color="black"
                                          dark
                                          @click="deleteProduct(product)"
                                        >
                                          Çıkar
                                        </v-btn>
                                      </div>
                                    </v-expand-transition>
                                  </v-img>
                                </v-hover>
                              </v-card>
                            </vue-draggable-resizable>
                          </v-col>
                        </v-row>
                        <v-row dense v-if="selectedProducts.length == 2">
                          <v-col
                            v-for="product in selectedProducts"
                            :key="product.id"
                            cols="12"
                          >
                            <vue-draggable-resizable
                              w="500px"
                              h="400px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                            >
                              <v-card>
                                <v-hover v-slot:default="{ hover }">
                                  <v-img
                                    :src="product.blobUrl"
                                    :style="{
                                      height: selectedProductImageUrun1,
                                    }"
                                  >
                                    <v-row dense>
                                      <v-col cols="12">
                                        <v-layout>
                                          <span>
                                            <h3
                                              dark
                                              :style="{
                                                'font-size':
                                                  selectedProductUrunAdiFontSizeUrun1,
                                              }"
                                              :class="{
                                                selectedProductUrunAdiA4:
                                                  selectedProductUrunAdiA4,
                                                selectedProductUrunAdiA5:
                                                  selectedProductUrunAdiA5,
                                              }"
                                            >
                                              {{ product.urunadi }}
                                            </h3>
                                          </span>
                                        </v-layout>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-layout>
                                          <div>
                                            <v-img
                                              :style="{
                                                'margin-top':
                                                  productFiyatAvatarMarginTopUrun1,
                                                'margin-left':
                                                  productFiyatAvatarMarginLeft,
                                              }"
                                              src="../../assets/img/fiyatBuyukBoy.png"
                                            >
                                              <span>
                                                <h3
                                                  :class="{
                                                    selectedProductFiyatTextUrun1A4:
                                                      selectedProductFiyatTextA4,
                                                    selectedProductFiyatTextA5:
                                                      selectedProductFiyatTextUrun1A5,
                                                  }"
                                                >
                                                  {{ product.fiyat }}
                                                  <span class="turkLirasiUrun1"
                                                    >TL</span
                                                  >
                                                </h3>
                                              </span>
                                            </v-img>
                                          </div>
                                        </v-layout>
                                      </v-col>
                                    </v-row>
                                    <v-expand-transition>
                                      <div
                                        v-if="hover"
                                        class="
                                          d-flex
                                          transition-fast-in-fast-out
                                          primary
                                          v-card--reveal
                                          display-3
                                          white--text
                                        "
                                        style="height: 100%"
                                      >
                                        <v-btn
                                          class="ma-2"
                                          tile
                                          color="black"
                                          dark
                                          @click="deleteProduct(product)"
                                        >
                                          Çıkar
                                        </v-btn>
                                      </div>
                                    </v-expand-transition>
                                  </v-img>
                                </v-hover>
                              </v-card>
                            </vue-draggable-resizable>
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          v-if="
                            selectedProducts.length > 2 &&
                            selectedProducts.length < 5
                          "
                        >
                          <v-col
                            v-for="product in selectedProducts"
                            :key="product.id"
                            cols="6"
                            ><vue-draggable-resizable
                              w="500px"
                              h="400px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                            >
                              <v-card>
                                <v-hover v-slot:default="{ hover }">
                                  <v-img
                                    :src="product.blobUrl"
                                    :style="{ height: selectedProductImage }"
                                  >
                                    <v-row dense>
                                      <v-col cols="12">
                                        <v-layout>
                                          <span>
                                            <h3
                                              :style="{
                                                'font-size':
                                                  selectedProductUrunAdiFontSize,
                                              }"
                                              :class="{
                                                selectedProductUrunAdiA4:
                                                  selectedProductUrunAdiA4,
                                                selectedProductUrunAdiA5:
                                                  selectedProductUrunAdiA4,
                                              }"
                                            >
                                              {{ product.urunadi }}
                                            </h3>
                                          </span>
                                        </v-layout>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-layout>
                                          <div width="50px" height="30px">
                                            <v-img
                                              :style="{
                                                'margin-top':
                                                  productFiyatAvatarMarginTop,
                                                'margin-left':
                                                  productFiyatAvatarMarginLeft,
                                              }"
                                              src="../../assets/img/fiyat.png"
                                            >
                                              <span>
                                                <h3
                                                  :class="{
                                                    selectedProductFiyatTextA4:
                                                      selectedProductFiyatTextA4,
                                                    selectedProductFiyatTextA5:
                                                      selectedProductFiyatTextA5,
                                                  }"
                                                >
                                                  {{ product.fiyat }}
                                                  <span class="turkLirasi"
                                                    >TL</span
                                                  >
                                                </h3>
                                              </span>
                                            </v-img>
                                          </div>
                                        </v-layout>
                                      </v-col>
                                    </v-row>
                                    <v-expand-transition>
                                      <div
                                        v-if="hover"
                                        class="
                                          d-flex
                                          transition-fast-in-fast-out
                                          primary
                                          v-card--reveal
                                          display-3
                                          white--text
                                        "
                                        style="height: 100%"
                                      >
                                        <v-btn
                                          class="ma-2"
                                          tile
                                          color="black"
                                          dark
                                          @click="deleteProduct(product)"
                                        >
                                          Çıkar
                                        </v-btn>
                                      </div>
                                    </v-expand-transition>
                                  </v-img>
                                </v-hover>
                              </v-card>
                            </vue-draggable-resizable>
                          </v-col>
                          <v-col v-if="logoButon" cols="6">
                            <vue-draggable-resizable
                              w="500px"
                              h="400px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                            >
                              <v-card v-if="selectedProducts.length == 3">
                                <v-img
                                  :style="{ height: selectedProductImage }"
                                >
                                  <div
                                    class="
                                      d-flex
                                      transition-fast-in-fast-out
                                      grey
                                      v-card--reveal
                                      display-3
                                      white--text
                                    "
                                    style="height: 100%"
                                  >
                                    <v-row justify="space-around">
                                      <span class="group pa-2">
                                        <v-btn
                                          icon
                                          color="indigo darken-4"
                                          :loading="loadingLogo"
                                        >
                                          <v-label>
                                            <input
                                              type="file"
                                              hidden
                                              @change="uploadLogo"
                                            />
                                            <v-icon x-large dark
                                              >mdi-plus</v-icon
                                            >
                                          </v-label>
                                        </v-btn>
                                      </span>
                                    </v-row>
                                  </div>
                                </v-img>
                              </v-card>
                            </vue-draggable-resizable>
                          </v-col>
                          <v-col v-if="logoResim" cols="6">
                            <vue-draggable-resizable
                              w="500px"
                              h="400px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                            >
                              <v-card v-if="selectedProducts.length == 3">
                                <v-hover v-slot:default="{ hover }">
                                  <v-img
                                    :src="logo.fileName"
                                    :style="{ height: selectedProductImage }"
                                  >
                                    <v-expand-transition>
                                      <div
                                        v-if="hover"
                                        class="
                                          d-flex
                                          transition-fast-in-fast-out
                                          primary
                                          v-card--reveal
                                          display-3
                                          white--text
                                        "
                                        style="height: 100%"
                                      >
                                        <v-btn
                                          class="ma-2"
                                          tile
                                          color="black"
                                          dark
                                          @click="deleteProductUrunResmi"
                                        >
                                          Çıkar
                                        </v-btn>
                                      </div>
                                    </v-expand-transition>
                                  </v-img>
                                </v-hover>
                              </v-card>
                            </vue-draggable-resizable>
                          </v-col>
                        </v-row>

                        <v-row
                          dense
                          v-if="
                            selectedProducts.length > 4 &&
                            selectedProducts.length < 7
                          "
                        >
                          <v-col
                            v-for="product in selectedProducts"
                            :key="product.id"
                            cols="6"
                          >
                            <vue-draggable-resizable
                              w="500px"
                              h="400px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                            >
                              <v-card>
                                <v-hover v-slot:default="{ hover }">
                                  <v-img
                                    :src="product.blobUrl"
                                    :style="{ height: selectedProductImage }"
                                  >
                                    <v-row dense>
                                      <v-col cols="12">
                                        <v-layout>
                                          <span>
                                            <h3
                                              :style="{
                                                'font-size':
                                                  selectedProductUrunAdiFontSize,
                                              }"
                                              :class="{
                                                selectedProductUrunAdiA4:
                                                  selectedProductUrunAdiA4,
                                                selectedProductUrunAdiA5:
                                                  selectedProductUrunAdiA5,
                                              }"
                                            >
                                              {{ product.urunadi }}
                                            </h3>
                                          </span>
                                        </v-layout>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-layout>
                                          <div width="50px" height="30px">
                                            <v-img
                                              :style="{
                                                'margin-top':
                                                  productFiyatAvatarMarginTop,
                                                'margin-left':
                                                  productFiyatAvatarMarginLeft,
                                              }"
                                              src="../../assets/img/fiyat.png"
                                            >
                                              <span>
                                                <h3
                                                  :class="{
                                                    selectedProductFiyatTextA4:
                                                      selectedProductFiyatTextA4,
                                                    selectedProductFiyatTextA5:
                                                      selectedProductFiyatTextA5,
                                                  }"
                                                >
                                                  {{ product.fiyat }}
                                                  <span class="turkLirasi"
                                                    >TL</span
                                                  >
                                                </h3>
                                              </span>
                                            </v-img>
                                          </div>
                                        </v-layout>
                                      </v-col>
                                    </v-row>
                                    <v-expand-transition>
                                      <div
                                        v-if="hover"
                                        class="
                                          d-flex
                                          transition-fast-in-fast-out
                                          primary
                                          v-card--reveal
                                          display-3
                                          white--text
                                        "
                                        style="height: 100%"
                                      >
                                        <v-btn
                                          class="ma-2"
                                          tile
                                          color="black"
                                          dark
                                          @click="deleteProduct(product)"
                                        >
                                          Çıkar
                                        </v-btn>
                                      </div>
                                    </v-expand-transition>
                                  </v-img>
                                </v-hover>
                              </v-card>
                            </vue-draggable-resizable>
                          </v-col>
                          <v-col v-if="logoButon" cols="6">
                            <vue-draggable-resizable
                              w="500px"
                              h="400px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                            >
                              <v-card v-if="selectedProducts.length == 5">
                                <v-img
                                  :style="{ height: selectedProductImage }"
                                >
                                  <div
                                    class="
                                      d-flex
                                      transition-fast-in-fast-out
                                      grey
                                      v-card--reveal
                                      display-3
                                      white--text
                                    "
                                    style="height: 100%"
                                  >
                                    <v-row justify="space-around">
                                      <span class="group pa-2">
                                        <v-btn
                                          icon
                                          color="indigo darken-4"
                                          :loading="loadingLogo"
                                        >
                                          <v-label>
                                            <input
                                              type="file"
                                              hidden
                                              @change="uploadLogo"
                                            />
                                            <v-icon x-large dark
                                              >mdi-plus</v-icon
                                            >
                                          </v-label>
                                        </v-btn>
                                      </span>
                                    </v-row>
                                  </div>
                                </v-img>
                              </v-card>
                            </vue-draggable-resizable>
                          </v-col>
                          <v-col v-if="logoResim" cols="6">
                            <vue-draggable-resizable
                              w="500px"
                              h="400px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                            >
                              <v-card>
                                <v-hover v-slot:default="{ hover }">
                                  <v-img
                                    :src="logo.fileName"
                                    :style="{ height: selectedProductImage }"
                                  >
                                    <v-expand-transition>
                                      <div
                                        v-if="hover"
                                        class="
                                          d-flex
                                          transition-fast-in-fast-out
                                          primary
                                          v-card--reveal
                                          display-3
                                          white--text
                                        "
                                        style="height: 100%"
                                      >
                                        <v-btn
                                          class="ma-2"
                                          tile
                                          color="black"
                                          dark
                                          @click="deleteProductUrunResmi"
                                        >
                                          Çıkar
                                        </v-btn>
                                      </div>
                                    </v-expand-transition>
                                  </v-img>
                                </v-hover>
                              </v-card>
                            </vue-draggable-resizable>
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          v-if="
                            selectedProducts.length > 6 &&
                            selectedProducts.length < 15
                          "
                        >
                          <v-col
                            v-for="product in selectedProducts"
                            :key="product.id"
                            cols="4"
                          >
                            <vue-draggable-resizable
                              w="500px"
                              h="400px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                            >
                              <v-card>
                                <v-hover v-slot:default="{ hover }">
                                  <v-img
                                    :src="product.blobUrl"
                                    :style="{ height: selectedProductImage }"
                                  >
                                    <v-row dense>
                                      <v-col cols="12">
                                        <v-layout>
                                          <span>
                                            <h3
                                              :style="{
                                                'font-size':
                                                  selectedProductUrunAdiFontSize,
                                              }"
                                              :class="{
                                                selectedProductUrunAdiA4:
                                                  selectedProductUrunAdiA4,
                                                selectedProductUrunAdiA5:
                                                  selectedProductUrunAdiA5,
                                              }"
                                            >
                                              {{ product.urunadi }}
                                            </h3>
                                          </span>
                                        </v-layout>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-layout>
                                          <div width="50px" height="30px">
                                            <v-img
                                              :style="{
                                                'margin-top':
                                                  productFiyatAvatarMarginTop,
                                                'margin-left':
                                                  productFiyatAvatarMarginLeft,
                                              }"
                                              src="../../assets/img/fiyat.png"
                                            >
                                              <span>
                                                <h3
                                                  :class="{
                                                    selectedProductFiyatTextA4:
                                                      selectedProductFiyatTextA4,
                                                    selectedProductFiyatTextA5:
                                                      selectedProductFiyatTextA5,
                                                  }"
                                                >
                                                  {{ product.fiyat }}
                                                  <span class="turkLirasi"
                                                    >TL</span
                                                  >
                                                </h3>
                                              </span>
                                            </v-img>
                                          </div>
                                        </v-layout>
                                      </v-col>
                                    </v-row>
                                    <v-expand-transition>
                                      <div
                                        v-if="hover"
                                        class="
                                          d-flex
                                          transition-fast-in-fast-out
                                          primary
                                          v-card--reveal
                                          display-3
                                          white--text
                                        "
                                        style="height: 100%"
                                      >
                                        <v-btn
                                          class="ma-2"
                                          tile
                                          color="black"
                                          dark
                                          @click="deleteProduct(product)"
                                        >
                                          Çıkar
                                        </v-btn>
                                      </div>
                                    </v-expand-transition>
                                  </v-img>
                                </v-hover>
                              </v-card>
                            </vue-draggable-resizable>
                          </v-col>
                          <v-col v-if="logoButon" cols="6">
                            <vue-draggable-resizable
                              w="500px"
                              h="400px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                            >
                              <v-card v-if="selectedProducts.length == 5">
                                <v-img
                                  :style="{ height: selectedProductImage }"
                                >
                                  <div
                                    class="
                                      d-flex
                                      transition-fast-in-fast-out
                                      grey
                                      v-card--reveal
                                      display-3
                                      white--text
                                    "
                                    style="height: 100%"
                                  >
                                    <v-row justify="space-around">
                                      <span class="group pa-2">
                                        <v-btn
                                          icon
                                          color="indigo darken-4"
                                          :loading="loadingLogo"
                                        >
                                          <v-label>
                                            <input
                                              type="file"
                                              hidden
                                              @change="uploadLogo"
                                            />
                                            <v-icon x-large dark
                                              >mdi-plus</v-icon
                                            >
                                          </v-label>
                                        </v-btn>
                                      </span>
                                    </v-row>
                                  </div>
                                </v-img>
                              </v-card>
                            </vue-draggable-resizable>
                          </v-col>
                          <v-col v-if="logoResim" cols="6">
                            <vue-draggable-resizable
                              w="500px"
                              h="400px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                            >
                              <v-card>
                                <v-hover v-slot:default="{ hover }">
                                  <v-img
                                    :src="logo.fileName"
                                    :style="{ height: selectedProductImage }"
                                  >
                                    <v-expand-transition>
                                      <div
                                        v-if="hover"
                                        class="
                                          d-flex
                                          transition-fast-in-fast-out
                                          primary
                                          v-card--reveal
                                          display-3
                                          white--text
                                        "
                                        style="height: 100%"
                                      >
                                        <v-btn
                                          class="ma-2"
                                          tile
                                          color="black"
                                          dark
                                          @click="deleteProductUrunResmi"
                                        >
                                          Çıkar
                                        </v-btn>
                                      </div>
                                    </v-expand-transition>
                                  </v-img>
                                </v-hover>
                              </v-card>
                            </vue-draggable-resizable>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-flex v-if="cubuk">
                        <vue-draggable-resizable
                          w="500px"
                          h="100px"
                          :lock-aspect-ratio="true"
                          :resizable="true"
                        >
                          <v-img
                            :class="{
                              cubukA4: cubukA4Class,
                              cubukA5: cubukA5Class,
                            }"
                            :height="cubukHeight"
                            src="../../assets/img/brosur-cubuk.png"
                          ></v-img>
                        </vue-draggable-resizable>
                      </v-flex>
                      <v-col cols="12">
                        <a class="d-flex justify-center text-center">
                          <vue-draggable-resizable
                            w="500px"
                            h="100px"
                            :lock-aspect-ratio="true"
                            :resizable="true"
                            class="text-center"
                          >
                            <h4
                              @dblclick="editAdress"
                              class="
                                d-flex
                                justify-center
                                text-center
                                font-weight-bold
                                adresAlanMedium
                              "
                              :style="{
                                'font-size': adresYaziBoyutu.alan1,
                                color: adresYaziRenk.alan1,
                              }"
                            >
                              <pre> {{ adres.alan1 }}</pre>
                            </h4>
                          </vue-draggable-resizable>
                        </a>
                      </v-col>
                      <v-col cols="12">
                        <a class="d-flex justify-center text-center">
                          <vue-draggable-resizable
                            w="500px"
                            h="400px"
                            :lock-aspect-ratio="true"
                            :resizable="true"
                          >
                            <h4
                              @dblclick="editAdress"
                              class="
                                font-weight-bold
                                adresAlanMedium
                                d-flex
                                justify-center
                                text-center
                              "
                              :style="{
                                'font-size': adresYaziBoyutu.alan8,
                                color: adresYaziRenk.alan8,
                              }"
                            >
                              {{ adres.alan8 }}
                            </h4>
                          </vue-draggable-resizable>
                        </a>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-img>
              </v-card>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-row class="mt-5">
              <v-col cols="6">
                <v-row>
                  <v-col
                    class="d-flex child-flex"
                    cols="2"
                  >
                    <v-img
                    height="40"
                      src="../../assets/arka-plan/1.png"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      @click="arkaPlan1"
                    ></v-img>
                  </v-col>
                   <v-col
                    class="d-flex child-flex"
                    cols="2"
                  >
                    <v-img
                    height="40"
                      src="../../assets/arka-plan/2.png"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      @click="arkaPlan2"
                    ></v-img>
                  </v-col>
                  <v-col
                    class="d-flex child-flex"
                    cols="2"
                  >
                    <v-img
                    height="40"
                      src="../../assets/arka-plan/3.png"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      @click="arkaPlan3"
                    ></v-img>
                  </v-col>
                   <v-col
                    class="d-flex child-flex"
                    cols="2"
                  >
                    <v-img
                    height="40"
                      src="../../assets/arka-plan/4.png"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      @click="arkaPlan4"
                    ></v-img>
                  </v-col>
                  <v-col
                    class="d-flex child-flex"
                    cols="2"
                  >
                    <v-img
                    height="40"
                      src="../../assets/arka-plan/5.png"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      @click="arkaPlan5"
                    ></v-img>
                  </v-col>
                  <v-col
                    class="d-flex child-flex"
                    cols="2"
                  >
                    <v-img
                    height="40"
                      src="../../assets/arka-plan/6.png"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      @click="arkaPlan6"
                    ></v-img>
                  </v-col>
                  
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-btn outlined :loading="loadingBgImage" tile color="indigo">
                  <v-icon left> mdi-pencil </v-icon>
                  <v-label>
                    <input type="file" hidden @change="updateBackgroundImage" />
                    Arka Plan
                  </v-label>
                </v-btn>
                <v-btn
                  tile
                  color="teal"
                  outlined
                  class="ml-2 mr-2"
                  @click="editAdress"
                >
                  <v-icon>mdi-format-list-bulleted-square</v-icon>
                  Adres
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="urunDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            {{ title }}
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="urunDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      label="Resim"
                      color="sari"
                      @change="imageUrunUpload"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.urunadi"
                      label="Ürün Adı"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="product.urunaciklama"
                      label="Açıklama"
                      prepend-icon="gesture"
                      color="sari"
                      rows="1"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.fiyat"
                      label="Fiyat"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      item-text="name"
                      item-value="sembol"
                      :items="paraBirimleri"
                      v-model="product.paraBirimi"
                      prepend-icon="euro"
                      label="Para Birimi"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="sari"
              @click="saveProduct"
              :loading="loader"
              dark
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--Ürünler Edit Dialog-->
      <v-dialog v-model="editUrunDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            {{ title }}
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="editUrunDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <span class="ml-5">
                      <v-label>Resim</v-label>
                    </span>
                    <v-img
                      :src="product.resim"
                      aspect-ratio="1"
                      class="grey lighten-2 align-end ml-5"
                      max-width="300"
                      max-height="150"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :loading="loadingUrunImage"
                            color="pink darken-1"
                            small
                            class="float-right"
                          >
                            <v-label>
                              <input
                                type="file"
                                hidden
                                @change="updateUrunImage"
                              />
                              <v-icon>mdi-plus</v-icon>
                            </v-label>
                          </v-btn>
                        </template>
                        <span>Yeni Resim</span>
                      </v-tooltip>
                    </v-img>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.urunadi"
                      label="Ürün Adı"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="product.urunaciklama"
                      label="Açıklama"
                      prepend-icon="gesture"
                      color="sari"
                      rows="1"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.fiyat"
                      label="Fiyat"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      item-text="name"
                      item-value="sembol"
                      :items="paraBirimleri"
                      v-model="product.paraBirimi"
                      prepend-icon="euro"
                      label="Para Birimi"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="sari"
              @click="updateProduct"
              :loading="updateLoader"
              dark
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="urunSayisiDialog"
      >
        <v-card>
          <v-toolbar color="yellow accent-4">
            <v-spacer></v-spacer>
            <h3>Uyarı</h3>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <div class="text-h5 pa-12">6' dan Fazla Ürün Ekleyemezsiniz</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="urunSayisiDialog = false">Kapat</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
const clamp = (num, lower = 0, upper) => {
  return num < lower ? lower : num > upper ? upper : num;
};

import { fb, db } from "../../firebase/fb";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";
import VueDraggableResizable from "vue-draggable-resizable";
import Brosur1 from "../brosur/brosur1.vue";

export default {
  data() {
    return {
      backgrounds:[],
      urunSayisiDialog: false,
      searchKey: "",
      loadingLogo: false,
      logo: {
        resim: "",
        fileName: "",
      },
      logoButon: true,
      logoResim: false,
      bosluk: true,
      selectedProductFiyatTextUrun1A5: false,
      selectedProductUrunAdiFontSizeUrun1: "30px",
      x: 100,
      y: 100,
      w: 250,
      h: 50,
      min_w: 250,
      min_h: 10,
      max_w: 300,
      max_h: 60,
      windowStyle: {
        width: "400px",
        position: "relative",
      },
      cubukA4Class: true,
      cubukA5Class: false,
      selectedProductFiyatTextA4: true,
      selectedProductFiyatTextA5: false,
      selectedProductUrunAdiFontSize: "20px",
      selectedProductUrunAdiA4: true,
      selectedProductUrunAdiA5: false,
      cubukHeight: "25px",
      brosurA4Class: true,
      brosurA5Class: false,
      adresAlan9Width: "130px",
      adresAlan9Height: "25px",
      isDragging: false,
      dragItemCoords: {
        top: 5,
        left: 5,
      },
      selection: 1,
      brosurBackground: {
        resim: "",
        fileName: "",
      },
      color: "#3F51B5",
      fontSize: "25px",
      updateUrunLoader: false,
      editUrunDialog: false,
      urunDialog: false,
      loadingUrunImage: false,
      brosurSecimDialog: false,
      loaderBrosurA2: false,
      brosurDialogA2: false,
      loaderBrosur: false,
      paraBirimleri: [
        { name: "TL", sembol: "TL" },
        { name: "$", sembol: "$" },
        { name: "£", sembol: "£ " },
        { name: "€", sembol: "€" },
      ],
      imageShow: true,
      id: this.$route.params.id,
      brosurDialogBoyut: 800,
      adresAlani: "",
      blbUrl: null,
      adresEditDiaog: false,
      brosurUrun: {
        urunAdi: "",
        urunFiyat: "",
        bosluk: " ...................... ",
        paraBirimi: "",
      },
      urunListDialog: false,
      renk: "success",
      chk: false,
      editTitleDialog: false,
      brosurTitle: "NFS SOFT",
      checked: false,
      editDialog: false,
      disabled: true,
      products: [],
      snackbar: {
        status: false,
        text: "",
        color: "",
        timeout: 2000,
      },
      title: "",
      dialog: false,
      loader: false,
      updateLoader: false,
      product: {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      },
      loading: false,
      selectedProducts: [],
      brosurDialog: false,
      allUrunlerDialog: false,
      list: [],
      adresYaziRenk: {
        alan1: "#000000",
        alan8: "#000000",
        alan9: "#000000",
        alan10: " #ffe414",
      },
      adresYaziBoyutu: {
        alan1: "15",
        alan8: "30",
        alan9: "15",
      },
      adres: {
        alan1: "Ataüni Teknokent Yakutiye ERZURUM",
        alan8: "448448737363",
        alan9: "448448737363",
      },
      urunler: [],
      icecekler: [],
      tatlilar: [],
      bayi: {
        id: null,
        docName: "",
      },
      output: null,
      kategoriler: [],
      updateLoader: false,
      urunFiyatDialog: false,
      loadingBgImage: false,
      cubuk: true,
      brosurBoyutWidth: "",
      brosurBoyutHeight: "",
      selectedProductImage: "",
      productFiyatAvatarMarginTop: "",
      productFiyatAvatarMarginLeft: "",
      selectedProductImageUrun1: "",
      adresButon: false,
      instagram: false,
      facebook: false,
      a4Boyut: true,
      a5boyut: false,
    };
  },

  components: {
    VueDraggableResizable,
    brosur1: Brosur1,
  },
  mounted() {
    this.getUrunler();
    this.getBackround();
    this.getAdresBilgi();
  },
  watch: {
    brosurTitle: (val) => {
      this.brosurTitle = val;
    },
    selectedProducts: {
      handler(val, oldVal) {
         if (val.length > 6) {
          this.urunSayisiDialog = true;
        } 
      }
  },
  },
  methods: {
    arkaPlan1(){
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("1")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan2(){
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("2")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan3(){
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("3")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan4(){
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("4")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan5(){
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("5")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan6(){
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("6")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan7() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("7")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan8() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("8")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan9() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("9")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    boyutInstagramExport() {
      this.instagram = true;
      this.a4Boyut = false;
      this.a5Boyut = false;
      this.facebook = false;
      this.brosurBoyutWidth = "500px";
      this.brosurBoyutHeight = "500px";
      if (this.selectedProducts.length == 1) {
        this.brosurA4Class = false;
        this.bosluk = false;
        this.selectedProductImageUrun1 = "300px";
        this.productFiyatAvatarMarginTopUrun1 = "165px";
        this.selectedProductUrunAdiFontSizeUrun1 = "30px";
        this.cubukHeight = "15px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (this.selectedProducts.length == 2) {
        this.brosurA4Class = false;
        this.selectedProductImageUrun1 = "145px";
        this.productFiyatAvatarMarginTopUrun1 = "10px";
        this.selectedProductUrunAdiFontSizeUrun1 = "15px";
        this.cubukHeight = "15px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (
        this.selectedProducts.length > 2 &&
        this.selectedProducts.length < 5
      ) {
        this.selectedProductImage = "135px";
        this.productFiyatAvatarMarginTop = "36px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.brosurA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = true;
      } else if (
        this.selectedProducts.length > 4 &&
        this.selectedProducts.length < 7
      ) {
        this.selectedProductImage = "85px";
        this.productFiyatAvatarMarginTop = "-16px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.brosurA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = true;
      }
    },
    boyutA4Export() {
      this.instagram = false;
      this.a4Boyut = true;
      this.a5Boyut = false;
      this.facebook = false;
      this.brosurA4Class = true;
      this.brosurBoyutWidth = "595px";
      this.brosurBoyutHeight = "842px";
      if (this.selectedProducts.length == 1) {
        this.selectedProductImageUrun1 = "570px";
        this.productFiyatAvatarMarginTopUrun1 = "435px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (this.selectedProducts.length == 2) {
        this.selectedProductImageUrun1 = "295px";
        this.productFiyatAvatarMarginTopUrun1 = "160px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (
        this.selectedProducts.length > 2 &&
        this.selectedProducts.length < 5
      ) {
        this.selectedProductImage = "295px";
        this.productFiyatAvatarMarginTop = "194px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiA4 = true;
        this.selectedProductUrunAdiA5 = false;
      } else if (
        this.selectedProducts.length > 4 &&
        this.selectedProducts.length < 7
      ) {
        this.selectedProductImage = "193px";
        this.productFiyatAvatarMarginTop = "89px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiA4 = true;
        this.selectedProductUrunAdiA5 = false;
        // this.selectedProductFiyatTextA4 = true;
      }
      
    },
    boyutA5Export() {
      this.instagram = false;
      this.a4Boyut = false;
      this.a5Boyut = true;
      this.facebook = false;
      this.brosurBoyutWidth = " 421px";
      this.brosurBoyutHeight = "605px";
      if (this.selectedProducts.length == 1) {
        this.selectedProductUrunAdiA4 = false;
        this.selectedProductUrunAdiA5 = true;
        this.selectedProductUrunAdiFontSizeUrun1 = "20px";
        this.fontSize = "23px";
        this.cubukA5Class = true;
        this.cubukA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.cubukHeight = "20px";
        this.brosurA4Class = false;
        this.brosurA5Class = true;
        this.selectedProductImageUrun1 = "405px";
        this.productFiyatAvatarMarginTopUrun1 = "270px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (this.selectedProducts.length == 2) {
        this.selectedProductImageUrun1 = "188px";
        this.productFiyatAvatarMarginTopUrun1 = "55px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiFontSizeUrun1 = "15px";
        this.cubukHeight = "20px";
      } else if (
        this.selectedProducts.length > 2 &&
        this.selectedProducts.length < 5
      ) {
        this.selectedProductImage = "175px";
        this.productFiyatAvatarMarginTop = "75px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = false;
        this.selectedProductUrunAdiA5 = true;
        // this.selectedProductFiyatTextA4 = true;
      } else if (
        this.selectedProducts.length > 4 &&
        this.selectedProducts.length < 7
      ) {
        this.selectedProductImage = "122px";
        this.productFiyatAvatarMarginTop = "23px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiA4 = false;
        this.selectedProductUrunAdiA5 = true;
        this.selectedProductFiyatTextA4 = false;
        this.selectedProductFiyatTextA5 = true;
        this.cubukA5Class = true;
        this.cubukA4Class = false;
        this.cubukHeight = "20px";
        this.fontSize = "20px";
        this.selectedProductUrunAdiFontSize = "15px";
        // this.selectedProductFiyatTextA4 = true;
      }
    },
    boyutFacebookExport() {
      this.instagram = false;
      this.a4Boyut = false;
      this.a5Boyut = false;
      this.facebook = true;
      this.brosurBoyutWidth = "600px";
      this.brosurBoyutHeight = "600px";
      if (this.selectedProducts.length == 1) {
        this.brosurA4Class = false;
        this.bosluk = false;
        this.selectedProductImageUrun1 = "390px";
        this.productFiyatAvatarMarginTopUrun1 = "250px";
        this.selectedProductUrunAdiFontSizeUrun1 = "30px";
        this.cubukHeight = "15px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (this.selectedProducts.length == 2) {
        this.brosurA4Class = false;
        this.selectedProductImageUrun1 = "195px";
        this.productFiyatAvatarMarginTopUrun1 = "60px";
        this.selectedProductUrunAdiFontSizeUrun1 = "15px";
        this.cubukHeight = "15px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (
        this.selectedProducts.length > 2 &&
        this.selectedProducts.length < 5
      ) {
        this.selectedProductImage = "195px";
        this.productFiyatAvatarMarginTop = "95px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.brosurA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = true;
      } else if (
        this.selectedProducts.length > 4 &&
        this.selectedProducts.length < 7
      ) {
        this.selectedProductImage = "118px";
        this.productFiyatAvatarMarginTop = "18px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.brosurA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = true;
      }
    },

    getAdresBilgi() {
      db.collection("adres")
        .doc("adres")
        .onSnapshot((doc) => {
         // this.brosurTitle = doc.data().brosurTitle;
          this.adres = {
            alan1: doc.data().alan1,
            alan8: doc.data().alan8,
          };
        });
    },
    adresKaydet() {
      this.adresEditDiaog = false;
      this.editTitleDialog = false;
      this.snackbar.status = true;
      this.snackbar.color = "green darken-3";
      this.snackbar.text = "Bilgileriniz Kaydedildi";
      db.collection("adres")
        .doc("adres")
        .update({
          alan1: this.adres.alan1,
          alan8: this.adres.alan8,
      //    brosurTitle: this.brosurTitle,
        })
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },
    deleteProductUrunResmi() {
      this.logoResim = false;
      this.logoButon = true;
    },
    uploadLogo(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingLogo = true;
        this.logo.fileName = file.name;
        var storageRef = fb.storage().ref("logo/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.logo.resim = downloadURL;
              let vm = this;
              db.collection("logo")
                .doc("logo")
                .set({
                  resim: vm.logo.resim,
                  fileName: vm.logo.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingLogo = false;
                  vm.logoButon = false;
                  vm.logoResim = true;
                  db.collection("logo")
                    .doc("logo")
                    .onSnapshot((doc) => {
                      let fileName = "";
                      fetch(doc.data().resim)
                        .then((r) => {
                          return r.blob();
                        })
                        .then((blobFile) => {
                          fileName = window.URL.createObjectURL(
                            new File([blobFile], { type: "image/jpeg" })
                          );
                          vm.logo.fileName = fileName;
                        });
                    });
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    /* Boyutlar */

    updateBackgroundImage(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingBgImage = true;
        this.brosurBackground.fileName = file.name;
        var storageRef = fb.storage().ref("background/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.brosurBackground.resim = downloadURL;
              let vm = this;
              db.collection("arka_plan")
                .doc("resim")
                .update({
                  resim: vm.brosurBackground.resim,
                  fileName: vm.brosurBackground.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingBgImage = false;
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    getBackround() {
      db.collection("arka_plan")
        .doc("resim")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    changeWidthAdresAlan9: function (event) {
      this.adresAlan9Width = event.target.value + "px";
    },
    changeHeightAdresAlan9: function (event) {
      this.adresAlan9Height = event.target.value + "px";
    },
    changeColorAdresAlan10: function (event) {
      this.adresYaziRenk.alan10 = event.target.value;
    },
    changeColorAdresAlan9: function (event) {
      this.adresYaziRenk.alan9 = event.target.value;
    },
    changeFontAdresAlan9: function (event) {
      this.adresYaziBoyutu.alan9 = event.target.value + "px";
    },
    changeColorAdresAlan8: function (event) {
      this.adresYaziRenk.alan8 = event.target.value;
    },
    changeFontAdresAlan8: function (event) {
      this.adresYaziBoyutu.alan8 = event.target.value + "px";
    },
    changeColorAdresAlan1: function (event) {
      this.adresYaziRenk.alan1 = event.target.value;
    },
    changeFontAdresAlan1: function (event) {
      this.adresYaziBoyutu.alan1 = event.target.value + "px";
    },
    changeFontSize: function (event) {
      this.fontSize = event.target.value + "px";
    },
    changeColor: function (event) {
      this.color = event.target.value;
    },
    brosurA1() {
      this.brosurDialog = !this.brosurDialog;
    },
    brosurA2() {
      this.brosurDialogA2 = !this.brosurDialogA2;
    },
    async print() {
      const el = this.$refs.capture;
      const options = {
        type: "dataURL",
      };
      this.output = await this.$html2canvas(el, options);
      var link = document.createElement("a");
      link.download = "brosur.png";
      link.href = this.output;
      document.body.appendChild(link);
      link.click();
    },
    makePDF() {
      this.loaderBrosur = true;
      const self = this;
      var canvas = this.$refs.capture;
      html2canvas(canvas, { type: "dataURL" }).then(function (canvas) {
        // var myImage = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
        // var link = document.createElement('a')
        // link.download = 's.png'
        // link.href = myImage
        // document.body.appendChild(link)
        // link.click()

        self.print();
      });
      setTimeout(() => {
        this.loaderBrosur = false;
      }, 1000);
    },

    newMusteriLink() {
      this.$router.push({ path: `/customer-login/${this.$route.params.id}` });
    },
    imageShowDelete() {
      this.imageShow = false;
    },
    deleteProduct(product) {
      console.log(product);
      this.selectedProducts.splice(product, 1);
    },
    deleteAdresItem(index) {
      this.adres.splice(index, 1);
    },
    editAdress() {
      this.adresEditDiaog = true;
    },
    addItem() {
      if (this.brosurUrun.urunAdi !== "") {
        this.list.push({
          urunAdi: this.brosurUrun.urunAdi,
          urunFiyat: this.brosurUrun.urunFiyat,
          bosluk: this.brosurUrun.bosluk,
          paraBirimi: this.brosurUrun.paraBirimi,
        });
        this.brosurUrun = {
          urunAdi: "",
          urunFiyat: "",
          bosluk: "  ...............................  ",
          paraBirimi: "",
        };
      }
    },
    deleteItem(index) {
      this.list.splice(index, 1);
    },
    clearProduct() {
      this.product = {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      };
    },
    updateUrunImage(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingUrunImage = true;
        this.product.fileName = file.name;
        var storageRef = fb.storage().ref("images/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.resim = downloadURL;
              let vm = this;
              db.collection("urun")
                .doc(vm.product.docName)
                .update({
                  resim: vm.product.resim,
                  fileName: vm.product.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingUrunImage = false;
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    imageUrunUpload(e) {
      let file = e;
      this.product.fileName = file.name;
      var storageRef = fb.storage().ref("images/" + file.name);
      let uploadTask = storageRef.put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        function (error) {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.product.resim = downloadURL;
            console.log("File available at", downloadURL);
            this.disabled = false;
          });
        }
      );
    },
    getUrunler() {
      db.collection("brosur")
              .doc("brosur").collection("urun")
        .onSnapshot((querySnapshot) => {
          this.urunler = [];
          let fileName = "";
          querySnapshot.forEach((doc) => {
            fetch(doc.data().resim)
              .then((r) => {
                return r.blob();
              })
              .then((blobFile) => {
                fileName = window.URL.createObjectURL(
                  new File([blobFile], { type: "image/jpeg" })
                );
                // this.blbUrl = fileName
                this.urunler.push({
                  docName: doc.id,
                  ...doc.data(),
                  blobUrl: fileName,
                });
              });
          });
        });
    },
    deleteUrun(product) {
      let vm = this;
      vm.product.docName = product.docName;
      if (confirm("Ürünü Silmek İstediğinize emin misiniz?")) {
        db.collection("urun")
          .doc(vm.product.docName)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true;
            vm.snackbar.color = "red";
            vm.snackbar.text = "Ürün Silindi";
            //vm.clearProduct()
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    },
    editUrunList() {
      this.urunListDialog = true;
    },
    generatePdf() {},
    downloadBrosur() {},
    editTitle() {
      this.editTitleDialog = true;
    },
    newBrosurClose() {
      this.brosurSecimDialog = false;
    },
    newBrosur() {
      this.boyutA4Export();
      this.brosurDialog = !this.brosurDialog;
      //if (this.selectedProducts.length > 1) {
      //  this.brosurDialogBoyut = 1200
      // }
    },
    saveProduct() {
      this.loader = true;
      this.urunAdiEkle();
      db.collection("urun")
        .doc(this.product.docName)
        .set({
          id: this.product.id,
          fiyat: this.product.fiyat,
          urunadi: this.product.urunadi,
          urunaciklama: this.product.urunaciklama,
          resim: this.product.resim,
          active: true,
          activeDurum: "Aktif",
          fileName: this.product.fileName,
          paraBirimi: this.product.paraBirimi,
        })
        .then((docRef) => {
          console.log("Document written with ID", docRef);
          this.loader = false;
          this.urunDialog = false;
          this.snackbar.status = true;
          this.snackbar.color = "green darken-3";
          this.snackbar.text = "Yeni Ürün Eklendi";
          //this.urunleriGoster(this.product)
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    selectedProduct(product) {
      //console.log(product);
      // eslint-disable-next-line eqeqeq
      if (this.selectedProducts.length == 0) {
        if (product.active == true) {
          this.selectedProducts.push(product);
          this.snackbar.status = true;
          this.snackbar.color = "green darken-3";
          this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
          // eslint-disable-next-line eqeqeq
        } else if (product.active == false) {
          this.selectedProducts.splice(
            this.selectedProducts.indexOf(product),
            1
          );
        }
      } else if (this.selectedProducts.length > 0) {
        if (
          this.selectedProducts.find(
            (element) => element.docName === product.docName
          )
        ) {
          this.snackbar.status = true;
          this.snackbar.color = "red darken-3";
          this.snackbar.text = "Bu Ürünü Daha Önce Eklemiştiniz...";
        } else {
          if (this.selectedProducts.length > 5) {
            
            this.snackbar.status = true;
            this.snackbar.color = "yellow darken-3";
            this.snackbar.text = "Seçilen Ürün Broşüre Eklenemedi";
          } else if (this.selectedProducts.length < 7) {
            this.selectedProducts.push(product);
            this.snackbar.status = true;
            this.snackbar.color = "green darken-3";
            this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
          }
        }
      }
    },
    newProduct() {
      this.clearProduct();
      this.title = "Yeni Ürün";
      this.urunDialog = !this.urunDialog;
    },
    editProduct(product) {
      this.title = "Ürün Güncelleme";
      this.product = product;
      this.editUrunDialog = !this.editUrunDialog;
      // console.log(this.product)
    },
    updateProduct() {
      let vm = this;
      vm.updateLoader = true;
      db.collection("urun")
        .doc(vm.product.docName)
        .update({
          urunadi: vm.product.urunadi,
          urunaciklama: vm.product.urunaciklama,
          fiyat: vm.product.fiyat,
          paraBirimi: vm.product.paraBirimi,
        })
        .then(function () {
          console.log("Document successfully updated!");
          vm.updateLoader = false;
          vm.snackbar.status = true;
          vm.snackbar.color = "sari";
          vm.snackbar.text = "Ürün Bilgileri Güncellendi";
          vm.editUrunDialog = !vm.editUrunDialog;
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },

    clearProduct() {
      this.product = {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      };
    },
    urunAdiEkle() {
      var charMap = {
        Ç: "C",
        Ö: "O",
        Ş: "S",
        İ: "I",
        I: "i",
        Ü: "U",
        Ğ: "G",
        ç: "c",
        ö: "o",
        ş: "s",
        ı: "i",
        ü: "u",
        ğ: "g",
      };
      var str = this.product.urunadi;
      var str_array = str.split("");
      for (var i = 0, len = str_array.length; i < len; i++) {
        str_array[i] = charMap[str_array[i]] || str_array[i];
      }
      str = str_array.join("");

      var clearStr = str
        .replace(" ", "")
        .replace("-", "")
        .replace(/[^a-z0-9-.çöşüğı]/gi, "");
      this.product.docName = clearStr;
      //var res = new Array();
      //res = this.kelime.split(" ");
      //var sonuc = res.join("");
      //console.log(sonuc);
    },
  },

  computed: {
    filteredList() {
      if (this.searchKey) {
        return this.urunler.filter((urun) => {
          return urun.urunadi
            .toLowerCase()
            .startsWith(this.searchKey.toLowerCase());
        });
      } else {
        return this.urunler;
      }
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}

.brosurA4 {
  margin-top: 10px;
}
.selectedProductUrunAdiA4 {
  color: white;
  margin-top: 5px;
  margin-left: 5px;
}
.selectedProductUrunAdiA5 {
  color: white;
  margin-top: 3px;
  margin-left: 3px;
}
.selectedProductFiyatTextUrun1A4 {
  margin-top: 35px;
  margin-left: 30px;
  font-weight: 900;
  font-size: 45px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}

.selectedProductFiyatTextUrun1A5 {
  margin-top: 35px;
  margin-left: 30px;
  font-weight: 900;
  font-size: 45px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.selectedProductFiyatTextA4 {
  margin-top: 25px;
  margin-left: 23px;
  font-weight: 900;
  font-size: 25px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.selectedProductFiyatTextA5 {
  margin-top: 25px;
  margin-left: 22px;
  font-weight: 900;
  font-size: 22px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.turkLirasiUrun1 {
  font-size: 30px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  font-weight: 900;
}

.cubukA4 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.cubukA5 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.brosurBackground {
  position: "relative";
}
.brosurTitle {
  margin-top: 8px;
  font-weight: bolder;
}

@media screen and (min-width: 601px) {
  .alGoturToGo {
    margin-left: 20px;
    margin-top: 70px;
  }
  .urunListBosluk {
    margin-top: 50px;
  }
  .urunListFiyat {
    margin-right: 10px;
  }
  .yesil {
    background-color: #ffe414;
    float: left;
    margin-left: 80px;
    margin-top: 20px;
  }
  .selectedProductFiyatAvatar {
    margin-top: 200px;
    margin-left: -17px;
  }
  .selectedProductFiyatAvatarA2 {
    margin-top: 100px;
    margin-left: -10px;
  }

  .selectedProductFiyatText {
    margin-top: 25px;
    margin-left: 18px;
    font-weight: 900;
    font-size: 30px;
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  }
  .turkLirasi {
    font-size: 20px;
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
    font-weight: 900;
  }

  .editAdres {
    margin-bottom: 15px;
  }
  .editAdresA2 {
    margin-bottom: 15px;
  }
  .ambalaj {
    font-size: small;
    font-weight: bolder;
    margin-left: 5px;
  }
  .productImage {
    height: 250px;
  }
  .productImageRow {
    height: 30px;
  }
}

@media (min-width: 300px) and (max-width: 601px) {
  .selectedProductImage {
    height: 150px;
  }
  .selectedProductUrunadi {
    font-size: x-small;
    margin-bottom: 6px;
  }
  .selectedProductUrunAciklama {
    font-size: 8px;
    margin-top: -16px;
  }
  .selectedProductFiyatAvatar {
    width: 65px;
    height: 65px;
    margin-top: 42px;
    margin-left: -7px;
  }
  .selectedProductFiyatText {
    font-size: 11px;
    font-weight: bolder;
    margin-top: 25px;
    margin-left: 11px;
  }
  .brosurTitle {
    font-weight: bolder;
  }
  .selectedAdresTextMedium {
    font-size: x-small;
    font-weight: bolder;
  }
  .selectedAdresTextSmall {
    font-size: xx-small;
    margin-bottom: 0px;
  }
  .yesil {
    background-color: #e3dd2f;
  }
  .bosluk {
    margin-top: 0px;
    margin-bottom: -13px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .bosluk8 {
    margin-top: 0px;
    margin-bottom: -10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .urunListFiyat {
    margin-right: 3px;
  }
  .alGoturToGo {
    font-size: 9px;
    font-weight: bolder;
  }
  .urunListBosluk {
    margin-top: 20px;
  }
  .editAdres {
    margin-bottom: 3px;
  }
  .alGoturCard {
    height: 150px;
    width: 250;
  }
  .ambalaj {
    font-size: xx-small;
    font-weight: bolder;
    margin-top: -15px;
    margin-left: 1px;
  }
  .alGoturToGoText {
    font-size: xx-small;
    font-weight: bolder;
  }
  .alGoturToGoNokta {
    font-size: 5px;
    font-weight: bolder;
  }
  .adresAlanMedium {
    font-size: 7px;
    font-weight: bolder;
  }
  .adresAlanSmall {
    font-size: 7px;
    font-weight: medium;
  }
  .adresAlanBold {
    font-size: 10px;
    font-weight: bolder;
  }
  .brosurTitle8 {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: bolder;
  }
  .brosurBtn {
    font-size: small;
    margin-right: 45px;
  }
  .productImage {
    height: 150px;
  }
  .productImageRow {
    height: 15px;
  }
  .productUrunadi {
    font-size: small;
  }
  .productFiyat {
    font-size: large;
  }
  .productActive {
    font-size: xx-small;
  }
  .productSwitch {
    font-size: xx-small;
  }
  .opacity-0 {
    opacity: 0 !important;
  }
}
</style>
