<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card-title class="headline">
          <v-spacer></v-spacer>
          <div id="editor"></div>
          <v-btn
            id="cmd"
            tile
            color="success"
            class="white--text"
            @click="makePDF"
            :loading="loaderBrosur"
          >
            İNDİR
            <v-icon right dark> mdi-cloud-download </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
          style="margin-top:-15px"
            tile
            color="primary"
            outlined
            @click="fiyatlandirmaYap"
            v-if="fiyatButonShow"
          >
            {{fiyatButonName}}
          </v-btn>
        </v-card-title>
        <v-card-text class="d-flex justify-center" id="test_1">
          <div id="capture" ref="capture">
            <v-card :color="brosurBackgroundColor">
              <v-img class="brosurBackground" :src="brosurBackground.fileName">
                <v-flex class="mt-7">
                  <a class="d-flex justify-center text-center">
                    <vue-draggable-resizable
                      w="500px"
                      h="400px"
                      :lock-aspect-ratio="true"
                      :resizable="true"
                    >
                      <h2
                        class="
                          font-weight-black
                          brosurTitle
                          d-flex
                          justify-center
                          text-center
                        "
                        :style="{ 'font-size': fontSize, color: color }"
                        @dblclick="editTitle"
                        v-text="brosurTitle"
                      ></h2>
                    </vue-draggable-resizable>
                  </a>
                </v-flex>
                <v-flex style="z-index: 5; margin-top: 150px">
                  <v-flex
                    class="d-flex justify-center align-center"
                    v-if="selectedProduct1"
                    cols="6"
                  >
                    <vue-draggable-resizable
                      w="500px"
                      h="400px"
                      :lock-aspect-ratio="true"
                      :resizable="true"
                    >
                      <v-hover v-slot:default="{ hover }">
                        <v-card style="width: 450px; height: 300px">
                          <img
                            :src="seciliUrun.product1"
                            style="width: 100%; height: 100%"
                          />
                          <v-card v-if="fiyatShow"
                            :color="brosurBackgroundColorFiyat"
                            class="pa-0"
                            height="40px"
                            width="80px"
                            style="opacity: 1; margin-top: -290px"
                          >
                            <v-row class="mt-3 pa-1" >
                              <v-col
                                cols="12"
                                class="text-center"
                                style="color: white"
                              >
                                <p
                                  style="
                                    line-height: 13px;
                                    font-size: 15px;
                                    letter-spacing: 1px;
                                  "
                                >
                                  {{ seciliUrun.fiyat1 }}

                                  <span
                                    style="line-height: 13px; font-size: 15px"
                                    class="font-weight-bold"
                                    >₺
                                  </span>
                                </p>
                              </v-col>
                            </v-row>
                          </v-card>
                          <v-expand-transition>
                            <div
                              v-if="hover"
                              class="
                                d-flex
                                transition-fast-in-fast-out
                                primary
                                v-card--reveal
                                display-3
                                white--text
                              "
                              style="height: 100%"
                            >
                              <v-btn
                                class="ma-2"
                                tile
                                color="black"
                                dark
                                @click="deleteProductUrunResmi1"
                              >
                                Çıkar
                              </v-btn>
                            </div>
                          </v-expand-transition>
                        </v-card>
                      </v-hover>
                    </vue-draggable-resizable>
                  </v-flex>
                  <v-flex
                    class="d-flex justify-center align-center"
                    v-else
                    cols="6"
                  >
                    <v-card style="width: 450px; height: 300px">
                      <v-img style="width: 450px; height: 300px">
                        <div
                          class="
                            d-flex
                            transition-fast-in-fast-out
                            grey
                            darken-1
                            v-card--reveal
                            display-3
                            white--text
                          "
                          style="height: 100%"
                        >
                          <v-row justify="space-around">
                            <span class="group pa-2">
                              <v-btn
                                icon
                                color="indigo darken-4"
                                :loading="loadingLogo"
                                @click="selectProduct1"
                              >
                                <v-icon x-large dark>mdi-plus</v-icon>
                              </v-btn>
                            </span>
                          </v-row>
                        </div>
                      </v-img>
                    </v-card>
                  </v-flex>
                </v-flex>
                <v-row class="d-flex justify-center" style="margin-top: -120px">
                  <v-col cols="12" class="d-flex justify-center">
                    <vue-draggable-resizable
                      w="500px"
                      h="400px"
                      :lock-aspect-ratio="true"
                      :resizable="true"
                      style="margin-top: 40px"
                    >
                      <v-card
                        style="width: 150px; height: 150px; z-index: 5"
                        class="rounded-circle d-inline-block"
                        v-if="selectedProduct2"
                        outlined
                      >
                        <v-hover v-slot:default="{ hover }">
                          <v-img
                            :src="seciliUrun.product2"
                            width="100%"
                            height="100%"
                            style="border: 2px solid white"
                          >                           
                            <v-expand-transition>
                              <div
                                v-if="hover"
                                class="
                                  d-flex
                                  transition-fast-in-fast-out
                                  primary
                                  v-card--reveal
                                  display-3
                                  white--text
                                "
                                style="height: 100%"
                              >
                                <v-btn
                                  class="ma-2"
                                  tile
                                  color="black"
                                  dark
                                  @click="deleteProductUrunResmi2"
                                >
                                  Çıkar
                                </v-btn>
                              </div>
                            </v-expand-transition>
                            
                          </v-img>
                        </v-hover>
                      </v-card>
                      <v-card
                        v-else
                        style="width: 150px; height: 150px; z-index: 5"
                        class="rounded-circle d-inline-block"
                        outlined
                      >
                        <v-img style="width: 150px; height: 150px">
                          <div
                            class="
                              d-flex
                              transition-fast-in-fast-out
                              grey
                              v-card--reveal
                              display-3
                              white--text
                            "
                            style="height: 100%"
                          >
                            <v-row justify="space-around">
                              <span class="group pa-2">
                                <v-btn
                                  icon
                                  color="indigo darken-4"
                                  :loading="loadingLogo"
                                  @click="selectProduct2"
                                >
                                  <v-icon x-large dark>mdi-plus</v-icon>
                                </v-btn>
                              </span>
                            </v-row>
                          </div>
                        </v-img>
                      </v-card>
                      <v-row class="pa-0 ma-0" v-if="fiyatShow">
                      <v-card
                      v-if="selectedProduct2"
                              :color="brosurBackgroundColorFiyat"
                              class="pa-0"
                              height="30px"
                              width="80px"
                              style="
                                opacity: 1;
                                margin-left:40px;
                              "
                            >
                              <v-row class="mt-1 pa-1" >
                                <v-col
                                  cols="12"
                                  class="text-center"
                                  style="color: white"
                                >
                                  <p
                                    class="mt-n2"
                                    style="
                                      line-height: 13px;
                                      font-size: 15px;
                                      letter-spacing: 1px;
                                    "
                                  >
                                    {{ seciliUrun.fiyat2 }}

                                    <span
                                      style="line-height: 13px; font-size: 15px"
                                      class="font-weight-bold"
                                      >₺
                                    </span>
                                  </p>
                                </v-col>
                              </v-row>
                    </v-card>
                      </v-row>
                    </vue-draggable-resizable>
                    
                    <vue-draggable-resizable
                      w="500px"
                      h="400px"
                      :lock-aspect-ratio="true"
                      :resizable="true"
                      style="margin-left: -30px; margin-top: 20px"
                    >
                      <v-flex v-if="selectedProduct3">
                        <v-hover v-slot:default="{ hover }">
                          <v-card
                            style="width: 185px; height: 185px; z-index: 15"
                            class="rounded-circle d-inline-block"
                            outlined
                          >
                            <v-img
                              :src="seciliUrun.product3"
                              style="border: 2px solid white"
                              height="100%"
                              width="100%"
                            >
                              
                              <v-expand-transition>
                                <div
                                  v-if="hover"
                                  class="
                                    d-flex
                                    transition-fast-in-fast-out
                                    primary
                                    v-card--reveal
                                    display-3
                                    white--text
                                  "
                                  style="height: 100%"
                                >
                                  <v-btn
                                    class="ma-2"
                                    tile
                                    color="black"
                                    dark
                                    @click="deleteProductUrunResmi3"
                                  >
                                    Çıkar
                                  </v-btn>
                                </div>
                              </v-expand-transition>
                            </v-img>
                          </v-card>
                        </v-hover>
                      </v-flex>
                      <v-flex v-else>
                        <v-card
                          style="width: 185px; height: 185px; z-index: 15"
                          class="rounded-circle d-inline-block"
                          outlined
                        >
                          <v-img style="width: 185px; height: 185px">
                            <div
                              class="
                                d-flex
                                transition-fast-in-fast-out
                                grey
                                v-card--reveal
                                display-3
                                white--text
                              "
                              style="height: 100%"
                            >
                              <v-row justify="space-around">
                                <span class="group pa-2">
                                  <v-btn
                                    icon
                                    color="indigo darken-4"
                                    :loading="loadingLogo"
                                    @click="selectProduct3"
                                  >
                                    <v-icon x-large dark>mdi-plus</v-icon>
                                  </v-btn>
                                </span>
                              </v-row>
                            </div>
                          </v-img>
                        </v-card>
                      </v-flex>
                    <v-row class="pa-0 ma-0"  v-if="fiyatShow">
                    <v-card
                    v-if="selectedProduct3"
                                :color="brosurBackgroundColorFiyat"
                                class="pa-0"
                                height="30px"
                                width="80px"
                                style="
                                  opacity: 1;
                                  margin-left: 58px;
                                  margin-top:0px;
                                "
                              >
                                <v-row class="mt-3 pa-1">
                                  <v-col
                                    cols="12"
                                    class="text-center"
                                    style="color: white"
                                  >
                                    <p
                                      class="mt-n4"
                                      style="
                                        line-height: 13px;
                                        font-size: 15px;
                                        letter-spacing: 1px;
                                      "
                                    >
                                      {{ seciliUrun.fiyat3 }}

                                      <span
                                        style="
                                          line-height: 13px;
                                          font-size: 15px;
                                        "
                                        class="font-weight-bold"
                                        >₺
                                      </span>
                                    </p>
                                  </v-col>
                                </v-row>
                    </v-card>
                    </v-row>
                 </vue-draggable-resizable>

                    <vue-draggable-resizable
                      w="500px"
                      h="400px"
                      :lock-aspect-ratio="true"
                      :resizable="true"
                      style="margin-left: -20px; margin-top: 40px"
                    >
                      <v-card
                        style="width: 150px; height: 150px; z-index: 5"
                        class="rounded-circle d-inline-block"
                        v-if="selectedProduct4"
                        outlined
                      >
                        <v-hover v-slot:default="{ hover }">
                          <v-img
                            :src="seciliUrun.product4"
                            style="border: 2px solid white"
                            width="100%"
                            height="100%"
                          >
                            
                            <v-expand-transition>
                              <div
                                v-if="hover"
                                class="
                                  d-flex
                                  transition-fast-in-fast-out
                                  primary
                                  v-card--reveal
                                  display-3
                                  white--text
                                "
                                style="height: 100%"
                              >
                                <v-btn
                                  class="ma-2"
                                  tile
                                  color="black"
                                  dark
                                  @click="deleteProductUrunResmi4"
                                >
                                  Çıkar
                                </v-btn>
                              </div>
                            </v-expand-transition>
                          </v-img>
                        </v-hover>
                      </v-card>
                      <v-card
                        v-else
                        style="width: 150px; height: 150px; z-index: 5"
                        class="rounded-circle d-inline-block"
                        outlined
                      >
                        <v-img style="width: 150px; height: 150px">
                          <div
                            class="
                              d-flex
                              transition-fast-in-fast-out
                              grey
                              v-card--reveal
                              display-3
                              white--text
                            "
                            style="height: 100%"
                          >
                            <v-row justify="space-around">
                              <span class="group pa-2">
                                <v-btn
                                  icon
                                  color="indigo darken-4"
                                  :loading="loadingLogo"
                                  @click="selectProduct4"
                                >
                                  <v-icon x-large dark>mdi-plus</v-icon>
                                </v-btn>
                              </span>
                            </v-row>
                          </div>
                        </v-img>
                      </v-card>
                      <v-row class="ma-0 pa-0"  v-if="fiyatShow">
                     <v-card
                    v-if="selectedProduct4"
                              :color="brosurBackgroundColorFiyat"
                              class="pa-0"
                              height="30px"
                              width="80px"
                              style="
                                opacity: 1;
                                margin-left: 39px;
                                margin-top: 0px;
                              "
                            >
                              <v-row class="mt-3 pa-1">
                                <v-col
                                  cols="12"
                                  class="text-center"
                                  style="color: white"
                                >
                                  <p
                                    class="mt-n4"
                                    style="
                                      line-height: 13px;
                                      font-size: 15px;
                                      letter-spacing: 1px;
                                    "
                                  >
                                    {{ seciliUrun.fiyat4 }}

                                    <span
                                      style="line-height: 13px; font-size: 15px"
                                      class="font-weight-bold"
                                      >₺
                                    </span>
                                  </p>
                                </v-col>
                              </v-row>
                     </v-card>
                      </v-row>
                  </vue-draggable-resizable>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-flex cols="1"> </v-flex>
                    <v-flex cols="11">
                      <v-row no-gutters>
                        <v-col cols="12"
                          ><a class="d-flex justify-center text-center">
                            <vue-draggable-resizable
                              w="100px"
                              h="10px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                              class="text-center"
                            >
                              <h5
                                :style="{
                                  'font-size': adresYaziBoyutu.firma,
                                  color: adresYaziRenk.firma,
                                }"
                                @dblclick="editFirma"
                                v-text="firma"
                              >
                                {{ firma }}
                              </h5>
                            </vue-draggable-resizable>
                          </a></v-col
                        >
                        <v-col cols="12">
                          <a class="d-flex justify-center text-center">
                            <vue-draggable-resizable
                              w="100px"
                              h="10px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                              class="text-center"
                            >
                              <h4
                                @dblclick="editFirma"
                                class="
                                  d-flex
                                  justify-center
                                  text-center
                                  font-weight-bold
                                  adresAlanMedium
                                "
                                :style="{
                                  'font-size': adresYaziBoyutu.alan8,
                                  color: adresYaziRenk.alan8,
                                }"
                              >
                                <h1>{{ adres.alan8 }}</h1>
                              </h4>
                            </vue-draggable-resizable>
                          </a>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-col>
                  <v-col cols="4"
                    ><v-flex class="d-flex justify-center text-center">
                      <vue-draggable-resizable
                        w="200px"
                        h="100px"
                        :lock-aspect-ratio="true"
                        :resizable="true"
                        class="text-center"
                      >
                        <v-btn
                          class="mt-2"
                          small
                          dark
                          depressed
                          color="#063062"
                          @dblclick="hemenAlEdit"
                        >
                          <h6
                            :style="{
                              'font-size': adresYaziBoyutu.hemenAl,
                              color: adresYaziRenk.hemenAl,
                            }"
                          >
                            {{ hemen_al }}
                          </h6>
                        </v-btn>
                      </vue-draggable-resizable>
                    </v-flex>
                  </v-col>
                  <v-col cols="4">
                    <v-flex cols="11">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <a class="d-flex justify-center text-center">
                            <vue-draggable-resizable
                              w="100px"
                              h="10px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                              class="text-center"
                            >
                              <h4
                                @dblclick="editAdress"
                                class="
                                  d-flex
                                  justify-center
                                  text-center
                                  font-weight-bold
                                  adresAlanMedium
                                "
                                :style="{
                                  'font-size': adresYaziBoyutu.webAdresi,
                                  color: adresYaziRenk.webAdresi,
                                }"
                              >
                                <h3>{{ adres.webAdresi }}</h3>
                              </h4>
                            </vue-draggable-resizable>
                          </a>
                        </v-col>
                        <v-col cols="12"
                          ><a
                            
                            class="d-flex justify-center text-center"
                          >
                            <vue-draggable-resizable
                              w="100px"
                              h="10px"
                              :lock-aspect-ratio="true"
                              :resizable="true"
                              class="text-center"
                            >
                            <h4 
                            @dblclick="editAdress"
                            class="
                            d-flex
                            justify-center
                            text-center
                            font-wight-bold
                            "
                            :style="{
                                  'font-size': adresYaziBoyutu.web,
                                  color: adresYaziRenk.web,
                                }"
                            >
                              <h1>
                                {{ web }}
                              </h1>
                              </h4>
                            </vue-draggable-resizable>
                          </a></v-col
                        >
                      </v-row>
                    </v-flex>
                    <v-flex cols="1"></v-flex>
                  </v-col>
                </v-row>
              </v-img>
            </v-card>
          </div>
        </v-card-text>
      </v-col>
      <v-card-text class="d-flex justify-center mt-3">
        <v-row>
          <v-col offset="8" cols="3">
            <v-row  dense>
              <v-col cols="12">
                <v-label>
                  <span>Arka Plan Rengi</span>
                  <input
                    type="color"
                    id="favcolor"
                    name="favcolor"
                    value="#ff0000"
                    @change="updateBackgroundColor"
                  />
                </v-label>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-label>
                  <span>Fiyat Buton Rengi</span>
                  <input
                    type="color"
                    id="favcolor"
                    name="favcolor" 
                    value="#b4855f"
                    @change="updateBackgroundColorFiyat"
                  />
                </v-label>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-row>
    <v-row dense>
      <v-snackbar
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        center
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-row>
    <v-dialog v-model="editTitleDialog" @keydown.enter="editTitleDialog = false" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Başlığı Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="editTitleDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="brosurTitle"
                    label="Yeni Başlık"
                    color="sari"
                    outlined
                    maxlength="25"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <div
                    class="mt-3"
                    v-text="25 - brosurTitle.length"
                  ></div>
                </v-col>
                <v-col cols="6">Yazı Boyutu</v-col>
                <v-col cols="6">
                  <input type="number" value="35" @input="changeFontSize" />
                </v-col>
                <v-col cols="6"><label>Yazı Rengi</label></v-col>
                <v-col cols="6">
                  <input
                    id="color-choos"
                    type="color"
                    name="color"
                    value="#ffffff"
                    @input="changeColor"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="adresKaydet()"
            :loading="adresButon"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="adresEditDiaog" @keydown.enter="adresEditDiaog = false" max-width="800">
      <v-card>
        <v-card-title class="headline">
          Firma İletişim Bilgileri Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="adresEditDiaog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row v-if="adresBilgiShow">
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="web"
                    color="sari"
                    outlined
                    maxlength="15"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="6">Yazı Boyutu</v-col>
                    <v-col cols="6">
                      <input type="number" value="10" @input="changeFontWeb" />
                    </v-col>
                    <v-col cols="6"><label>Yazı Rengi</label></v-col>
                    <v-col cols="6">
                      <input
                        id="color-choos"
                        type="color"
                        name="color"
                        value="#063062"
                        @input="changeColorWeb"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="adres.webAdresi"
                    color="sari"
                    outlined
                    maxlength="25"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="6">Yazı Boyutu</v-col>
                    <v-col cols="6">
                      <input
                        type="number"
                        value="10"
                        @input="changeFontAdresAlan1"
                      />
                    </v-col>
                    <v-col cols="6"><label>Yazı Rengi</label></v-col>
                    <v-col cols="6">
                      <input
                        id="color-choos"
                        type="color"
                        name="color"
                        value="#000000"
                        @input="changeColorAdresAlan1"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="hemenalShow">
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="hemen_al"
                    color="sari"
                    outlined
                    maxlength="15"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="6">Yazı Boyutu</v-col>
                    <v-col cols="6">
                      <input
                        type="number"
                        value="12"
                        @input="changeFontHemenAl"
                      />
                    </v-col>
                    <v-col cols="6"><label>Yazı Rengi</label></v-col>
                    <v-col cols="6">
                      <input
                        id="color-choos"
                        type="color"
                        name="color"
                        value="#ffffff"
                        @input="changeColorHemenAL"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="firmaBilgiShow">
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="firma"
                    color="sari"
                    outlined
                    maxlength="25"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="6">Yazı Boyutu</v-col>
                    <v-col cols="6">
                      <input
                        type="number"
                        value="12"
                        @input="changeFontFirma"
                      />
                    </v-col>
                    <v-col cols="6"><label>Yazı Rengi</label></v-col>
                    <v-col cols="6">
                      <input
                        id="color-choos"
                        type="color"
                        name="color"
                        value="#000000 "
                        @input="changeColorFirma"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="adres.alan8"
                    color="sari"
                    outlined
                     maxlength="15"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="6">Yazı Boyutu</v-col>
                    <v-col cols="6">
                      <input
                        type="number"
                        value="10"
                        @input="changeFontAdresAlan8"
                      />
                    </v-col>
                    <v-col cols="6"><label>Yazı Rengi</label></v-col>
                    <v-col cols="6">
                      <input
                        id="color-choos"
                        type="color"
                        name="color"
                        value="#063062"
                        @input="changeColorAdresAlan8"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="adresKaydet()"
            :loading="adresButon"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <!-- A1 Broşür-->
      <v-dialog v-model="brosurDialog"  @keydown.enter="brosurDialog = false" max-width="1200"> </v-dialog>

      <v-dialog v-model="urunDialog"  @keydown.enter="urunDialog = false" max-width="400">
        <v-card>
          <v-card-title class="headline">
            {{ title }}
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="urunDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      label="Resim"
                      color="sari"
                      @change="imageUrunUpload"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.urunadi"
                      label="Ürün Adı"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="product.urunaciklama"
                      label="Açıklama"
                      prepend-icon="gesture"
                      color="sari"
                      rows="1"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.fiyat"
                      label="Fiyat"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      item-text="name"
                      item-value="sembol"
                      :items="paraBirimleri"
                      v-model="product.paraBirimi"
                      prepend-icon="euro"
                      label="Para Birimi"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="sari"
              @click="saveProduct"
              :loading="loader"
              dark
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="selectedProductDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="indigo">
            <v-row>
              <v-col cols="8" offset="4">
                <v-text-field
                  v-model="searchKey"
                  cache-items
                  flat
                  hide-no-data
                  hide-details
                  label="Aramak istediğiniz ürünü ekleyin..."
                  solo-inverted
                ></v-text-field>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="selectedProductDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <!--ürünler -->
          <v-row dense class="mt-2">
            <v-col
              cols="6"
              sm="6"
              md="4"
              lg="3"
              v-for="product in filteredList"
              :key="product.id"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card tile color="grey lighten-4">
                  <v-img
                    :aspect-ratio="16 / 9"
                    class="productImage"
                    :src="product.blobUrl"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="
                          d-flex
                          transition-fast-in-fast-out
                          sari
                          v-card--reveal
                          display-3
                          white--text
                        "
                        style="height: 100%"
                      >
                        <v-btn
                          class="ma-2"
                          tile
                          color="black"
                          dark
                          @click="selectedProduct(product)"
                        >
                          Ekle
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text style="position: relative">
                    <v-row no-gutters class="productImageRow">
                      <v-col cols="12">
                        <v-row no-gutters>
                          <v-col cols="12" class="text-center">
                            <h3 class="text-center productUrunadi">
                              {{ product.urunadi }}
                            </h3>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-1"></v-divider>
                    <v-row no-gutters>
                      <v-col cols="12" class="mt-1">
                        <h2 class="text-center">
                          <strong
                            class="font-weight-black productFiyat text-center"
                            style="font-weight: older"
                          >
                            {{ product.fiyat }} {{ product.paraBirimi }}
                          </strong>
                        </h2>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
const clamp = (num, lower = 0, upper) => {
  return num < lower ? lower : num > upper ? upper : num;
};

import { fb, db } from "../../firebase/fb";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";
import VueDraggableResizable from "vue-draggable-resizable";
import Brosur1 from "../brosur/brosur1.vue";
import facebookVue from "../boyutlar/facebook.vue";
import Vue from 'vue';

export default {
  data() {
    return {
      fiyatButonShow:false,
      fiyatShow:true,
      fiyatButonName:'Fiyat Kaldır',
      firma: "Firma İletişim Bilgileri",
      web: "WEB ZİYARET",
      hemen_al: "HEMEN AL",
      urunFiyatButon: "Fiyat Ekle",
      selectedProduct1: "",
      selectedProduct2: "",
      selectedProduct3: "",
      selectedProduct4: "",
      backgrounds: [],
      urunSayisiDialog: false,
      searchKey: "",
      loadingLogo: false,
      logo: {
        resim: "",
        fileName: "",
      },
      logoButon: true,
      logoResim: false,
      bosluk: true,
      selectedProductFiyatTextUrun1A5: false,
      selectedProductUrunAdiFontSizeUrun1: "30px",
      x: 100,
      y: 100,
      w: 250,
      h: 50,
      min_w: 250,
      min_h: 10,
      max_w: 300,
      max_h: 60,
      windowStyle: {
        width: "400px",
        position: "relative",
      },
      cubukA4Class: true,
      cubukA5Class: false,
      selectedProductFiyatTextA4: true,
      selectedProductFiyatTextA5: false,
      selectedProductUrunAdiFontSize: "20px",
      selectedProductUrunAdiA4: true,
      selectedProductUrunAdiA5: false,
      cubukHeight: "25px",
      brosurA4Class: true,
      brosurA5Class: false,
      adresAlan9Width: "130px",
      adresAlan9Height: "25px",
      isDragging: false,
      dragItemCoords: {
        top: 5,
        left: 5,
      },
      selection: 1,
      brosurBackground: {
        resim: "",
        fileName: "",
      },
      color: "#ffffff",
      fontSize: "35px",
      updateUrunLoader: false,
      editUrunDialog: false,
      urunDialog: false,
      loadingUrunImage: false,
      brosurSecimDialog: false,
      loaderBrosurA2: false,
      brosurDialogA2: false,
      loaderBrosur: false,
      paraBirimleri: [
        { name: "TL", sembol: "TL" },
        { name: "$", sembol: "$" },
        { name: "£", sembol: "£ " },
        { name: "€", sembol: "€" },
      ],
      imageShow: true,
      id: this.$route.params.id,
      brosurDialogBoyut: 800,
      adresAlani: "",
      blbUrl: null,
      adresEditDiaog: false,
      brosurUrun: {
        urunAdi: "",
        urunFiyat: "",
        bosluk: " ...................... ",
        paraBirimi: "",
      },
      urunListDialog: false,
      renk: "success",
      chk: false,
      editTitleDialog: false,
      brosurTitle: "NFS SOFT MOBİLYA",
      checked: false,
      editDialog: false,
      disabled: true,
      products: [],
      snackbar: {
        status: false,
        text: "",
        color: "",
        timeout: 2000,
      },
      title: "",
      dialog: false,
      loader: false,
      updateLoader: false,
      product: {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      },
      loading: false,
      selectedProducts: [],
      brosurDialog: false,
      allUrunlerDialog: false,
      list: [],
      adresYaziRenk: {
        alan1: "#000000",
        alan8: "#063062",
        alan9: "#000000",
        alan10: " #ffe414",
        webAdresi: "#4C4C4C",
        web: "#063062",
        firma: "#000000",
        hemenAl: "#ffffff",
      },
      adresYaziBoyutu: {
        alan1: "15px",
        alan8: "10px",
        alan9: "15px",
        webAdresi: "10px",
        web: "10px",
        firma: "12px",
        hemenAl: "12px",
      },
      adres: {
        alan1: "Ataüni Teknokent Yakutiye ERZURUM",
        alan8: "448448737363",
        alan9: "448448737363",
        webAdresi: "wwww.kardelenyatak.com",
      },
      urunler: [],
      icecekler: [],
      tatlilar: [],
      bayi: {
        id: null,
        docName: "",
      },
      output: null,
      kategoriler: [],
      updateLoader: false,
      urunFiyatDialog: false,
      loadingBgImage: false,
      cubuk: true,
      brosurBoyutWidth: "1080px",
      brosurBoyutHeight: "1080px",
      selectedProductImage: "",
      productFiyatAvatarMarginTop: "",
      productFiyatAvatarMarginLeft: "",
      selectedProductImageUrun1: "",
      adresButon: false,
      instagram: false,
      facebook: false,
      a4Boyut: true,
      a5boyut: false,
      selectedProductDialog: false,
      seciliUrun: {
        product1: "",
        product2: "",
        product3: "",
        product4: "",
        fiyat1: "",
        fiyat2: "",
        fiyat3: "",
        fiyat4: "",
        fiyatAlan1: false,
        fiyatAlan2: false,
        fiyatAlan3: false,
        fiyatAlan4: false,
      },
      fiyatAlani: false,
      urun1: false,
      urun2: false,
      urun3: false,
      urun4: false,
      backgrounds: [],
      firmaBilgiShow: false,
      adresBilgiShow: false,
      hemenalShow: false,
      brosurBackgroundColor: "",
      brosurBackgroundColorFiyat: "#b4855f",
    };
  },

  components: {
    VueDraggableResizable,
    brosur1: Brosur1,
  },
  mounted() {
    this.getUrunler();
    this.getBackround();
    this.getAdresBilgi();
  },
  watch: {
    brosurTitle: (val) => {
      this.brosurTitle = val;
    },

    selectedProducts: {
      handler(val, oldVal) {
        if (val.length > 6) {
          this.urunSayisiDialog = true;
        }
      },
    },
    selectedProduct1(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product1 = urunResmi;
        this.seciliUrun.fiyat1 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
    selectedProduct2(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product2 = urunResmi;
        this.seciliUrun.fiyat2 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
    selectedProduct3(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.fiyat3 = this.selectedProducts[0].fiyat;
        this.seciliUrun.product3 = urunResmi;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
    selectedProduct4(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product4 = urunResmi;
        this.seciliUrun.fiyat4 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
  },
  methods: {
    fiyatlandirmaYap(){
      this.fiyatShow = !this.fiyatShow;
      if(this.fiyatShow == false){
        this.fiyatButonName = "Fiyat Ekle"
      }
      else{
         this.fiyatButonName = "Fiyat Kaldır"
      }
    },
    updateBackgroundColor(event) {
      this.brosurBackground.fileName = "";
      this.brosurBackgroundColor = event.target.value;
    },
    updateBackgroundColorFiyat(event) {
      this.brosurBackground.fileName = "";
      this.brosurBackgroundColorFiyat = event.target.value;
    },
    hemenAlEdit() {
      this.firmaBilgiShow = false;
      this.adresBilgiShow = false;
      this.hemenalShow = true;
      this.adresEditDiaog = true;
    },
    editAdress() {
      this.firmaBilgiShow = false;
      this.adresBilgiShow = true;
      this.hemenalShow = false;
      this.adresEditDiaog = true;
    },
    editFirma() {
      this.firmaBilgiShow = true;
      this.adresBilgiShow = false;
      this.hemenalShow = false;
      this.adresEditDiaog = true;
    },
    arkaPlan1() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("brosur2")
        .doc("asil")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan2() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("2")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan3() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("3")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan4() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("4")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan5() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("5")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan6() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("6")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    wrapperHeight() {},
    fiyatVisibility() {
      this.fiyatAlani = !this.fiyatAlani;
      if (this.fiyatAlani == true) {
        this.urunFiyatButon = "Fiyat Kaldır";
      } else {
        this.urunFiyatButon = "Fiyat Ekle";
      }
      this.seciliUrun.fiyatAlan1 = !this.seciliUrun.fiyatAlan1;
      this.seciliUrun.fiyatAlan2 = !this.seciliUrun.fiyatAlan2;
      this.seciliUrun.fiyatAlan3 = !this.seciliUrun.fiyatAlan3;
      this.seciliUrun.fiyatAlan4 = !this.seciliUrun.fiyatAlan4;
    },
    deleteProductUrunResmi1() {
      this.selectedProduct1 = false;
    },
    deleteProductUrunResmi2() {
      this.selectedProduct2 = false;
    },
    deleteProductUrunResmi3() {
      this.selectedProduct3 = false;
    },
    deleteProductUrunResmi4() {
      this.selectedProduct4 = false;
    },
    selectProduct1() {
      this.selectedProductDialog = true;
      this.urun1 = true;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
    },
    selectProduct2() {
      this.selectedProductDialog = true;
      this.urun2 = true;
      this.urun1 = false;
      this.urun3 = false;
      this.urun4 = false;
    },
    selectProduct3() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = true;
      this.urun4 = false;
    },
    selectProduct4() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = true;
    },

    getAdresBilgi() {
      db.collection("adres")
        .doc("adres")
        .onSnapshot((doc) => {
          //this.brosurTitle = doc.data().brosurTitle;
          this.adres = {
            alan1: doc.data().alan1,
            alan8: doc.data().alan8,
            webAdresi: doc.data().webAdresi,
          };
        });
    },
    adresKaydet() {
      this.adresEditDiaog = false;
      this.editTitleDialog = false;
      this.snackbar.status = true;
      this.snackbar.color = "green darken-3";
      this.snackbar.text = "Bilgileriniz Kaydedildi";
      db.collection("adres")
        .doc("adres")
        .update({
          alan1: this.adres.alan1,
          alan8: this.adres.alan8,
          brosurTitle: this.brosurTitle,
          webAdresi: this.adres.webAdresi,
        })
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },
    deleteProductUrunResmi() {
      this.logoResim = false;
      this.logoButon = true;
    },
    uploadLogo(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingLogo = true;
        this.logo.fileName = file.name;
        var storageRef = fb.storage().ref("logo/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.logo.resim = downloadURL;
              let vm = this;
              db.collection("logo")
                .doc("logo")
                .set({
                  resim: vm.logo.resim,
                  fileName: vm.logo.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingLogo = false;
                  vm.logoButon = false;
                  vm.logoResim = true;
                  db.collection("logo")
                    .doc("logo")
                    .onSnapshot((doc) => {
                      let fileName = "";
                      fetch(doc.data().resim)
                        .then((r) => {
                          return r.blob();
                        })
                        .then((blobFile) => {
                          fileName = window.URL.createObjectURL(
                            new File([blobFile], { type: "image/jpeg" })
                          );
                          vm.logo.fileName = fileName;
                        });
                    });
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    /* Boyutlar */

    updateBackgroundImage(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingBgImage = true;
        this.brosurBackground.fileName = file.name;
        var storageRef = fb.storage().ref("background/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.brosurBackground.resim = downloadURL;
              let vm = this;
              db.collection("arka_plan")
                .doc("resim")
                .collection("brosur2")
                .doc("brosur2")
                .update({
                  resim: vm.brosurBackground.resim,
                  fileName: vm.brosurBackground.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingBgImage = false;
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    getBackround() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("brosur2")
        .doc("asil")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    changeColorHemenAL: function (event) {
      this.adresYaziRenk.hemenAl = event.target.value;
    },
    changeFontHemenAl: function (event) {
      if (event.target.value <20){
      this.adresYaziBoyutu.hemenAl = event.target.value + "px";
      }
      else {
        this.adresYaziBoyutu.hemenAl = "20";
      }
    },
    changeColorFirma: function (event) {
      this.adresYaziRenk.firma = event.target.value;
    },
    changeFontFirma: function (event) {
      if (event.target.value < 15){
      this.adresYaziBoyutu.firma = event.target.value + "px";
      }
      else {
        this.adresYaziBoyutu.firma = "15px";
      }
    },
    changeColorWeb: function (event) {
      this.adresYaziRenk.web = event.target.value;
    },
    changeFontWeb: function (event) {
      if (event.target.value < 15){
      this.adresYaziBoyutu.web = event.target.value + "px";
      }
      else {
        this.adresYaziBoyutu.web ="14px";
      }
    },
    changeWidthAdresAlan9: function (event) {
      this.adresAlan9Width = event.target.value + "px";
    },
    changeHeightAdresAlan9: function (event) {
      this.adresAlan9Height = event.target.value + "px";
    },
    changeColorAdresAlan10: function (event) {
      this.adresYaziRenk.alan10 = event.target.value;
    },
    changeColorAdresAlan9: function (event) {
      this.adresYaziRenk.alan9 = event.target.value;
    },
    changeFontAdresAlan9: function (event) {
      this.adresYaziBoyutu.alan9 = event.target.value + "px";
    },
    changeColorAdresAlan8: function (event) {
      this.adresYaziRenk.alan8 = event.target.value;
    },
    changeFontAdresAlan8: function (event) {
      if (event.target.value < 15){
      this.adresYaziBoyutu.alan8 = event.target.value + "px";
      }
      else{
        this.adresYaziBoyutu.alan8 = "15px";
      }
    },
    changeColorAdresAlan1: function (event) {
      this.adresYaziRenk.webAdresi = event.target.value;
    },
    changeFontAdresAlan1: function (event) {
      if (event.target.value < 15){
      this.adresYaziBoyutu.webAdresi = event.target.value + "px";
      }
      else{
        this.adresYaziBoyutu.webAdresi ="15px";
      }
    },
    changeFontSize: function (event) {
      if (event.target.value <40){
      this.fontSize = event.target.value + "px";
      }
      else {
        this.fontSize = "40px";
      }
    },
    changeColor: function (event) {
      this.color = event.target.value;
    },
    brosurA1() {
      this.brosurDialog = !this.brosurDialog;
    },
    brosurA2() {
      this.brosurDialogA2 = !this.brosurDialogA2;
    },
    async print() {
      const el = this.$refs.capture;
      const options = {
        type: "dataURL",
      };
      this.output = await this.$html2canvas(el, options);
      var link = document.createElement("a");
      link.download = "brosur.png";
      link.href = this.output;
      document.body.appendChild(link);
      link.click();
    },
    makePDF() {
      this.loaderBrosur = true;
      const self = this;
      var canvas = this.$refs.capture;
      html2canvas(canvas, { type: "dataURL" }).then(function (canvas) {
        // var myImage = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
        // var link = document.createElement('a')
        // link.download = 's.png'
        // link.href = myImage
        // document.body.appendChild(link)
        // link.click()

        self.print();
      });
      setTimeout(() => {
        this.loaderBrosur = false;
      }, 1000);
    },

    newMusteriLink() {
      this.$router.push({ path: `/customer-login/${this.$route.params.id}` });
    },
    imageShowDelete() {
      this.imageShow = false;
    },
    deleteProduct(product) {
      console.log(product);
      this.selectedProducts.splice(product, 1);
    },
    deleteAdresItem(index) {
      this.adres.splice(index, 1);
    },

    addItem() {
      if (this.brosurUrun.urunAdi !== "") {
        this.list.push({
          urunAdi: this.brosurUrun.urunAdi,
          urunFiyat: this.brosurUrun.urunFiyat,
          bosluk: this.brosurUrun.bosluk,
          paraBirimi: this.brosurUrun.paraBirimi,
        });
        this.brosurUrun = {
          urunAdi: "",
          urunFiyat: "",
          bosluk: "  ...............................  ",
          paraBirimi: "",
        };
      }
    },
    deleteItem(index) {
      this.list.splice(index, 1);
    },
    clearProduct() {
      this.product = {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      };
    },
    updateUrunImage(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingUrunImage = true;
        this.product.fileName = file.name;
        var storageRef = fb.storage().ref("images/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.resim = downloadURL;
              let vm = this;
              db.collection("urun")
                .doc(vm.product.docName)
                .update({
                  resim: vm.product.resim,
                  fileName: vm.product.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingUrunImage = false;
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    imageUrunUpload(e) {
      let file = e;
      this.product.fileName = file.name;
      var storageRef = fb.storage().ref("images/" + file.name);
      let uploadTask = storageRef.put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        function (error) {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.product.resim = downloadURL;
            console.log("File available at", downloadURL);
            this.disabled = false;
          });
        }
      );
    },
    getUrunler() {
      db.collection("brosur")
        .doc("brosur")
        .collection("urun")
        .onSnapshot((querySnapshot) => {
          this.urunler = [];
          let fileName = "";
          querySnapshot.forEach((doc) => {
            fetch(doc.data().resim)
              .then((r) => {
                return r.blob();
              })
              .then((blobFile) => {
                fileName = window.URL.createObjectURL(
                  new File([blobFile], { type: "image/jpeg" })
                );
                // this.blbUrl = fileName
                this.urunler.push({
                  docName: doc.id,
                  ...doc.data(),
                  blobUrl: fileName,
                });
              });
          });
        });
    },
    deleteUrun(product) {
      let vm = this;
      vm.product.docName = product.docName;
      if (confirm("Ürünü Silmek İstediğinize emin misiniz?")) {
        db.collection("urun")
          .doc(vm.product.docName)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true;
            vm.snackbar.color = "red";
            vm.snackbar.text = "Ürün Silindi";
            //vm.clearProduct()
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    },
    editUrunList() {
      this.urunListDialog = true;
    },
    generatePdf() {},
    downloadBrosur() {},
    editTitle() {
      this.editTitleDialog = true;
    },
    newBrosurClose() {
      this.brosurSecimDialog = false;
    },
    newBrosur() {
      //this.boyutA4Export();
      this.brosurDialog = !this.brosurDialog;
      //if (this.selectedProducts.length > 1) {
      //  this.brosurDialogBoyut = 1200
      // }
    },
    saveProduct() {
      this.loader = true;
      this.urunAdiEkle();
      db.collection("urun")
        .doc(this.product.docName)
        .set({
          id: this.product.id,
          fiyat: this.product.fiyat,
          urunadi: this.product.urunadi,
          urunaciklama: this.product.urunaciklama,
          resim: this.product.resim,
          active: true,
          activeDurum: "Aktif",
          fileName: this.product.fileName,
          paraBirimi: this.product.paraBirimi,
        })
        .then((docRef) => {
          console.log("Document written with ID", docRef);
          this.loader = false;
          this.urunDialog = false;
          this.snackbar.status = true;
          this.snackbar.color = "green darken-3";
          this.snackbar.text = "Yeni Ürün Eklendi";
          //this.urunleriGoster(this.product)
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    selectedProduct(product) {
      this.selectedProducts.push(product);
      if (this.urun1 == true) {
        this.selectedProduct1 = true;
        //this.urun2 = false
      } else if (this.urun2 == true) {
        this.selectedProduct2 = true;
      } else if (this.urun3 == true) {
        this.selectedProduct3 = true;
      } else if (this.urun4 == true) {
        this.selectedProduct4 = true;
      }
    },
    newProduct() {
      this.clearProduct();
      this.title = "Yeni Ürün";
      this.urunDialog = !this.urunDialog;
    },
    editProduct(product) {
      this.title = "Ürün Güncelleme";
      this.product = product;
      this.editUrunDialog = !this.editUrunDialog;
      // console.log(this.product)
    },
    updateProduct() {
      let vm = this;
      vm.updateLoader = true;
      db.collection("urun")
        .doc(vm.product.docName)
        .update({
          urunadi: vm.product.urunadi,
          urunaciklama: vm.product.urunaciklama,
          fiyat: vm.product.fiyat,
          paraBirimi: vm.product.paraBirimi,
        })
        .then(function () {
          console.log("Document successfully updated!");
          vm.updateLoader = false;
          vm.snackbar.status = true;
          vm.snackbar.color = "sari";
          vm.snackbar.text = "Ürün Bilgileri Güncellendi";
          vm.editUrunDialog = !vm.editUrunDialog;
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },

    clearProduct() {
      this.product = {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      };
    },
    urunAdiEkle() {
      var charMap = {
        Ç: "C",
        Ö: "O",
        Ş: "S",
        İ: "I",
        I: "i",
        Ü: "U",
        Ğ: "G",
        ç: "c",
        ö: "o",
        ş: "s",
        ı: "i",
        ü: "u",
        ğ: "g",
      };
      var str = this.product.urunadi;
      var str_array = str.split("");
      for (var i = 0, len = str_array.length; i < len; i++) {
        str_array[i] = charMap[str_array[i]] || str_array[i];
      }
      str = str_array.join("");

      var clearStr = str
        .replace(" ", "")
        .replace("-", "")
        .replace(/[^a-z0-9-.çöşüğı]/gi, "");
      this.product.docName = clearStr;
      //var res = new Array();
      //res = this.kelime.split(" ");
      //var sonuc = res.join("");
      //console.log(sonuc);
    },
  },

  computed: {
    filteredList() {
      if (this.searchKey) {
        return this.urunler.filter((urun) => {
          return urun.urunadi
            .toLowerCase()
            .startsWith(this.searchKey.toLowerCase());
        });
      } else {
        return this.urunler;
      }
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}

.brosurA4 {
  margin-top: 10px;
}
.selectedProductUrunAdiA4 {
  color: white;
  margin-top: 5px;
  margin-left: 5px;
}
.selectedProductUrunAdiA5 {
  color: white;
  margin-top: 3px;
  margin-left: 3px;
}
.selectedProductFiyatTextUrun1A4 {
  margin-top: 35px;
  margin-left: 30px;
  font-weight: 900;
  font-size: 45px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}

.selectedProductFiyatTextUrun1A5 {
  margin-top: 35px;
  margin-left: 30px;
  font-weight: 900;
  font-size: 45px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.selectedProductFiyatTextA4 {
  margin-top: 35px;
  margin-left: 33px;
  font-weight: 900;
  font-size: 25px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.selectedProductFiyatTextA5 {
  margin-top: 25px;
  margin-left: 22px;
  font-weight: 900;
  font-size: 22px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.turkLirasiUrun1 {
  font-size: 30px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  font-weight: 900;
}

.cubukA4 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.cubukA5 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.brosurBackground {
  position: "relative";
}
.brosurTitle {
  margin-top: 8px;
  font-weight: bolder;
}

@media screen and (min-width: 601px) {
  .alGoturToGo {
    margin-left: 20px;
    margin-top: 70px;
  }
  .urunListBosluk {
    margin-top: 50px;
  }
  .urunListFiyat {
    margin-right: 10px;
  }
  .yesil {
    background-color: #ffe414;
    float: left;
    margin-left: 80px;
    margin-top: 20px;
  }
  .selectedProductFiyatAvatar {
    margin-top: 200px;
    margin-left: -17px;
  }
  .selectedProductFiyatAvatarA2 {
    margin-top: 100px;
    margin-left: -10px;
  }

  .selectedProductFiyatText {
    margin-top: 25px;
    margin-left: 18px;
    font-weight: 900;
    font-size: 30px;
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  }
  .turkLirasi {
    font-size: 20px;
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
    font-weight: 900;
  }

  .editAdres {
    margin-bottom: 15px;
  }
  .editAdresA2 {
    margin-bottom: 15px;
  }
  .ambalaj {
    font-size: small;
    font-weight: bolder;
    margin-left: 5px;
  }
  .productImage {
    height: 250px;
  }
  .productImageRow {
    height: 30px;
  }
}

@media (min-width: 300px) and (max-width: 601px) {
  .selectedProductImage {
    height: 150px;
  }
  .selectedProductUrunadi {
    font-size: x-small;
    margin-bottom: 6px;
  }
  .selectedProductUrunAciklama {
    font-size: 8px;
    margin-top: -16px;
  }
  .selectedProductFiyatAvatar {
    width: 65px;
    height: 65px;
    margin-top: 42px;
    margin-left: -7px;
  }
  .selectedProductFiyatText {
    font-size: 11px;
    font-weight: bolder;
    margin-top: 25px;
    margin-left: 11px;
  }
  .brosurTitle {
    font-weight: bolder;
  }
  .selectedAdresTextMedium {
    font-size: x-small;
    font-weight: bolder;
  }
  .selectedAdresTextSmall {
    font-size: xx-small;
    margin-bottom: 0px;
  }
  .yesil {
    background-color: #e3dd2f;
  }
  .bosluk {
    margin-top: 0px;
    margin-bottom: -13px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .bosluk8 {
    margin-top: 0px;
    margin-bottom: -10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .urunListFiyat {
    margin-right: 3px;
  }
  .alGoturToGo {
    font-size: 9px;
    font-weight: bolder;
  }
  .urunListBosluk {
    margin-top: 20px;
  }
  .editAdres {
    margin-bottom: 3px;
  }
  .alGoturCard {
    height: 150px;
    width: 250;
  }
  .ambalaj {
    font-size: xx-small;
    font-weight: bolder;
    margin-top: -15px;
    margin-left: 1px;
  }
  .alGoturToGoText {
    font-size: xx-small;
    font-weight: bolder;
  }
  .alGoturToGoNokta {
    font-size: 5px;
    font-weight: bolder;
  }
  .adresAlanMedium {
    font-size: 7px;
    font-weight: bolder;
  }
  .adresAlanSmall {
    font-size: 7px;
    font-weight: medium;
  }
  .adresAlanBold {
    font-size: 10px;
    font-weight: bolder;
  }
  .brosurTitle8 {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: bolder;
  }
  .brosurBtn {
    font-size: small;
    margin-right: 45px;
  }
  .productImage {
    height: 150px;
  }
  .productImageRow {
    height: 15px;
  }
  .productUrunadi {
    font-size: small;
  }
  .productFiyat {
    font-size: large;
  }
  .productActive {
    font-size: xx-small;
  }
  .productSwitch {
    font-size: xx-small;
  }
  .opacity-0 {
    opacity: 0 !important;
  }
}
</style>
