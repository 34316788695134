import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import corsModule from 'cors'

const firebaseConfig = {
  apiKey: "AIzaSyBdLvcRcMbqPhcZqb14vjlphEROCKXuMI8",
  authDomain: "muhabbetsofrasi-a07d6.firebaseapp.com",
  projectId: "muhabbetsofrasi-a07d6",
  storageBucket: "muhabbetsofrasi-a07d6.appspot.com",
  messagingSenderId: "949338084548",
  appId: "1:949338084548:web:680a262b416cc58885e591",
  measurementId: "G-NR6MNCFN9J"
};

/*const firebaseConfig = {
  apiKey: "AIzaSyBVj5Q7Pre0S7DxuBOAl9XErA6Va3St7rs",
  authDomain: "online-brochure.firebaseapp.com",
  projectId: "online-brochure",
  storageBucket: "online-brochure.appspot.com",
  messagingSenderId: "141095118303",
  appId: "1:141095118303:web:43df549124fb56a971506a",
  measurementId: "G-DNTXNDW2RP"
};*/
// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export { fb, db };
