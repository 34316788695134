<template>
  <v-row dense>
    <v-hover v-slot:default="{ hover }">
      <v-card height="350px">
        <!--buraya eklenecek -->
        <!-- <v-card-text  class="black--text"> Şablon 1 </v-card-text>-->
        <img
         style=" height:100%; width:100%;"
          src="../../assets/sablonlar/sablon3.png"
        >
          <v-expand-transition>
            <div
              v-if="hover"
              class="
                align-center
                justify-center
                d-flex
                transition-fast-in-fast-out
                black
                v-card--reveal
                display-3
                white--text
              "
              style="height: 100%"
            >
              <v-btn outlined color="white" @click="openSablon1()">
                Tasarımı İncele</v-btn
              >
            </div>
          </v-expand-transition>
      </v-card>
    </v-hover>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="500"
        v-model="secilenSablonDialog"
      >
        <v-card class="pa-10">
          <v-card-text>
             <v-overlay :value="overlay" :absolute="absolute">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
             <img
         style=" height:100%; width:100%;"
          src="../../assets/sablonlar/sablon3.png"
        >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="float-right mr-1"
              color="indigo darken-4"
              dark
              tile
              small
              @click="brosurOlustur()"
            >
              Broşür Oluştur
            </v-btn>
            <v-btn
              class="float-right mr-1"
              color="grey"
              tile
              @click="secilenSablonDialog = false"
              small
            >
              Kapat
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
       <v-dialog
        transition="dialog-bottom-transition"
        max-width="500"
        v-model="sablonDialog"
      >
        <v-card>
          <v-card-text>
            <div class="text-center pa-10">
              <v-progress-circular
                :rotate="360"
                :size="100"
                :width="15"
                :value="value"
                color="teal"
              >
                {{ value }}
              </v-progress-circular>
            </div>
            <div class="text-center text-h5 pa-1">Tasarımınız Hazırlanıyor</div>
          </v-card-text>
        </v-card>
      </v-dialog>
  </v-row>
</template>

<script>
import { fb, db } from "../../firebase/fb";
import VueDraggableResizable from "vue-draggable-resizable";
export default {
  data() {
    return {
       opacity: 1,
      absolute: true,
      overlay: false,
      secilenSablonDialog: false,
      interval: {},
      value: 0,
      sablonDialog: false,
    };
  },
  components: {
    VueDraggableResizable,
  },
  unmounted() {},
  mounted() {
   
    //  this.productFiyatAvatarMarginLeft = "-33px";
  },
  methods: {
   openSablon1() {
      this.secilenSablonDialog = true;
    },
    brosurOlustur(){
        this.sablonDialog = true;
        this.interval = setInterval(() => {
          if (this.value === 100) {
            //return (this.value = 0);
            this.$router.push("/home/sablon3");
          }
          this.value += 50;
        }, 1000);
    }
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}

</style>
