<template>
  <v-row>
    <v-col cols="12">
      <v-snackbar
        class="mt-10"
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        right
      >
        {{ snackbar.text }}
        <v-btn dark text icon @click="snackbar.status = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
      <v-card>
        <v-card-title class="mb-3">
          <v-row>
            <v-col sm="6" lg4 md6>
              <v-card-title> Kullanıcılar </v-card-title>
            </v-col>
            <v-col sm="6" lg4 offset-lg4 md6>
              <v-text-field
                v-model="searching"
                append-icon="search"
                single-line
                hide-details
                color="#53646b"
                placeholder="Arama Yap"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="bayiler"
          :search="searching"
          class="elevation-1"
          :footer-props="{ itemsPerPageText: 'Kullanıcılar' }"
        >
          <template v-slot:item.action="{ item }">
            <v-icon color="teal" small class="mr-2" @click="editBayi(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="red" small class="mr-2" @click="deleteBayi(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col>
      <v-dialog v-model="bayiKayitDialog" max-width="600">
        <v-card>
          <v-card-title class="headline">
            Kullanıcı Bilgileri
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="bayiKayitDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="bayi.firmaAdi"
                  label="Firma Adı"
                  prepend-icon="mail"
                  color="sari"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="bayi.mail"
                  label="Mail Adresi"
                  prepend-icon="mail"
                  color="sari"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="bayi.webAdresi"
                  label="Web Adresi"
                  prepend-icon="gesture"
                  color="sari"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="bayi.telefon"
                  label="Telefon"
                  prepend-icon="phone"
                  color="sari"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="bayi.adres"
                  label="Adres"
                  prepend-icon="map"
                  color="sari"
                  rows="2"
                ></v-textarea>
              </v-col>
             <!--  <v-col cols="6">
                <v-text-field
                  v-model="bayi.tarih"
                  label="Başlangıç Tarihi"
                  prepend-icon="watch"
                  color="sari"
                ></v-text-field>
              </v-col>
              -->
              <v-col cols="12">
                 <v-select
                    item-text="name"
                    item-value="name"
                    :items="firma_kategorileri"
                    v-model="bayi.firma_kategori"
                    label="Firma Kategorisi"
                  ></v-select>
              </v-col>
               <v-col
                cols="12"
              >
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      slot="activator"
                      label="Tarih"
                      :value="bayi.tarih"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="bayi.tarih"
                    locale="turkish"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="bayi.username"
                  label="Kullanıcı Adı"
                  prepend-icon="mdi-account"
                  color="sari"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="bayi.password"
                  label="Şifre"
                  prepend-icon="mdi-lock"
                  color="sari"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="sari"
              dark
              @click="saveBayi"
              :loading="bayiKayitLoader"
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mb-10"
            bottom
            color="sari"
            dark
            fab
            fixed
            right
            v-on="on"
            @click="newBayi"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Kullanıcı</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>
<script>
import { db, fb } from "../../firebase/fb";

export default {
  data() {
    return {
       firma_kategorileri:[ 
        { name: 'Mobilya' },
        { name: 'Otel' },
        { name: 'Market' },
        { name: 'Restoran & Cafe' },
        { name: 'Call-Center' },
        { name: 'Teknoloji' },
        { name: 'Demo' },
        ],
      headers: [
        { text: "Firma Adı", value: "firmaAdi" },
        { text: "Firma Kategori", value: "firma_kategori" },
        { text: "Telefon", value: "telefon" },
        { text: "Web Adres", value: "webAdresi" },
        { text: "Mail Adresi", value: "mail" },
        { text: "Başlangıç Tarihi", value: "tarih" },
        
        { text: "", value: "action" },
      ],
      searching: "",
      bayiKayitDialog: false,
      bayiKayitLoader: false,
      bayi: {
        id: null,
        password: "",
        mail: "",
        username: "",
        docName: "",
        firmaAdi: "",
        adres: "",
        telefon: "",
        webAdresi: "",
        firma_kategori:'',
        tarih:this.formatDate(new Date().toISOString().substr(0, 10)),
      },
      snackbar: {
        status: false,
        text: "",
        color: "",
        timeout: 4000,
      },
      bayiler: [],
    };
  },
  mounted() {
    this.getBayi();
     
      //return this.bayi.tarih;
      
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    updateBayi() {
      let vm = this;
      vm.bayiKayitLoader = true;
      db.collection("kullanici")
        .doc(vm.bayi.docName)
        .update({
            username: vm.bayi.username,
            password: vm.bayi.password,
            mail: vm.bayi.mail,
            firmaAdi: vm.bayi.firmaAdi,
            telefon : vm.bayi.telefon,
            webAdresi : vm.bayi.webAdresi,
            adres  :vm.bayi.adres,
            firma_kategori: vm.bayi.firma_kategori,
            tarih :vm.bayi.tarih,
        })
        .then(function () {
          console.log("Document successfully updated!");
          vm.bayiKayitLoader = false;
          vm.snackbar.status = true;
          vm.snackbar.color = "sari";
          vm.snackbar.text = "Kullanıcı Bilgileri Güncellendi";
          vm.bayiKayitDialog = !vm.bayiKayitDialog;
          vm.clearBayi();
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },
    editBayi(bayi) {
      console.log(bayi)
      this.bayi = bayi;
      this.bayi.id = bayi.docName;
      this.bayiKayitDialog = true;
    },
    deleteBayi(bayi) {
      let vm = this;
      vm.bayi.id = bayi.docName;
      if (confirm("Kullanıcı Bilgisini Silmek İstediğinize emin misiniz?")) {
        db.collection("kullanici")
          .doc(vm.bayi.id)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true;
            vm.snackbar.color = "red";
            vm.snackbar.text = "Bayi Bilgileri Silindi";
            vm.clearBayi();
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    },
    clearBayi() {
      this.bayi = {
        id: null,
        username: '',
        password: '',
        mail: '',
        firmaAdi: '',
        telefon: '',
        webAdresi: '',
        adres: '',
        firma_kategori:'',
        tarih:''
      };
    },
    getBayi() {
      db.collection("kullanici").onSnapshot((querySnapshot) => {
        this.bayiler = [];
        querySnapshot.forEach((doc) => {
          this.bayiler.push({ docName: doc.id, ...doc.data() });
        });
        // console.log(this.products)
      });
    },
    newBayi() {
    
      this.clearBayi();
      this.bayiKayitDialog = true;
    },
    saveBayi() {
       const current = new Date();
      this.bayi.tarih = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      console.log(this.bayi.tarih)
      if (this.bayi.id == null) {
        db.collection("kullanici")
          .add({
            username: this.bayi.username,
            password: this.bayi.password,
            mail: this.bayi.mail,
            firmaAdi: this.bayi.firmaAdi,
            telefon: this.bayi.telefon,
            webAdresi: this.bayi.webAdresi,
            adres: this.bayi.adres,
            firma_kategori: this.bayi.firma_kategori,
            tarih: this.bayi.tarih,
          })
          .then((docRef) => {
             db.collection("kullanici").doc(docRef.id).collection("adres").doc("adres").set({
            alan8 : this.bayi.telefon,
            webAdresi : this.bayi.webAdresi,
            alan1  :this.bayi.adres,
            brosurTitle:this.bayi.firmaAdi,
            aciklamaAlani:"Günlük Taze Sebze & Meyvede İndirimlerimiz Devam Ediyor",
            firmaAdi:this.bayi.firmaAdi
          })
          db.collection("kullanici").doc(docRef.id).collection("logo").doc("logo").set({
            fileName :"",
            resim : "",
           
          })
            console.log("Document written with ID");
            this.bayiKayitLoader = false;
            this.bayiKayitDialog = false;
            this.snackbar.status = true;
            this.snackbar.color = "green";
            this.snackbar.text = "Yeni Kullanıcı Eklendi";

          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
        //console.log(this.product)
      } else if (this.bayi.id) {
        this.updateBayi();
      }
    },
  },
};
</script>
