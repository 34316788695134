<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card-title class="headline">
          <v-spacer></v-spacer>
          <div id="editor"></div>
          <v-btn
            id="cmd"
            tile
            color="success"
            class="white--text"
            @click="makePDF"
            :loading="loaderBrosur"
          >
            İNDİR
            <v-icon right dark> mdi-cloud-download </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="d-flex justify-center" id="test_1">
          <div id="capture" ref="capture">
            <v-card width="600" height="700" color="white">
              <v-row>
                <v-col cols="12" class="pa-8 pt-5" v-if="selectedProduct1">
                  <v-hover v-slot:default="{ hover }">
                    <v-img :src="seciliUrun.product1" style="border-radius: 0px 0px 0px 0px" height="660px">
                      <v-expand-transition>
                        <div
                          v-if="hover"
                          class="
                            d-flex
                            transition-fast-in-fast-out
                            primary
                            v-card--reveal
                            display-3
                            white--text
                          "
                          style="
                            height: 100%;
                            width: 100%;
                            z-index: 1;
                            position: absolute;
                          "
                        >
                          <v-btn
                            class="ma-2"
                            tile
                            color="black"
                            dark
                            @click="deleteProductUrunResmi1"
                          >
                            Çıkar
                          </v-btn>
                        </div>
                      </v-expand-transition>
                    </v-img>
                  </v-hover>
                </v-col>
                <v-col cols="12" class="pa-8" v-else>
                  <v-card color="white" height="660px">
                    <v-row style="height: 95px">
                      <v-col cols="9" class="pa-3 mt-n5">
                        <div
                          class="
                            d-flex
                            transition-fast-in-fast-out
                            darken-1
                            v-card--reveal
                            display-3
                            white--text
                          "
                          style="height: 100%"
                        >
                          <v-row justify="space-around">
                            <span class="group pa-2">
                              <v-btn
                                icon
                                color="indigo darken-4"
                                :loading="loadingLogo"
                                @click="selectProduct1"
                              >
                                <v-icon x-large dark>mdi-plus</v-icon>
                              </v-btn>
                            </span>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                
              </v-row>
              <v-row style="margin-top:-238px"  >               
                <v-col cols="12">
                  <v-img contain src="../../../assets/img/gold-royal.png" height="278px" width="600" >
                    <v-row >
                        <v-col cols="1">
                          <a>
                        <vue-draggable-resizable h="90" w="90" style="margin-top:120px">
                        <v-flex 
                          class=""
                          style="
                            
                            width: 100px;
                            height: 100px;
                          "
                          v-if="logoResim"
                          
                        >
                          <v-hover v-slot:default="{ hover }">
                            <v-img contain
                              :src="logo.fileName"
                              width="100%"
                              height="100%"
                            >
                              <v-expand-transition>
                                <div
                                  v-if="hover"
                                  class="
                                    d-flex
                                    transition-fast-in-fast-out
                                    primary
                                    v-card--reveal
                                    display-3
                                    white--text
                                  "
                                  style="height: 100%"
                                >
                                  <v-btn
                                    class="ma-2"
                                    tile
                                    color="black"
                                    dark
                                    @click="deleteProductLogo"
                                  >
                                    Çıkar
                                  </v-btn>
                                </div>
                              </v-expand-transition>
                            </v-img>
                          </v-hover>
                          
                        </v-flex>
                        
                        <v-flex v-else>
                          <v-img
                            style="
                              
                              width: 100px;
                              height: 100px;
                            "
                          >
                            <div
                              class="
                                d-flex
                                transition-fast-in-fast-out
                                v-card--reveal
                                display-3
                                white--text
                              "
                              style="height: 100%"
                            >
                              <v-row justify="space-around">
                                <v-label>
                                  <input
                                    type="file"
                                    hidden
                                    @change="uploadLogo"
                                  />
                                  <h2 style="color: black">LOGO</h2>
                                </v-label>
                              </v-row>
                            </div>
                          </v-img>
                        </v-flex>
                        </vue-draggable-resizable>
                      </a>
                        </v-col>
                      <v-col @dblclick="editAdress" cols="5" class="text-center" style="margin-top:150px">
                        <a>
                        <vue-draggable-resizable w="215" h="30" style="margin-left:95px; margin-top:17px">
                        <p  :style="{
                          'text-align': 'center',
                          'font-size': adresYaziBoyutu.web,
                          color: adresYaziRenk.web,
                        }"
                      >
                       <v-icon :color="adresYaziRenk.web" style="font-size:25px">mdi-earth </v-icon> {{ adres.webAdresi }}
                       </p>
                        </vue-draggable-resizable>
                        </a>
                      </v-col>
                      <v-col cols="6"  class="mt-4 text-center">                        
                          <v-col cols="12" class="">
                            <a>
                        <vue-draggable-resizable w="205" h="30" style="margin-left:45px; margin-top:80px">
                            <v-img height="45px"
                            src="../../../assets/img/grlogo.png">

                            </v-img>
                        </vue-draggable-resizable>
                            </a>
                          </v-col>
                          <v-col @dblclick="editAdress" cols="12" class="mt-1">
                             <a>
                        <vue-draggable-resizable w="175" h="30" style="margin-left:65px">
                            <p :style="{
                            'font-size': adresYaziBoyutu.alan8,
                            color: adresYaziRenk.alan8,
                          }"><v-icon :color="adresYaziRenk.alan8" style="font-size:25px">mdi-phone </v-icon> {{adres.alan8}} 
                          </p>
                        </vue-draggable-resizable>
                             </a>
                          </v-col>
                        
                      </v-col>
                    </v-row>
                  </v-img>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row dense>
      <v-snackbar
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        center
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-row>
    <v-dialog v-model="editTitleDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Başlığı Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="editTitleDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="brosurTitle"
                    label="Yeni Başlık"
                    color="sari"
                    outlined
                    maxlength="6"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <div class="mt-3" v-text="6 - brosurTitle.length"></div>
                </v-col>
                <v-col cols="6">Yazı Boyutu</v-col>
                <v-col cols="6">
                  <input type="number" value="35" @input="changeFontSize" />
                </v-col>
                <v-col cols="6"><label>Yazı Rengi</label></v-col>
                <v-col cols="6">
                  <input
                    id="color-choos"
                    type="color"
                    name="color"
                    value="#efd9b0"
                    @input="changeColor"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="brosurTitle_2"
                    label="Yeni Başlık"
                    color="sari"
                    outlined
                    maxlength="6"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <div class="mt-3" v-text="6 - brosurTitle_2.length"></div>
                </v-col>
                <v-col cols="6">Yazı Boyutu</v-col>
                <v-col cols="6">
                  <input type="number" value="35" @input="changeFontSize_1" />
                </v-col>
                <v-col cols="6"><label>Yazı Rengi</label></v-col>
                <v-col cols="6">
                  <input
                    id="color-choos"
                    type="color"
                    name="color"
                    value="#ccccca"
                    @input="changeColor_1"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="adresKaydet()"
            :loading="adresButon"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="adresEditDiaog"
      @keydown.enter="adresEditDiaog = false"
      max-width="800"
    >
      <v-card>
        <v-card-title class="headline">
          İletişim Bilgilerini Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="adresEditDiaog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="10" lg="5">
                  <v-text-field
                    v-model="adres.alan8"
                    color="sari"
                    outlined
                    maxlength="14"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" lg="1">
                  <div class="mt-3" v-text="14 - adres.alan8.length"></div>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="6">Yazı Boyutu</v-col>
                    <v-col cols="6">
                      <input
                        type="number"
                        value="25"
                        @input="changeFontAdresAlan8"
                      />
                    </v-col>
                    <v-col cols="6"><label>Yazı Rengi</label></v-col>
                    <v-col cols="6">
                      <input
                        id="color-choos"
                        type="color"
                        name="color"
                        value="#3c2b1e"
                        @input="changeColorAdresAlan8"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="10" lg="5">
                  <v-text-field
                    v-model="adres.webAdresi"
                    color="sari"
                    outlined
                    maxlength="24"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" lg="1">
                  <div class="mt-3" v-text="24 - adres.webAdresi.length"></div>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="6">Yazı Boyutu</v-col>
                    <v-col cols="6">
                      <input
                        type="number"
                        value="15"
                        @input="changeFontAdresAlanWeb"
                      />
                    </v-col>
                    <v-col cols="6"><label>Yazı Rengi</label></v-col>
                    <v-col cols="6">
                      <input
                        id="color-choos"
                        type="color"
                        name="color"
                        value="#000000"
                        @input="changeColorAdresAlanWeb"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="adresKaydet()"
            :loading="adresButon"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="yaziSloganDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Yazı Alanını Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="yaziSloganDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="acilisYazi"
                    prepend-icon="bookmark"
                    color="sari"
                    maxlength="15"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                    <div class="mt-3" v-text="15 - acilisYazi.length"></div>
                  </v-col>
                <v-col cols="6">Yazı Boyutu</v-col>
                <v-col cols="6">
                  <input
                    type="number"
                    value="15"
                    @input="changeFontYazi"
                  />
                </v-col>
                <v-col cols="6"><label>Yazı Rengi</label></v-col>
                <v-col cols="6">
                  <input
                    id="color-choos"
                    type="color"
                    name="color"
                    value="#000000"
                    @input="changeColorYazi"
                    
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="adresKaydet()"
            :loading="adresButon"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <!-- A1 Broşür-->
      <v-dialog v-model="brosurDialog" max-width="1200"> </v-dialog>

      <v-dialog v-model="urunDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            {{ title }}
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="urunDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      label="Resim"
                      color="sari"
                      @change="imageUrunUpload"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.urunadi"
                      label="Ürün Adı"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="product.urunaciklama"
                      label="Açıklama"
                      prepend-icon="gesture"
                      color="sari"
                      rows="1"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.fiyat"
                      label="Fiyat"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      item-text="name"
                      item-value="sembol"
                      :items="paraBirimleri"
                      v-model="product.paraBirimi"
                      prepend-icon="euro"
                      label="Para Birimi"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="sari"
              @click="saveProduct"
              :loading="loader"
              dark
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="selectedProductDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="indigo">
            <v-row>
              <v-col cols="8" offset="4">
                <v-text-field
                  v-model="searchKey"
                  cache-items
                  flat
                  hide-no-data
                  hide-details
                  label="Aramak istediğiniz ürünü ekleyin..."
                  solo-inverted
                ></v-text-field>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="selectedProductDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <!--ürünler -->
          <v-row dense class="mt-2">
            <v-col
              cols="6"
              sm="6"
              md="4"
              lg="3"
              v-for="product in filteredList"
              :key="product.id"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card tile color="grey lighten-4">
                  <v-img
                    :aspect-ratio="16 / 9"
                    class="productImage"
                    :src="product.blobUrl"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="
                          d-flex
                          transition-fast-in-fast-out
                          sari
                          v-card--reveal
                          display-3
                          white--text
                        "
                        style="height: 100%"
                      >
                        <v-btn
                          class="ma-2"
                          tile
                          color="black"
                          dark
                          @click="selectedProduct(product)"
                        >
                          Ekle
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text style="position: relative">
                    <v-row no-gutters class="productImageRow">
                      <v-col cols="12">
                        <v-row no-gutters>
                          <v-col cols="12" class="text-center">
                            <h3 class="text-center productUrunadi">
                              {{ product.urunadi }}
                            </h3>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-1"></v-divider>
                    <v-row no-gutters>
                      <v-col cols="12" class="mt-1">
                        <h2 class="text-center">
                          <strong
                            class="font-weight-black productFiyat text-center"
                            style="font-weight: older"
                          >
                            {{ product.fiyat }} {{ product.paraBirimi }}
                          </strong>
                        </h2>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
const clamp = (num, lower = 0, upper) => {
  return num < lower ? lower : num > upper ? upper : num;
};

import { fb, db } from "../../../firebase/fb";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";
import VueDraggableResizable from "vue-draggable-resizable";

export default {
  data() {
    return {
      yaziSloganDialog: false,
      acilisYazi: "DÜĞÜN SOLANU",
      acilis_color:"#efd9b0",
      acilis_font:"10px",
      selectedProduct1: "",
      selectedProduct2: "",
      selectedProduct3: "",
      selectedProduct4: "",
      selectedProduct5: "",
      backgrounds: [],
      urunSayisiDialog: false,
      searchKey: "",
      loadingLogo: false,
      logo: {
        resim: "",
        fileName: "",
      },
      logoButon: true,
      logoResim: false,
      bosluk: true,
      selectedProductFiyatTextUrun1A5: false,
      selectedProductUrunAdiFontSizeUrun1: "30px",
      x: 100,
      y: 100,
      w: 250,
      h: 50,
      min_w: 250,
      min_h: 10,
      max_w: 300,
      max_h: 60,
      windowStyle: {
        width: "400px",
        position: "relative",
      },
      cubukA4Class: true,
      cubukA5Class: false,
      selectedProductFiyatTextA4: true,
      selectedProductFiyatTextA5: false,
      selectedProductUrunAdiFontSize: "20px",
      selectedProductUrunAdiA4: true,
      selectedProductUrunAdiA5: false,
      cubukHeight: "25px",
      brosurA4Class: true,
      brosurA5Class: false,
      adresAlan9Width: "130px",
      adresAlan9Height: "25px",
      isDragging: false,
      dragItemCoords: {
        top: 5,
        left: 5,
      },
      selection: 1,
      brosurBackground: {
        resim: "",
        fileName: "",
      },
      color: "#efd9b0",
      fontSize: "35px",
      color_1: "#ccccca",
      fontSize_1: "35px",
      updateUrunLoader: false,
      editUrunDialog: false,
      urunDialog: false,
      loadingUrunImage: false,
      brosurSecimDialog: false,
      loaderBrosurA2: false,
      brosurDialogA2: false,
      loaderBrosur: false,
      paraBirimleri: [
        { name: "TL", sembol: "TL" },
        { name: "$", sembol: "$" },
        { name: "£", sembol: "£ " },
        { name: "€", sembol: "€" },
      ],
      imageShow: true,
      id: this.$route.params.id,
      brosurDialogBoyut: 800,
      adresAlani: "",
      blbUrl: null,
      adresEditDiaog: false,
      brosurUrun: {
        urunAdi: "",
        urunFiyat: "",
        bosluk: " ...................... ",
        paraBirimi: "",
      },
      urunListDialog: false,
      renk: "success",
      chk: false,
      editTitleDialog: false,
      brosurTitle: "GOLD",
      brosurTitle_2: "ROYAL",
      checked: false,
      editDialog: false,
      disabled: true,
      products: [],
      snackbar: {
        status: false,
        text: "",
        color: "",
        timeout: 2000,
      },
      title: "",
      dialog: false,
      loader: false,
      updateLoader: false,
      product: {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      },
      loading: false,
      selectedProducts: [],
      brosurDialog: false,
      allUrunlerDialog: false,
      list: [],
      adresYaziRenk: {
        alan1: "#000000",
        alan8: "#000000",
        alan9: "#000000",
        alan10: " #ffe414",
        web: "#000000",
        webAdresi: "#4C4C4C",
      },
      adresYaziBoyutu: {
        alan1: "15px",
        alan8: "20px",
        alan9: "15px",
        web: "15px",
        webAdresi: "10px",
      },
      adres: {
        firmaAdi: "KARDELEN",
        alan1: "Ataüni Teknokent Yakutiye ERZURUM",
        alan8: "448448737363",
        alan9: "448448737363",
        webAdresi: "wwww.kardelenyatak.com",
      },
      urunler: [],
      icecekler: [],
      tatlilar: [],
      bayi: {
        id: null,
        docName: "",
      },
      output: null,
      kategoriler: [],
      updateLoader: false,
      urunFiyatDialog: false,
      loadingBgImage: false,
      cubuk: true,
      brosurBoyutWidth: "1080px",
      brosurBoyutHeight: "1080px",
      selectedProductImage: "",
      productFiyatAvatarMarginTop: "",
      productFiyatAvatarMarginLeft: "",
      selectedProductImageUrun1: "",
      adresButon: false,
      instagram: false,
      facebook: false,
      a4Boyut: true,
      a5boyut: false,
      selectedProductDialog: false,
      seciliUrun: {
        product1: "",
        product2: "",
        product3: "",
        product4: "",
        product5: "",
      },
      urun1: false,
      urun2: false,
      urun3: false,
      urun4: false,
      urun5: false,
      fontSizeFirma: "55px",
      colorFirma: "#7ccbd8",
    };
  },

  components: {
    VueDraggableResizable,
  },
  mounted() {
    this.getUrunler();
    this.getBackround();
    this.getAdresBilgi();
    this.getLogo()
  },
  watch: {
    brosurTitle: (val) => {
      this.brosurTitle = val;
    },
    selectedProducts: {
      handler(val, oldVal) {
        if (val.length > 6) {
          this.urunSayisiDialog = true;
        }
      },
    },
    selectedProduct1(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product1 = urunResmi;
        this.seciliUrun.urunadi1 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat1 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    
  },
  methods: {
    getLogo(){
      db.collection("kullanici")
                    .doc(this.$route.params.id)
                    .collection("logo")
                    .doc("logo")
                    .onSnapshot((doc) => {
                      let fileName = "";
                      fetch(doc.data().resim)
                        .then((r) => {
                          return r.blob();
                        })
                        .then((blobFile) => {
                          fileName = window.URL.createObjectURL(
                            new File([blobFile], { type: "image/jpeg" })
                          );
                          this.logo.fileName = fileName;
                          if(this.logo.fileName){
                            this.logoResim = true
                          }
                          else{
                            this.logoResim= false
                          }
                        });
                    });
    },
    editSlogan() {
      this.yaziSloganDialog = true;
    },
    deleteProductUrunResmi1() {
      this.selectedProduct1 = false;
    },
    deleteProductUrunResmi2() {
      this.selectedProduct2 = false;
    },
    deleteProductUrunResmi3() {
      this.selectedProduct3 = false;
    },
    deleteProductUrunResmi4() {
      this.selectedProduct4 = false;
    },
    deleteProductUrunResmi5() {
      this.selectedProduct5 = false;
    },
    selectProduct1() {
      this.selectedProductDialog = true;
      this.urun1 = true;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
    },
    selectProduct2() {
      this.selectedProductDialog = true;
      this.urun2 = true;
      this.urun1 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
    },
    selectProduct3() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = true;
      this.urun4 = false;
      this.urun5 = false;
    },
    selectProduct4() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = true;
      this.urun5 = false;
    },
    selectProduct5() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = true;
    },
    boyutInstagramExport() {
      this.instagram = true;
      this.a4Boyut = false;
      this.a5Boyut = false;
      this.facebook = false;
      this.brosurBoyutWidth = "500px";
      this.brosurBoyutHeight = "500px";
      if (this.selectedProducts.length == 1) {
        this.brosurA4Class = false;
        this.bosluk = false;
        this.selectedProductImageUrun1 = "300px";
        this.productFiyatAvatarMarginTopUrun1 = "165px";
        this.selectedProductUrunAdiFontSizeUrun1 = "30px";
        this.cubukHeight = "15px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (this.selectedProducts.length == 2) {
        this.brosurA4Class = false;
        this.selectedProductImageUrun1 = "145px";
        this.productFiyatAvatarMarginTopUrun1 = "10px";
        this.selectedProductUrunAdiFontSizeUrun1 = "15px";
        this.cubukHeight = "15px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (
        this.selectedProducts.length > 2 &&
        this.selectedProducts.length < 5
      ) {
        this.selectedProductImage = "135px";
        this.productFiyatAvatarMarginTop = "36px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.brosurA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = true;
      } else if (
        this.selectedProducts.length > 4 &&
        this.selectedProducts.length < 7
      ) {
        this.selectedProductImage = "85px";
        this.productFiyatAvatarMarginTop = "-16px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.brosurA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = true;
      }
    },
    boyutA4Export() {
      this.instagram = false;
      this.a4Boyut = true;
      this.a5Boyut = false;
      this.facebook = false;
      this.brosurA4Class = true;
      this.brosurBoyutWidth = "595px";
      this.brosurBoyutHeight = "842px";
      if (this.selectedProducts.length == 1) {
        this.selectedProductImageUrun1 = "570px";
        this.productFiyatAvatarMarginTopUrun1 = "435px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (this.selectedProducts.length == 2) {
        this.selectedProductImageUrun1 = "295px";
        this.productFiyatAvatarMarginTopUrun1 = "160px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (
        this.selectedProducts.length > 2 &&
        this.selectedProducts.length < 5
      ) {
        this.selectedProductImage = "295px";
        this.productFiyatAvatarMarginTop = "194px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiA4 = true;
        this.selectedProductUrunAdiA5 = false;
      } else if (
        this.selectedProducts.length > 4 &&
        this.selectedProducts.length < 7
      ) {
        this.selectedProductImage = "193px";
        this.productFiyatAvatarMarginTop = "89px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiA4 = true;
        this.selectedProductUrunAdiA5 = false;
        // this.selectedProductFiyatTextA4 = true;
      }
    },
    boyutA5Export() {
      this.instagram = false;
      this.a4Boyut = false;
      this.a5Boyut = true;
      this.facebook = false;
      this.brosurBoyutWidth = " 421px";
      this.brosurBoyutHeight = "605px";
      if (this.selectedProducts.length == 1) {
        this.selectedProductUrunAdiA4 = false;
        this.selectedProductUrunAdiA5 = true;
        this.selectedProductUrunAdiFontSizeUrun1 = "20px";
        this.fontSize = "23px";
        this.cubukA5Class = true;
        this.cubukA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.cubukHeight = "20px";
        this.brosurA4Class = false;
        this.brosurA5Class = true;
        this.selectedProductImageUrun1 = "405px";
        this.productFiyatAvatarMarginTopUrun1 = "270px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (this.selectedProducts.length == 2) {
        this.selectedProductImageUrun1 = "188px";
        this.productFiyatAvatarMarginTopUrun1 = "55px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiFontSizeUrun1 = "15px";
        this.cubukHeight = "20px";
      } else if (
        this.selectedProducts.length > 2 &&
        this.selectedProducts.length < 5
      ) {
        this.selectedProductImage = "175px";
        this.productFiyatAvatarMarginTop = "75px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = false;
        this.selectedProductUrunAdiA5 = true;
        // this.selectedProductFiyatTextA4 = true;
      } else if (
        this.selectedProducts.length > 4 &&
        this.selectedProducts.length < 7
      ) {
        this.selectedProductImage = "122px";
        this.productFiyatAvatarMarginTop = "23px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiA4 = false;
        this.selectedProductUrunAdiA5 = true;
        this.selectedProductFiyatTextA4 = false;
        this.selectedProductFiyatTextA5 = true;
        this.cubukA5Class = true;
        this.cubukA4Class = false;
        this.cubukHeight = "20px";
        this.fontSize = "20px";
        this.selectedProductUrunAdiFontSize = "15px";
        // this.selectedProductFiyatTextA4 = true;
      }
    },
    boyutFacebookExport() {
      this.instagram = false;
      this.a4Boyut = false;
      this.a5Boyut = false;
      this.facebook = true;
      this.brosurBoyutWidth = "600px";
      this.brosurBoyutHeight = "600px";
      if (this.selectedProducts.length == 1) {
        this.brosurA4Class = false;
        this.bosluk = false;
        this.selectedProductImageUrun1 = "390px";
        this.productFiyatAvatarMarginTopUrun1 = "250px";
        this.selectedProductUrunAdiFontSizeUrun1 = "30px";
        this.cubukHeight = "15px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (this.selectedProducts.length == 2) {
        this.brosurA4Class = false;
        this.selectedProductImageUrun1 = "195px";
        this.productFiyatAvatarMarginTopUrun1 = "60px";
        this.selectedProductUrunAdiFontSizeUrun1 = "15px";
        this.cubukHeight = "15px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (
        this.selectedProducts.length > 2 &&
        this.selectedProducts.length < 5
      ) {
        this.selectedProductImage = "195px";
        this.productFiyatAvatarMarginTop = "95px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.brosurA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = true;
      } else if (
        this.selectedProducts.length > 4 &&
        this.selectedProducts.length < 7
      ) {
        this.selectedProductImage = "118px";
        this.productFiyatAvatarMarginTop = "18px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.brosurA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = true;
      }
    },
    getAdresBilgi() {
      db.collection("kullanici").doc(this.$route.params.id).collection("adres")
        .doc("adres")
        .onSnapshot((doc) => {
         // this.brosurTitle = doc.data().brosurTitle;
          this.adres = {
            alan1: doc.data().alan1,
            alan8: doc.data().alan8,
            webAdresi: doc.data().webAdresi,
          };
        });
    },
    adresKaydet() {
      this.yaziSloganDialog = false;
      this.adresEditDiaog = false;
      this.editTitleDialog = false;
      this.snackbar.status = true;
      this.snackbar.color = "green darken-3";
      this.snackbar.text = "Bilgileriniz Kaydedildi";
       db.collection("kullanici").doc(this.$route.params.id).collection("adres")
        .doc("adres")
        .update({
          alan1: this.adres.alan1,
          alan8: this.adres.alan8,
          brosurTitle: this.brosurTitle,
          webAdresi: this.adres.webAdresi,
        })
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },
    deleteProductLogo() {
      this.logoResim = false;
    },
    deleteProductUrunResmi() {
      this.logoResim = false;
      this.logoButon = true;
    },
    uploadLogo(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingLogo = true;
        this.logo.fileName = file.name;
        var storageRef = fb.storage().ref("logo/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.logo.resim = downloadURL;
              let vm = this;
             db.collection("kullanici")
             .doc(vm.$route.params.id)
             .collection("logo")
                .doc("logo")
                .set({
                  resim: vm.logo.resim,
                  fileName: vm.logo.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingLogo = false;
                  vm.logoButon = false;
                  vm.logoResim = true;
                  
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    /* Boyutlar */

    updateBackgroundImage(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingBgImage = true;
        this.brosurBackground.fileName = file.name;
        var storageRef = fb.storage().ref("background/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.brosurBackground.resim = downloadURL;
              let vm = this;
              db.collection("arka_plan")
                .doc("resim")
                .update({
                  resim: vm.brosurBackground.resim,
                  fileName: vm.brosurBackground.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingBgImage = false;
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    getBackround() {
      db.collection("arka_plan")
        .doc("resim")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    changeWidthAdresAlan9: function (event) {
      this.adresAlan9Width = event.target.value + "px";
    },
    changeHeightAdresAlan9: function (event) {
      this.adresAlan9Height = event.target.value + "px";
    },
    changeColorAdresAlan10: function (event) {
      this.adresYaziRenk.alan10 = event.target.value;
    },
    changeColorAdresAlan9: function (event) {
      this.adresYaziRenk.alan9 = event.target.value;
    },
    changeFontAdresAlan9: function (event) {
      this.adresYaziBoyutu.alan9 = event.target.value + "px";
    },
    changeColorAdresAlan8: function (event) {
      this.adresYaziRenk.alan8 = event.target.value;
    },
    changeFontAdresAlan8: function (event) {
      if (event.target.value < 20) {
        this.adresYaziBoyutu.alan8 = event.target.value + "px";
      } else {
        this.adresYaziBoyutu.alan8 = "20px";
      }
      
    },
    changeColorAdresAlanWeb: function (event) {
      this.adresYaziRenk.web = event.target.value;
    },
    changeFontAdresAlanWeb: function (event) {
      if (event.target.value < 15) {
        this.adresYaziBoyutu.web = event.target.value + "px";
      } else {
        this.adresYaziBoyutu.web = "15px";
      }
    },
    changeColorAdresAlan1: function (event) {
      this.adresYaziRenk.alan1 = event.target.value;
    },
    changeFontAdresAlan1: function (event) {
      if (event.target.value < 20) {
        this.adresYaziBoyutu.alan1 = event.target.value + "px";
      } else {
        this.adresYaziBoyutu.alan1 = "20px";
      }
      
    },
    changeFontYazi: function (event) {
       if (event.target.value <25) {
        this.acilis_font = event.target.value + "px";
      } else {
        this.acilis_font = "25px";
      }
      
    },
    changeColorYazi: function (event) {
      this.acilis_color = event.target.value;
    },
    changeFontSize: function (event) {
       if (event.target.value < 40) {
        this.fontSize = event.target.value + "px";
      } else {
        this.fontSize = "40px";
      }
      
    },
    changeColor: function (event) {
      this.color = event.target.value;
    },
    changeFontSize_1: function (event) {
       if (event.target.value < 40) {
        this.fontSize_1 = event.target.value + "px";
      } else {
        this.fontSize_1 = "40px";
      }
      
    },
    changeColor_1: function (event) {
      this.color_1 = event.target.value;
    },
    brosurA1() {
      this.brosurDialog = !this.brosurDialog;
    },
    brosurA2() {
      this.brosurDialogA2 = !this.brosurDialogA2;
    },
    async print() {
      const el = this.$refs.capture;
      const options = {
        type: "dataURL",
      };
      this.output = await this.$html2canvas(el, options);
      var link = document.createElement("a");
      link.download = "brosur.png";
      link.href = this.output;
      document.body.appendChild(link);
      link.click();
    },
    makePDF() {
      this.loaderBrosur = true;
      const self = this;
      var canvas = this.$refs.capture;
      html2canvas(canvas, { type: "dataURL" }).then(function (canvas) {
        // var myImage = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
        // var link = document.createElement('a')
        // link.download = 's.png'
        // link.href = myImage
        // document.body.appendChild(link)
        // link.click()

        self.print();
      });
      setTimeout(() => {
        this.loaderBrosur = false;
      }, 1000);
    },

    newMusteriLink() {
      this.$router.push({ path: `/customer-login/${this.$route.params.id}` });
    },
    imageShowDelete() {
      this.imageShow = false;
    },
    deleteProduct(product) {
      console.log(product);
      this.selectedProducts.splice(product, 1);
    },
    deleteAdresItem(index) {
      this.adres.splice(index, 1);
    },
    editAdress() {
      this.adresEditDiaog = true;
    },
    addItem() {
      if (this.brosurUrun.urunAdi !== "") {
        this.list.push({
          urunAdi: this.brosurUrun.urunAdi,
          urunFiyat: this.brosurUrun.urunFiyat,
          bosluk: this.brosurUrun.bosluk,
          paraBirimi: this.brosurUrun.paraBirimi,
        });
        this.brosurUrun = {
          urunAdi: "",
          urunFiyat: "",
          bosluk: "  ...............................  ",
          paraBirimi: "",
        };
      }
    },
    deleteItem(index) {
      this.list.splice(index, 1);
    },
    clearProduct() {
      this.product = {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      };
    },
    updateUrunImage(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingUrunImage = true;
        this.product.fileName = file.name;
        var storageRef = fb.storage().ref("images/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.resim = downloadURL;
              let vm = this;
              db.collection("urun")
                .doc(vm.product.docName)
                .update({
                  resim: vm.product.resim,
                  fileName: vm.product.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingUrunImage = false;
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    imageUrunUpload(e) {
      let file = e;
      this.product.fileName = file.name;
      var storageRef = fb.storage().ref("images/" + file.name);
      let uploadTask = storageRef.put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        function (error) {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.product.resim = downloadURL;
            console.log("File available at", downloadURL);
            this.disabled = false;
          });
        }
      );
    },
    getUrunler() {
      db.collection("kullanici")
        .doc(this.$route.params.id)
        .collection("urun")
        .onSnapshot((querySnapshot) => {
          this.urunler = [];
          let fileName = "";
          querySnapshot.forEach((doc) => {
            fetch(doc.data().resim)
              .then((r) => {
                return r.blob();
              })
              .then((blobFile) => {
                fileName = window.URL.createObjectURL(
                  new File([blobFile], { type: "image/jpeg" })
                );
                // this.blbUrl = fileName
                this.urunler.push({
                  docName: doc.id,
                  ...doc.data(),
                  blobUrl: fileName,
                });
              });
          });
        });
    },
    deleteUrun(product) {
      let vm = this;
      vm.product.docName = product.docName;
      if (confirm("Ürünü Silmek İstediğinize emin misiniz?")) {
        db.collection("urun")
          .doc(vm.product.docName)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true;
            vm.snackbar.color = "red";
            vm.snackbar.text = "Ürün Silindi";
            //vm.clearProduct()
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    },
    editUrunList() {
      this.urunListDialog = true;
    },
    generatePdf() {},
    downloadBrosur() {},
    editTitle() {
      this.editTitleDialog = true;
    },
    newBrosurClose() {
      this.brosurSecimDialog = false;
    },
    newBrosur() {
      //this.boyutA4Export();
      this.brosurDialog = !this.brosurDialog;
      //if (this.selectedProducts.length > 1) {
      //  this.brosurDialogBoyut = 1200
      // }
    },
    saveProduct() {
      this.loader = true;
      this.urunAdiEkle();
      db.collection("urun")
        .doc(this.product.docName)
        .set({
          id: this.product.id,
          fiyat: this.product.fiyat,
          urunadi: this.product.urunadi,
          urunaciklama: this.product.urunaciklama,
          resim: this.product.resim,
          active: true,
          activeDurum: "Aktif",
          fileName: this.product.fileName,
          paraBirimi: this.product.paraBirimi,
        })
        .then((docRef) => {
          console.log("Document written with ID", docRef);
          this.loader = false;
          this.urunDialog = false;
          this.snackbar.status = true;
          this.snackbar.color = "green darken-3";
          this.snackbar.text = "Yeni Ürün Eklendi";
          //this.urunleriGoster(this.product)
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    selectedProduct(product) {
      this.selectedProducts.push(product);
      if (this.urun1 == true) {
        this.selectedProduct1 = true;
        //this.urun2 = false
      } else if (this.urun2 == true) {
        this.selectedProduct2 = true;
      } else if (this.urun3 == true) {
        this.selectedProduct3 = true;
      } else if (this.urun4 == true) {
        this.selectedProduct4 = true;
      } else if (this.urun5 == true) {
        this.selectedProduct5 = true;
      }
    },
    newProduct() {
      this.clearProduct();
      this.title = "Yeni Ürün";
      this.urunDialog = !this.urunDialog;
    },
    editProduct(product) {
      this.title = "Ürün Güncelleme";
      this.product = product;
      this.editUrunDialog = !this.editUrunDialog;
      // console.log(this.product)
    },
    updateProduct() {
      let vm = this;
      vm.updateLoader = true;
      db.collection("urun")
        .doc(vm.product.docName)
        .update({
          urunadi: vm.product.urunadi,
          urunaciklama: vm.product.urunaciklama,
          fiyat: vm.product.fiyat,
          paraBirimi: vm.product.paraBirimi,
        })
        .then(function () {
          console.log("Document successfully updated!");
          vm.updateLoader = false;
          vm.snackbar.status = true;
          vm.snackbar.color = "sari";
          vm.snackbar.text = "Ürün Bilgileri Güncellendi";
          vm.editUrunDialog = !vm.editUrunDialog;
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },

    clearProduct() {
      this.product = {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      };
    },
    urunAdiEkle() {
      var charMap = {
        Ç: "C",
        Ö: "O",
        Ş: "S",
        İ: "I",
        I: "i",
        Ü: "U",
        Ğ: "G",
        ç: "c",
        ö: "o",
        ş: "s",
        ı: "i",
        ü: "u",
        ğ: "g",
      };
      var str = this.product.urunadi;
      var str_array = str.split("");
      for (var i = 0, len = str_array.length; i < len; i++) {
        str_array[i] = charMap[str_array[i]] || str_array[i];
      }
      str = str_array.join("");

      var clearStr = str
        .replace(" ", "")
        .replace("-", "")
        .replace(/[^a-z0-9-.çöşüğı]/gi, "");
      this.product.docName = clearStr;
      //var res = new Array();
      //res = this.kelime.split(" ");
      //var sonuc = res.join("");
      //console.log(sonuc);
    },
  },

  computed: {
    filteredList() {
      if (this.searchKey) {
        return this.urunler.filter((urun) => {
          return urun.urunadi
            .toLowerCase()
            .startsWith(this.searchKey.toLowerCase());
        });
      } else {
        return this.urunler;
      }
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}

.brosurA4 {
  margin-top: 10px;
}
.selectedProductUrunAdiA4 {
  color: white;
  margin-top: 5px;
  margin-left: 5px;
}
.selectedProductUrunAdiA5 {
  color: white;
  margin-top: 3px;
  margin-left: 3px;
}
.selectedProductFiyatTextUrun1A4 {
  margin-top: 35px;
  margin-left: 30px;
  font-weight: 900;
  font-size: 45px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}

.selectedProductFiyatTextUrun1A5 {
  margin-top: 35px;
  margin-left: 30px;
  font-weight: 900;
  font-size: 45px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.selectedProductFiyatTextA4 {
  margin-top: 25px;
  margin-left: 23px;
  font-weight: 900;
  font-size: 25px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.selectedProductFiyatTextA5 {
  margin-top: 25px;
  margin-left: 22px;
  font-weight: 900;
  font-size: 22px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.turkLirasiUrun1 {
  font-size: 30px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  font-weight: 900;
}

.cubukA4 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.cubukA5 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.brosurBackground {
  position: "relative";
}
.brosurTitle {
  margin-top: 8px;
}

@media screen and (min-width: 601px) {
  .alGoturToGo {
    margin-left: 20px;
    margin-top: 70px;
  }
  .urunListBosluk {
    margin-top: 50px;
  }
  .urunListFiyat {
    margin-right: 10px;
  }
  .yesil {
    background-color: #ffe414;
    float: left;
    margin-left: 80px;
    margin-top: 20px;
  }
  .selectedProductFiyatAvatar {
    margin-top: 200px;
    margin-left: -17px;
  }
  .selectedProductFiyatAvatarA2 {
    margin-top: 100px;
    margin-left: -10px;
  }

  .selectedProductFiyatText {
    margin-top: 25px;
    margin-left: 18px;
    font-weight: 900;
    font-size: 30px;
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  }
  .turkLirasi {
    font-size: 20px;
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
    font-weight: 900;
  }

  .editAdres {
    margin-bottom: 15px;
  }
  .editAdresA2 {
    margin-bottom: 15px;
  }
  .ambalaj {
    font-size: small;
    font-weight: bolder;
    margin-left: 5px;
  }
  .productImage {
    height: 250px;
  }
  .productImageRow {
    height: 30px;
  }
}

@media (min-width: 300px) and (max-width: 601px) {
  .selectedProductImage {
    height: 150px;
  }
  .selectedProductUrunadi {
    font-size: x-small;
    margin-bottom: 6px;
  }
  .selectedProductUrunAciklama {
    font-size: 8px;
    margin-top: -16px;
  }
  .selectedProductFiyatAvatar {
    width: 65px;
    height: 65px;
    margin-top: 42px;
    margin-left: -7px;
  }
  .selectedProductFiyatText {
    font-size: 11px;
    font-weight: bolder;
    margin-top: 25px;
    margin-left: 11px;
  }
  
  .selectedAdresTextMedium {
    font-size: x-small;
    font-weight: bolder;
  }
  .selectedAdresTextSmall {
    font-size: xx-small;
    margin-bottom: 0px;
  }
  .yesil {
    background-color: #e3dd2f;
  }
  .bosluk {
    margin-top: 0px;
    margin-bottom: -13px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .bosluk8 {
    margin-top: 0px;
    margin-bottom: -10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .urunListFiyat {
    margin-right: 3px;
  }
  .alGoturToGo {
    font-size: 9px;
    font-weight: bolder;
  }
  .urunListBosluk {
    margin-top: 20px;
  }
  .editAdres {
    margin-bottom: 3px;
  }
  .alGoturCard {
    height: 150px;
    width: 250;
  }
  .ambalaj {
    font-size: xx-small;
    font-weight: bolder;
    margin-top: -15px;
    margin-left: 1px;
  }
  .alGoturToGoText {
    font-size: xx-small;
    font-weight: bolder;
  }
  .alGoturToGoNokta {
    font-size: 5px;
    font-weight: bolder;
  }
  .adresAlanMedium {
    font-size: 7px;
    font-weight: bolder;
  }
  .adresAlanSmall {
    font-size: 7px;
    font-weight: medium;
  }
  .adresAlanBold {
    font-size: 10px;
    font-weight: bolder;
  }
  .brosurTitle8 {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: bolder;
  }
  .brosurBtn {
    font-size: small;
    margin-right: 45px;
  }
  .productImage {
    height: 150px;
  }
  .productImageRow {
    height: 15px;
  }
  .productUrunadi {
    font-size: small;
  }
  .productFiyat {
    font-size: large;
  }
  .productActive {
    font-size: xx-small;
  }
  .productSwitch {
    font-size: xx-small;
  }
  .opacity-0 {
    opacity: 0 !important;
  }
}
</style>
