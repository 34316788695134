import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/dashboard/home";
import Login from "../components/login/login";
import Urunler from "../components/admin/urunler";
import KullaniciAnasayfa from "../components/kullanici/anasayfa";
import KullaniciUrunler from "../components/kullanici/urunler";
import KullaniciLogo from "../components/kullanici/logo";
import Kategoriler from "../components/admin/kategoriler";
import SistemHakkinda from "../components/admin/sistem-hakkinda";
import Lisans from "../components/admin/lisans";
import Bos from "../components/dashboard/bos.vue";
import Users from "../components/admin/kullanicilar";
import Sablonlar from "../components/sablonlar/sablon";
import Brosur from "../components/brosur/brosur1"
import Sablon1 from "../components/brosur/brosur"
import Sablon2 from "../components/brosur/brosur2"
import Sablon3 from "../components/brosur/brosur3"
import Sablon4 from "../components/brosur/brosur4"
import Sablon5 from "../components/brosur/brosur5"
import Sablon6 from "../components/brosur/brosur6"
import Sablon7 from "../components/brosur/brosur7"
import Sablon8 from "../components/brosur/brosur8"
import Sablon9 from "../components/brosur/brosur9"
import Sablon10 from "../components/brosur/brosur10"
import Sablon11 from "../components/brosur/brosur11"
import Sablon12 from "../components/brosur/brosur12"
import Sablon13 from "../components/brosur/brosur13"
import Sablon14 from "../components/brosur/brosur14"
import Sablon15 from "../components/brosur/brosur15"
import Sablon16 from "../components/brosur/brosur16"
import Sablon17 from "../components/brosur/brosur17"
import Sablon18 from "../components/brosur/brosur18"
import Sablon19 from "../components/brosur/brosur19"
import Sablon20 from "../components/brosur/brosur20"
import Sablon21 from "../components/brosur/brosur21"
import Sablon22 from "../components/brosur/brosur22"
import Sablon23 from "../components/brosur/brosur23"
import Sablon24 from "../components/brosur/brosur24"
import Sablon25 from "../components/brosur/brosur25"
import Sablon26 from "../components/brosur/brosur26"
import Sablon27 from "../components/brosur/brosur27"
import Sablon28 from "../components/brosur/brosur28"
import Sablon29 from "../components/brosur/brosur29"
import Sablon30 from "../components/brosur/brosur30"
import Sablon31 from "../components/brosur/brosur31"
import Sablon32 from "../components/brosur/brosur32"
import Sablon33 from "../components/brosur/brosur33"
import Sablon34 from "../components/brosur/brosur34"
import Sablon35 from "../components/brosur/brosur35"
import Sablon36 from "../components/brosur/brosur36"
import Sablon37 from "../components/brosur/brosur37"
import Sablon38 from "../components/brosur/brosur38"
import Sablon39 from "../components/brosur/brosur39"
import Sablon40 from "../components/brosur/brosur40"
import Sablon41 from "../components/brosur/brosur41"
import Sablon42 from "../components/brosur/brosur42"
import Sablon43 from "../components/brosur/brosur43"
import Sablon44 from "../components/brosur/brosur44"
import Sablon45 from "../components/brosur/brosur45"
import Sablon46 from "../components/brosur/brosur46"
import Sablon47 from "../components/brosur/brosur47"
import Sablon48 from "../components/brosur/brosur48"
import BrosurUrunler from "../components/brosur/urunler"
import TumSablonlar from "../components/sablonlar/sablonlar";
import SosyalMedyaAnasayfa from "../components/sosyal-medya-sablon/home";
import SosyalMedyaSablon1 from "../components/sosyal-medya-brosur/brosur1";
import SosyalMedyaSablon2 from "../components/sosyal-medya-brosur/brosur2";
import SosyalMedyaSablon3 from "../components/sosyal-medya-brosur/brosur3";
import SosyalMedyaSablon4 from "../components/sosyal-medya-brosur/brosur4";
import SosyalMedyaSablon5 from "../components/sosyal-medya-brosur/brosur5";
import SosyalMedyaSablon6 from "../components/sosyal-medya-brosur/brosur6";
import SosyalMedyaSablon7 from "../components/sosyal-medya-brosur/brosur7";
import SosyalMedyaSablon8 from "../components/sosyal-medya-brosur/brosur8";



/**************************************************************/
/*****************    DEMO   *******************************/
/**************************************************************/
import DemoSablon1 from "../components/brosur/demo-brosur/brosur1"
import DemoSablon2 from "../components/brosur/demo-brosur/brosur2"
import DemoSablon3 from "../components/brosur/demo-brosur/brosur3"
import DemoSablon4 from "../components/brosur/demo-brosur/brosur4"
import DemoSablon5 from "../components/brosur/demo-brosur/brosur5"
import DemoSablon6 from "../components/brosur/demo-brosur/brosur6"
import DemoSablon7 from "../components/brosur/demo-brosur/brosur7"
import DemoSablon8 from "../components/brosur/demo-brosur/brosur8"
import DemoSablon9 from "../components/brosur/demo-brosur/brosur9"
import DemoSablon10 from "../components/brosur/demo-brosur/brosur10"
import DemoSablon11 from "../components/brosur/demo-brosur/brosur11"
import DemoSablon12 from "../components/brosur/demo-brosur/brosur12"
import DemoSablon13 from "../components/brosur/demo-brosur/brosur13"
import DemoSablon14 from "../components/brosur/demo-brosur/brosur14"
import DemoSablon15 from "../components/brosur/demo-brosur/brosur15"
import DemoSablon16 from "../components/brosur/demo-brosur/brosur16"
import DemoSablon17 from "../components/brosur/demo-brosur/brosur17"
import DemoSablon18 from "../components/brosur/demo-brosur/brosur18"
import DemoSablon19 from "../components/brosur/demo-brosur/brosur19"
import DemoSablon20 from "../components/brosur/demo-brosur/brosur20"
import DemoSablon21 from "../components/brosur/demo-brosur/brosur21"
import DemoSablon22 from "../components/brosur/demo-brosur/brosur22"
import DemoSablon23 from "../components/brosur/demo-brosur/brosur23"
import DemoSablon24 from "../components/brosur/demo-brosur/brosur24"
import DemoSablon25 from "../components/brosur/demo-brosur/brosur25"
import DemoSablon26 from "../components/brosur/demo-brosur/brosur26"
import DemoSablon27 from "../components/brosur/demo-brosur/brosur27"
import DemoSablon28 from "../components/brosur/demo-brosur/brosur28"
import DemoSablon29 from "../components/brosur/demo-brosur/brosur29"
import DemoSablon30 from "../components/brosur/demo-brosur/brosur30"
import DemoSablon31 from "../components/brosur/demo-brosur/brosur31"
import DemoSablon32 from "../components/brosur/demo-brosur/brosur32"
import DemoSablon33 from "../components/brosur/demo-brosur/brosur33"
import DemoSablon34 from "../components/brosur/demo-brosur/brosur34"
import DemoSablon35 from "../components/brosur/demo-brosur/brosur35"
import DemoSablon41 from "../components/brosur/demo-brosur/brosur41"
import DemoSablon42 from "../components/brosur/demo-brosur/brosur42"
import DemoSablon43 from "../components/brosur/demo-brosur/brosur43"
import DemoSablon44 from "../components/brosur/demo-brosur/brosur44"
import DemoSablon45 from "../components/brosur/demo-brosur/brosur45"
import DemoSablon46 from "../components/brosur/demo-brosur/brosur46"
//import DemoSablon47 from "../components/brosur/demo-brosur/brosur47"



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  
  {
    path: "/users/:id",
    name: "users",
    component: KullaniciAnasayfa,
    children: [
      {
        path: "sablonlar",
        name: "sablonlar",
        component: TumSablonlar
      },
      {
        path: "sistem-hakkinda",
        name: "sistem-hakkinda",
        component: SistemHakkinda
      },
      {
        path: "lisans",
        name: "lisans",
        component: Lisans
      },
      {
        path: "urunler",
        name: "urunler",
        component: KullaniciUrunler
      },
      
      {
        path: "logo",
        name: "logo",
        component: KullaniciLogo
      },
      /*******************************/
      /**********DEMO**********/
      {
        path: "demoSablon1",
        name: "demoSablon1",
        component: DemoSablon1
      },
      {
        path: "demoSablon2",
        name: "demoSablon2",
        component: DemoSablon2
      },
      {
        path: "demoSablon3",
        name: "demoSablon3",
        component: DemoSablon3
      },
      {
        path: "demoSablon4",
        name: "demoSablon4",
        component: DemoSablon4
      },
      {
        path: "demoSablon5",
        name: "demoSablon5",
        component: DemoSablon5
      },
      {
        path: "demoSablon6",
        name: "demoSablon6",
        component: DemoSablon6
      },
      {
        path: "demoSablon7",
        name: "demoSablon7",
        component: DemoSablon7
      },
      {
        path: "demoSablon8",
        name: "demoSablon8",
        component: DemoSablon8
      },
      {
        path: "demoSablon9",
        name: "demoSablon9",
        component: DemoSablon9
      },
      {
        path: "demoSablon10",
        name: "demoSablon10",
        component: DemoSablon10
      },
      {
        path: "demoSablon11",
        name: "demoSablon11",
        component: DemoSablon11
      },
      {
        path: "demoSablon12",
        name: "demoSablon12",
        component: DemoSablon12
      },
      {
        path: "demoSablon13",
        name: "demoSablon13",
        component: DemoSablon13
      },
      {
        path: "demoSablon14",
        name: "demoSablon14",
        component: DemoSablon14
      },
      {
        path: "demoSablon15",
        name: "demoSablon15",
        component: DemoSablon15
      },
      {
        path: "demoSablon16",
        name: "demoSablon16",
        component: DemoSablon16
      },
      {
        path: "demoSablon17",
        name: "demoSablon17",
        component: DemoSablon17
      },
      {
        path: "demoSablon18",
        name: "demoSablon18",
        component: DemoSablon18
      },
      {
        path: "demoSablon19",
        name: "demoSablon19",
        component: DemoSablon19
      },
      {
        path: "demoSablon20",
        name: "demoSablon20",
        component: DemoSablon20
      },
      {
        path: "demoSablon21",
        name: "demoSablon21",
        component: DemoSablon21
      },
      {
        path: "demoSablon22",
        name: "demoSablon22",
        component: DemoSablon22
      },
      {
        path: "demoSablon23",
        name: "demoSablon23",
        component: DemoSablon23
      },
      {
        path: "demoSablon24",
        name: "demoSablon24",
        component: DemoSablon24
      },
      {
        path: "demoSablon25",
        name: "demoSablon25",
        component: DemoSablon25
      },
      {
        path: "demoSablon26",
        name: "demoSablon26",
        component: DemoSablon26
      },
      {
        path: "demoSablon27",
        name: "demoSablon27",
        component: DemoSablon27
      },
      {
        path: "demoSablon28",
        name: "demoSablon28",
        component: DemoSablon28
      },
      {
        path: "demoSablon29",
        name: "demoSablon29",
        component: DemoSablon29
      },
      {
        path: "demoSablon30",
        name: "demoSablon30",
        component: DemoSablon30
      },
      {
        path: "demoSablon31",
        name: "demoSablon31",
        component: DemoSablon31
      },
      {
        path: "demoSablon32",
        name: "demoSablon32",
        component: DemoSablon32
      },
      {
        path: "demoSablon33",
        name: "demoSablon33",
        component: DemoSablon33
      },
      {
        path: "demoSablon34",
        name: "demoSablon34",
        component: DemoSablon34
      },
      {
        path: "demoSablon35",
        name: "demoSablon35",
        component: DemoSablon35
      },
      {
        path: "demoSablon41",
        name: "demoSablon41",
        component: DemoSablon41
      },
      {
        path: "demoSablon42",
        name: "demoSablon42",
        component: DemoSablon42
      },
      {
        path: "demoSablon43",
        name: "demoSablon43",
        component: DemoSablon43
      },
      {
        path: "demoSablon44",
        name: "demoSablon44",
        component: DemoSablon44
      },
      {
        path: "demoSablon45",
        name: "demoSablon45",
        component: DemoSablon45
      },
      {
        path: "demoSablon46",
        name: "demoSablon46",
        component: DemoSablon46
      },
      
      
    ]
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    children: [
      {
        path: "users",
        name: "users",
        component: Users
      },
      {
        path: "sablon1",
        name: "sablon1",
        component: Sablon1
      },
      {
        path: "sablon2",
        name: "sablon2",
        component: Sablon2
      },
      {
        path: "sablon3",
        name: "sablon3",
        component: Sablon3
      },
      {
        path: "sablon4",
        name: "sablon4",
        component: Sablon4
      },
      {
        path: "sablon5",
        name: "sablon5",
        component: Sablon5
      },
      {
        path: "sablon6",
        name: "sablon6",
        component: Sablon6
      },
      {
        path: "sablon7",
        name: "sablon7",
        component: Sablon7
      },
      {
        path: "sablon8",
        name: "sablon8",
        component: Sablon8
      },
      {
        path: "sablon9",
        name: "sablon9",
        component: Sablon9
      },
      {
        path: "sablon10",
        name: "sablon10",
        component: Sablon10
      },
      {
        path: "sablon11",
        name: "sablon11",
        component: Sablon11
      },
      {
        path: "sablon12",
        name: "sablon12",
        component: Sablon12
      },
      {
        path: "sablon13",
        name: "sablon13",
        component: Sablon13
      },
      {
        path: "sablon14",
        name: "sablon14",
        component: Sablon14
      },
      {
        path: "sablon15",
        name: "sablon15",
        component: Sablon15
      },
      {
        path: "sablon16",
        name: "sablon16",
        component: Sablon16
      },
      {
        path: "sablon17",
        name: "sablon17",
        component: Sablon17
      },
      {
        path: "sablon18",
        name: "sablon18",
        component: Sablon18
      },
      {
        path: "sablon19",
        name: "sablon19",
        component: Sablon19
      },
      {
        path: "sablon20",
        name: "sablon20",
        component: Sablon20
      },
      {
        path: "sablon21",
        name: "sablon21",
        component: Sablon21
      },
      {
        path: "sablon22",
        name: "sablon22",
        component: Sablon22
      },
      {
        path: "sablon23",
        name: "sablon23",
        component: Sablon23
      },
      {
        path: "sablon24",
        name: "sablon24",
        component: Sablon24
      },
      {
        path: "sablon25",
        name: "sablon25",
        component: Sablon25
      },
      {
        path: "sablon26",
        name: "sablon26",
        component: Sablon26
      },
      {
        path: "sablon27",
        name: "sablon27",
        component: Sablon27
      },
      {
        path: "sablon28",
        name: "sablon28",
        component: Sablon28
      },
      {
        path: "sablon29",
        name: "sablon29",
        component: Sablon29
      },
      {
        path: "sablon30",
        name: "sablon30",
        component: Sablon30
      },
      {
        path: "sablon31",
        name: "sablon31",
        component: Sablon31
      },
      {
        path: "sablon32",
        name: "sablon32",
        component: Sablon32
      },
      {
        path: "sablon33",
        name: "sablon33",
        component: Sablon33
      },
      {
        path: "sablon34",
        name: "sablon34",
        component: Sablon34
      },
      {
        path: "sablon35",
        name: "sablon35",
        component: Sablon35
      },
      {
        path: "sablon36",
        name: "sablon36",
        component: Sablon36
      },{
        path: "sablon37",
        name: "sablon37",
        component: Sablon37
      },{
        path: "sablon38",
        name: "sablon38",
        component: Sablon38
      },
      {
        path: "sablon39",
        name: "sablon39",
        component: Sablon39
      },
      {
        path: "sablon40",
        name: "sablon40",
        component: Sablon40
      },
      {
        path: "sablon41",
        name: "sablon41",
        component: Sablon41
      },
      {
        path: "sablon42",
        name: "sablon42",
        component: Sablon42
      },
      {
        path: "sablon43",
        name: "sablon43",
        component: Sablon43
      },
      {
        path: "sablon44",
        name: "sablon44",
        component: Sablon44
      },
      {
        path: "sablon45",
        name: "sablon45",
        component: Sablon45
      },
      {
        path: "sablon46",
        name: "sablon46",
        component: Sablon46
      },
      {
        path: "sablon47",
        name: "sablon47",
        component: Sablon47
      },
      {
        path: "sablon48",
        name: "sablon48",
        component: Sablon48
      },
      
      {
        path: "brosurUrunler",
        name: "brosurUrunler",
        component: BrosurUrunler
      },
      {
        path: "brosur",
        name: "brosur",
        component: Brosur
      },

      {
        path: "sistem-hakkinda",
        name: "sistem-hakkinda",
        component: SistemHakkinda
      },
      {
        path: "lisans",
        name: "lisans",
        component: Lisans
      },
      {
        path: "urunler",
        name: "urunler",
        component: Urunler
      },
      {
        path: "sosyal-medya",
        name: "sosyal-medya",
        component: SosyalMedyaAnasayfa
      },
      {
        path: "sablonlar",
        name: "sablonlar",
        component: Sablonlar
      },
      {
        path: "sosyal-medya-sablon1",
        name: "sosyal-medya-sablon1",
        component: SosyalMedyaSablon1
      },
      {
        path: "sosyal-medya-sablon2",
        name: "sosyal-medya-sablon2",
        component: SosyalMedyaSablon2
      },
      {
        path: "sosyal-medya-sablon3",
        name: "sosyal-medya-sablon3",
        component: SosyalMedyaSablon3
      },
      {
        path: "sosyal-medya-sablon4",
        name: "sosyal-medya-sablon4",
        component: SosyalMedyaSablon4
      },
      {
        path: "sosyal-medya-sablon5",
        name: "sosyal-medya-sablon5",
        component: SosyalMedyaSablon5
      },
      {
        path: "sosyal-medya-sablon6",
        name: "sosyal-medya-sablon6",
        component: SosyalMedyaSablon6
      },
      {
        path: "sosyal-medya-sablon7",
        name: "sosyal-medya-sablon7",
        component: SosyalMedyaSablon7
      },
      {
        path: "sosyal-medya-sablon8",
        name: "sosyal-medya-sablon8",
        component: SosyalMedyaSablon8
      },
      
    ]
  },
  
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
