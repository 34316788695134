<template>
  <v-app id="sandbox">
    <v-navigation-drawer v-model="drawer" app color="grey darken-4" dark>
      <v-list></v-list>
      <v-list rounded>
        <template v-for="item in items">
          <v-list-item :key="item.text" link :to="item.route" @click="titleTextView(item.text)">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app flat color="#EEEEEE">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" /> 
      <!-- 
         <v-icon
      class="mr-2"
      @click="$router.go(-1)"
    >
      mdi-keyboard-backspace
    </v-icon>
      -->
      <v-toolbar-title>{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!--
      <span class="mt-2">
        <v-text-field
          color="#d58f4c"
          prepend-inner-icon="mdi-magnify"
          label="Ara"
        /></span>
        -->

    </v-app-bar>

    <v-content class="grey lighten-3">
      <v-container fluid color="#EEEEEE">
        <router-view></router-view>
      </v-container>
    </v-content>

    <v-footer :inset="footer.inset" color="#EEEEEE" app>
      <v-spacer></v-spacer>
      <span class="px-4">&copy; {{ new Date().getFullYear() }} by Bilmar Bilişim Teknolojileri Ltd Şti.</span>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    title:'Ürünler',
    footer: {
      inset: true,
    },
    items: [
      /**
      {
        icon: 'dashboard',
        text: 'Ürünler',
        route: '/home/urunler',
      },
      /** */
      {
        icon: 'person',
        text: 'Kullanıcılar',
        route: '/home/users',
      },
      /**
      {
        icon: ' all_inclusive',
        text: 'Şablonlar',
        route: '/home/sablonlar',
         subLinks: [
            { text: 'Market Broşürleri' },
            { text: 'Mobilya Broşürleri' },
            { text: 'Otel Broşürleri' }
          ]
      },
      /** */
      /**
      {
        icon: ' wysiwyg',
        text: 'Sosyal Medya Şablonları',
        route: '/home/sosyal-medya',
      },
      /** */

      /**
       {
        icon: 'wysiwyg',
        text: 'Lisans',
        route: '/home/lisans',
      },
       */
      /**
      {
        icon: 'settings_applications',
        text: 'Sistem Hakkında',
        route: '/home/sistem-hakkinda',
      },
      /** */
      {
        icon: 'power_settings_new',
        text: 'Çıkış Yap',
        route: '/',
      },
      /**
      {
        icon: 'fas fa-bars',
        text: 'Menü',
        route: '/menu',
      },
     */
    ],
  }),
  methods:{
     cikisYap() {
      setTimeout(() => {
        this.$router.push('/')
      }, 1000)
    },
    titleTextView(item){
      this.title = item
    }
  },

}
</script>
