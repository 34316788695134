<template>
  <div>
    <v-row>
      <v-col cols="12" class="mx-auto">
        <v-row>
          <v-col cols="3">
            <sablon1></sablon1>
          </v-col>
           <v-col cols="3">
            <sablon2></sablon2>
          </v-col>
           <v-col cols="3">
            <sablon3></sablon3>
          </v-col>
          <v-col cols="3">
            <sablon4></sablon4>
          </v-col>
          <v-col cols="3">
            <sablon5></sablon5>
          </v-col>
          <v-col cols="3">
            <sablon6></sablon6>
          </v-col>
          <v-col cols="3">
            <sablon7></sablon7>
          </v-col>
          <v-col cols="3">
            <sablon8></sablon8>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { fb, db } from "../../firebase/fb";
import Sablon1 from "../sosyal-medya-sablon/sablon1";
import Sablon2 from "../sosyal-medya-sablon/sablon2";
import Sablon3 from "../sosyal-medya-sablon/sablon3";
import Sablon4 from "../sosyal-medya-sablon/sablon4";
import Sablon5 from "../sosyal-medya-sablon/sablon5";
import Sablon6 from "../sosyal-medya-sablon/sablon6";
import Sablon7 from "../sosyal-medya-sablon/sablon7";
import Sablon8 from "../sosyal-medya-sablon/sablon8";
export default {
  data() {
    return {
      secilenSablonDialog: false,
      opacity: 1,
      absolute: true,
      overlay: false,
      sablonDialog: false,
      interval: {},
      value: 0,
    };
  },
  components: {
    sablon1: Sablon1,
    sablon2: Sablon2,
    sablon3: Sablon3,
    sablon4: Sablon4,
    sablon5: Sablon5,
    sablon6: Sablon6,
    sablon7: Sablon7,
    sablon8: Sablon8,
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  mounted() {
    this.overlay = true;
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 2000);
    },
  },
  methods: {},
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}
.v-progress-circular {
  margin: 1rem;
}
</style>