<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="8">
            <v-text-field
              v-model="searchKey"
              cache-items
              flat
              hide-no-data
              hide-details
              label="Aramak istediğiniz ürünü ekleyin..."
              solo-inverted
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
              large
              class="float-right brosurBtn"
              tile
              color="green darken-4"
              dark
              @click="newProduct"
            >
              <v-icon left> mdi-plus </v-icon>
              YENİ ÜRÜN
            </v-btn>
            <v-flex>
              <BrosurUrunler :listdata="selectedProducts"></BrosurUrunler>
            </v-flex>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense>
      <v-snackbar
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        center
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-row>
    <v-row dense class="mt-2">
      <v-col
        cols="6"
        sm="6"
        md="4"
        lg="3"
        v-for="product in filteredList"
        :key="product.id"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card tile color="grey lighten-4">
            <v-img
              class="productImage"
              :src="product.blobUrl"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="
                    d-flex
                    transition-fast-in-fast-out
                    sari
                    v-card--reveal
                    display-3
                    white--text
                  "
                  style="height: 100%"
                >
                  <v-btn
                    class="ma-2"
                    tile
                    color="black"
                    dark
                    @click="selectedProduct(product)"
                  >
                    Ekle
                  </v-btn>
                </div>
              </v-expand-transition>
            </v-img>
            <v-card-text style="position: relative">
              <v-row no-gutters class="productImageRow">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="12" class="text-center">
                      <h3 class="text-center productUrunadi">
                        {{ product.urunadi }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider class="mt-1"></v-divider>
              <v-row no-gutters>
                <v-col cols="9" class="mt-1">
                  <h2 class="text-center">
                    <strong
                      class="font-weight-black productFiyat text-center"
                      style="font-weight: older"
                    >
                      {{ product.fiyat }} {{ product.paraBirimi }}
                    </strong>
                  </h2>
                </v-col>
                <v-col cols="3" class="mt-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="teal darken-1"
                        dark
                        v-on="on"
                        @click="editProduct(product)"
                      >
                        edit
                      </v-icon>
                    </template>
                    <span>Düzenle</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="red darken-1"
                        dark
                        v-on="on"
                        @click="deleteUrun(product)"
                      >
                        delete
                      </v-icon>
                    </template>
                    <span>Sil</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <div>
      <v-dialog v-model="urunDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            {{ title }}
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="urunDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      label="Resim"
                      color="sari"
                      @change="imageUrunUpload"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.urunadi"
                      label="Ürün Adı"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="product.urunaciklama"
                      label="Açıklama"
                      prepend-icon="gesture"
                      color="sari"
                      rows="1"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.fiyat"
                      label="Fiyat"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      item-text="name"
                      item-value="sembol"
                      :items="paraBirimleri"
                      v-model="product.paraBirimi"
                      prepend-icon="euro"
                      label="Para Birimi"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="sari"
              @click="saveProduct"
              :loading="loader"
              dark
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--Ürünler Edit Dialog-->
      <v-dialog v-model="editUrunDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            {{ title }}
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="editUrunDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <span class="ml-5">
                      <v-label>Resim</v-label>
                    </span>
                    <v-img
                      :src="product.resim"
                      aspect-ratio="1"
                      class="grey lighten-2 align-end ml-5"
                      max-width="300"
                      max-height="150"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :loading="loadingUrunImage"
                            color="pink darken-1"
                            small
                            class="float-right"
                          >
                            <v-label>
                              <input
                                type="file"
                                hidden
                                @change="updateUrunImage"
                              />
                              <v-icon>mdi-plus</v-icon>
                            </v-label>
                          </v-btn>
                        </template>
                        <span>Yeni Resim</span>
                      </v-tooltip>
                    </v-img>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.urunadi"
                      label="Ürün Adı"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="product.urunaciklama"
                      label="Açıklama"
                      prepend-icon="gesture"
                      color="sari"
                      rows="1"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.fiyat"
                      label="Fiyat"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      item-text="name"
                      item-value="sembol"
                      :items="paraBirimleri"
                      v-model="product.paraBirimi"
                      prepend-icon="euro"
                      label="Para Birimi"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="sari"
              @click="updateProduct"
              :loading="updateLoader"
              dark
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
const clamp = (num, lower = 0, upper) => {
  return num < lower ? lower : num > upper ? upper : num;
};

import { fb, db } from "../../firebase/fb";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";
import VueDraggableResizable from "vue-draggable-resizable";
import Brosur1 from "../brosur/brosur1.vue";
import BrosurUrunler from "../brosur/urunler.vue"
import { eventBus } from '../../main.js'

export default {
  data() {
    return {
      urunSayisiDialog: false,
      searchKey: "",
      loadingLogo: false,
      logo: {
        resim: "",
        fileName: "",
      },
      logoButon: true,
      logoResim: false,
      bosluk: false,
      selectedProductFiyatTextUrun1A5: false,
      selectedProductUrunAdiFontSizeUrun1: "30px",
      x: 100,
      y: 100,
      w: 250,
      h: 50,
      min_w: 250,
      min_h: 10,
      max_w: 300,
      max_h: 60,
      windowStyle: {
        width: "400px",
        position: "relative",
      },
      cubukA4Class: true,
      cubukA5Class: false,
      selectedProductFiyatTextA4: true,
      selectedProductFiyatTextA5: false,
      selectedProductUrunAdiFontSize: "20px",
      selectedProductUrunAdiA4: true,
      selectedProductUrunAdiA5: false,
      cubukHeight: "25px",
      brosurA4Class: true,
      brosurA5Class: false,
      adresAlan9Width: "130px",
      adresAlan9Height: "25px",
      isDragging: false,
      dragItemCoords: {
        top: 5,
        left: 5,
      },
      selection: 1,
      brosurBackground: {
        resim: "",
        fileName: "",
      },
      color: "#3F51B5",
      fontSize: "25px",
      updateUrunLoader: false,
      editUrunDialog: false,
      urunDialog: false,
      loadingUrunImage: false,
      brosurSecimDialog: false,
      loaderBrosurA2: false,
      brosurDialogA2: false,
      loaderBrosur: false,
      paraBirimleri: [
        { name: "TL", sembol: "TL" },
        { name: "$", sembol: "$" },
        { name: "£", sembol: "£ " },
        { name: "€", sembol: "€" },
      ],
      imageShow: true,
      id: this.$route.params.id,
      brosurDialogBoyut: 800,
      adresAlani: "",
      blbUrl: null,
      adresEditDiaog: false,
      brosurUrun: {
        urunAdi: "",
        urunFiyat: "",
        bosluk: " ...................... ",
        paraBirimi: "",
      },
      urunListDialog: false,
      renk: "success",
      chk: false,
      editTitleDialog: false,
      brosurTitle: "NFS SOFT",
      checked: false,
      editDialog: false,
      disabled: true,
      products: [],
      snackbar: {
        status: false,
        text: "",
        color: "",
        timeout: 500,
      },
      title: "",
      dialog: false,
      loader: false,
      updateLoader: false,
      product: {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      },
      loading: false,
      selectedProducts: [],
      brosurDialog: false,
      allUrunlerDialog: false,
      list: [],
      adresYaziRenk: {
        alan1: "#000000",
        alan8: "#000000",
        alan9: "#000000",
        alan10: " #ffe414",
      },
      adresYaziBoyutu: {
        alan1: "15",
        alan8: "30",
        alan9: "15",
      },
      adres: {
        alan1: "Ataüni Teknokent Yakutiye ERZURUM",
        alan8: "448448737363",
        alan9: "448448737363",
      },
      urunler: [],
      icecekler: [],
      tatlilar: [],
      bayi: {
        id: null,
        docName: "",
      },
      output: null,
      kategoriler: [],
      updateLoader: false,
      urunFiyatDialog: false,
      loadingBgImage: false,
      cubuk: true,
      brosurBoyutWidth: "595px",
      brosurBoyutHeight: "842px",
      selectedProductImage: "300px",
      productFiyatAvatarMarginTop: " 200px",
      productFiyatAvatarMarginLeft: "-17px",
      selectedProductImageUrun1: "450px",
    };
  },

  components: {
    VueDraggableResizable,
    brosur1: Brosur1,
    BrosurUrunler:BrosurUrunler,

  },
  mounted() {
    this.getUrunler();
  },
  methods: {
    brosurA1() {
      this.brosurDialog = !this.brosurDialog;
    },
    imageShowDelete() {
      this.imageShow = false;
    },
    deleteProduct(product) {
      this.selectedProducts.splice(product, 1);
    },
    deleteAdresItem(index) {
      this.adres.splice(index, 1);
    },
    editAdress() {
      this.adresEditDiaog = true;
    },
    addItem() {
      if (this.brosurUrun.urunAdi !== "") {
        this.list.push({
          urunAdi: this.brosurUrun.urunAdi,
          urunFiyat: this.brosurUrun.urunFiyat,
          bosluk: this.brosurUrun.bosluk,
          paraBirimi: this.brosurUrun.paraBirimi,
        });
        this.brosurUrun = {
          urunAdi: "",
          urunFiyat: "",
          bosluk: "  ...............................  ",
          paraBirimi: "",
        };
      }
    },
    deleteItem(index) {
      this.list.splice(index, 1);
    },
    clearProduct() {
      this.product = {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      };
    },
    updateUrunImage(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingUrunImage = true;
        this.product.fileName = file.name;
        var storageRef = fb.storage().ref("images/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.resim = downloadURL;
              let vm = this;
              db.collection("urun")
                .doc(vm.product.docName)
                .update({
                  resim: vm.product.resim,
                  fileName: vm.product.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingUrunImage = false;
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    imageUrunUpload(e) {
      let file = e;
      this.product.fileName = file.name;
      var storageRef = fb.storage().ref("images/" + file.name);
      let uploadTask = storageRef.put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        function (error) {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.product.resim = downloadURL;
            console.log("File available at", downloadURL);
            this.disabled = false;
          });
        }
      );
    },
    getUrunler() {
      db.collection("brosur")
              .doc("brosur").collection("urun")
        .onSnapshot((querySnapshot) => {
          this.urunler = [];
          let fileName = "";
          querySnapshot.forEach((doc) => {
            fetch(doc.data().resim)
              .then((r) => {
                return r.blob();
              })
              .then((blobFile) => {
                fileName = window.URL.createObjectURL(
                  new File([blobFile], { type: "image/jpeg" })
                );
                // this.blbUrl = fileName
                this.urunler.push({
                  docName: doc.id,
                  ...doc.data(),
                  blobUrl: fileName,
                });
              });
          });
        });
    },
    deleteUrun(product) {
      let vm = this;
      vm.product.docName = product.docName;
      if (confirm("Ürünü Silmek İstediğinize emin misiniz?")) {
        db.collection("urun")
          .doc(vm.product.docName)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true;
            vm.snackbar.color = "red";
            vm.snackbar.text = "Ürün Silindi";
            //vm.clearProduct()
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    },
    editUrunList() {
      this.urunListDialog = true;
    },
   
    editTitle() {
      this.editTitleDialog = true;
    },
    newBrosurClose() {
      this.brosurSecimDialog = false;
    },
    newBrosur() {
      
      this.brosurDialog = !this.brosurDialog;
      //if (this.selectedProducts.length > 1) {
      //  this.brosurDialogBoyut = 1200
      // }
    },
    saveProduct() {
      this.loader = true;
      this.urunAdiEkle();
      db.collection("urun")
        .doc(this.product.docName)
        .set({
          id: this.product.id,
          fiyat: this.product.fiyat,
          urunadi: this.product.urunadi,
          urunaciklama: this.product.urunaciklama,
          resim: this.product.resim,
          active: true,
          activeDurum: "Aktif",
          fileName: this.product.fileName,
          paraBirimi: this.product.paraBirimi,
        })
        .then((docRef) => {
          console.log("Document written with ID", docRef);
          this.loader = false;
          this.urunDialog = false;
          this.snackbar.status = true;
          this.snackbar.color = "green darken-3";
          this.snackbar.text = "Yeni Ürün Eklendi";
          //this.urunleriGoster(this.product)
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    selectedProduct(product) {
      //console.log(product);
      // eslint-disable-next-line eqeqeq
      if (this.selectedProducts.length == 0) {
        this.selectedProducts.push(product);
          this.snackbar.status = true;
          this.snackbar.color = "green darken-3";
          this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
      } else if (this.selectedProducts.length > 0) {
        if (
          this.selectedProducts.find(
            (element) => element.docName === product.docName
          )
        ) {
          this.snackbar.status = true;
          this.snackbar.color = "red darken-3";
          this.snackbar.text = "Bu Ürünü Daha Önce Eklemiştiniz...";
        } else {
          this.selectedProducts.push(product);
          this.snackbar.status = true;
          this.snackbar.color = "green darken-3";
          this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
          setTimeout(() => {
          this.$emit('selectedProductToBrosur','merhaba')
          }, 3000)
         
        }
      }
    },
    newProduct() {
      this.clearProduct();
      this.title = "Yeni Ürün";
      this.urunDialog = !this.urunDialog;
    },
    editProduct(product) {
      this.title = "Ürün Güncelleme";
      this.product = product;
      this.editUrunDialog = !this.editUrunDialog;
      // console.log(this.product)
    },
    updateProduct() {
      let vm = this;
      vm.updateLoader = true;
      db.collection("urun")
        .doc(vm.product.docName)
        .update({
          urunadi: vm.product.urunadi,
          urunaciklama: vm.product.urunaciklama,
          fiyat: vm.product.fiyat,
          paraBirimi: vm.product.paraBirimi,
        })
        .then(function () {
          console.log("Document successfully updated!");
          vm.updateLoader = false;
          vm.snackbar.status = true;
          vm.snackbar.color = "sari";
          vm.snackbar.text = "Ürün Bilgileri Güncellendi";
          vm.editUrunDialog = !vm.editUrunDialog;
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },

    clearProduct() {
      this.product = {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      };
    },

    urunAdiEkle() {
      var charMap = {
        Ç: "C",
        Ö: "O",
        Ş: "S",
        İ: "I",
        I: "i",
        Ü: "U",
        Ğ: "G",
        ç: "c",
        ö: "o",
        ş: "s",
        ı: "i",
        ü: "u",
        ğ: "g",
      };
      var str = this.product.urunadi;
      var str_array = str.split("");
      for (var i = 0, len = str_array.length; i < len; i++) {
        str_array[i] = charMap[str_array[i]] || str_array[i];
      }
      str = str_array.join("");

      var clearStr = str
        .replace(" ", "")
        .replace("-", "")
        .replace(/[^a-z0-9-.çöşüğı]/gi, "");
      this.product.docName = clearStr;
      //var res = new Array();
      //res = this.kelime.split(" ");
      //var sonuc = res.join("");
      //console.log(sonuc);
    },
  },
  watch: {
    brosurTitle: (val) => {
      this.brosurTitle = val;
    },
    selectedProducts: {
      handler(val, oldVal) {
        if (val.length == 1) {
          this.selectedProductImageUrun1 = "450px";
          this.bosluk = true;
          this.productFiyatAvatarMarginTopUrun1 = "315px";
          // this.selectedProductFiyatTextA4 = true;
        } else if (val.length == 2) {
          this.selectedProductImageUrun1 = "250px";
          this.bosluk = true;
          this.productFiyatAvatarMarginTopUrun1 = "115px";
          // this.selectedProductFiyatTextA4 = true;
        } else if (val.length > 4 && val.length < 7) {
          this.selectedProductImage = "220px";
          this.productFiyatAvatarMarginTop = "120px";
          this.bosluk = true;
          // this.selectedProductFiyatTextA4 = true;
        }
        if (val.length > 6) {
          this.urunSayisiDialog = true;
        } else {
          this.bosluk = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    filteredList() {
      if (this.searchKey) {
        return this.urunler.filter((urun) => {
          return urun.urunadi
            .toLowerCase()
            .startsWith(this.searchKey.toLowerCase());
        });
      } else {
        return this.urunler;
      }
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}


@media screen and (min-width: 601px) {
 
  .productImage {
    height: 250px;
  }
  .productImageRow {
    height: 30px;
  }
}

@media (min-width: 300px) and (max-width: 601px) {
  .selectedProductImage {
    height: 150px;
  }
  .selectedProductUrunadi {
    font-size: x-small;
    margin-bottom: 6px;
  }
  .selectedProductUrunAciklama {
    font-size: 8px;
    margin-top: -16px;
  }
  .selectedProductFiyatAvatar {
    width: 65px;
    height: 65px;
    margin-top: 42px;
    margin-left: -7px;
  }
  .selectedProductFiyatText {
    font-size: 11px;
    font-weight: bolder;
    margin-top: 25px;
    margin-left: 11px;
  }
  .brosurTitle {
    font-weight: bolder;
  }
  .selectedAdresTextMedium {
    font-size: x-small;
    font-weight: bolder;
  }
  .selectedAdresTextSmall {
    font-size: xx-small;
    margin-bottom: 0px;
  }
  .yesil {
    background-color: #e3dd2f;
  }
  .bosluk {
    margin-top: 0px;
    margin-bottom: -13px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .bosluk8 {
    margin-top: 0px;
    margin-bottom: -10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .urunListFiyat {
    margin-right: 3px;
  }
  .alGoturToGo {
    font-size: 9px;
    font-weight: bolder;
  }
  .urunListBosluk {
    margin-top: 20px;
  }
  .editAdres {
    margin-bottom: 3px;
  }
  .alGoturCard {
    height: 150px;
    width: 250;
  }
  .ambalaj {
    font-size: xx-small;
    font-weight: bolder;
    margin-top: -15px;
    margin-left: 1px;
  }
  .alGoturToGoText {
    font-size: xx-small;
    font-weight: bolder;
  }
  .alGoturToGoNokta {
    font-size: 5px;
    font-weight: bolder;
  }
  .adresAlanMedium {
    font-size: 7px;
    font-weight: bolder;
  }
  .adresAlanSmall {
    font-size: 7px;
    font-weight: medium;
  }
  .adresAlanBold {
    font-size: 10px;
    font-weight: bolder;
  }
  .brosurTitle8 {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: bolder;
  }
  .brosurBtn {
    font-size: small;
    margin-right: 45px;
  }
  .productImage {
    height: 150px;
  }
  .productImageRow {
    height: 15px;
  }
  .productUrunadi {
    font-size: small;
  }
  .productFiyat {
    font-size: large;
  }
  .productActive {
    font-size: xx-small;
  }
  .productSwitch {
    font-size: xx-small;
  }
  .opacity-0 {
    opacity: 0 !important;
  }
}
</style>
