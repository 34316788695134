<template>
  <v-container class="container fill-height" fluid>
    <v-row>
      <v-snackbar
        class="mt-10"
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        right
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-icon color="white" text v-bind="attrs" @click="snackbar = false">
            mdi-close
          </v-icon>
        </template>
      </v-snackbar>
      <v-col cols="12">
        <v-row align="center" justify="center" class="box2">
          <v-col cols="12" class="loginBox">
            <v-form class="mx-auto">
              <v-row no-gutters>
                <v-col cols="10" class="mx-auto" style="margin-top: -19px;">
                  <v-text-field
                    label="Kullanıcı Adı"
                    type="text"
                    solo
                    prepend-inner-icon="mdi-account"
                    v-model="login.username"
                    color="#cc3399"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="mx-auto">
                  <v-text-field
                    label="Şifre"
                    type="password"
                    solo
                    prepend-inner-icon="mdi-lock"
                    v-model="login.password"
                    color="#cc3399"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    color="#cc3399"
                    large
                    dark
                    @click="userLogin"     
                    :loading="loadingUserLogin"
                  >
                    GİRİŞ YAP
                  </v-btn>
                </v-col>
                 <v-col cols="6">
                  <v-btn
                    color="#cc3399"
                    large
                    dark
                    v-on="on"
                    @click="newBayi"     
                  >
                    KAYIT OL
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <!--

            <v-col offset="2" cols="8">
            <a
              clickable
              style="color: #E3D1BB; text-decoration: none;"
              @click="uyelikBilgileriDuzenle"
            >
              Şifremi Unuttum
            </a>
          </v-col>
          -->
        </v-row>
      </v-col>
      <v-dialog v-model="bayiKayitDialog" max-width="600">
        <v-card>
          <v-card-title class="headline">
            Kullanıcı Bilgileri
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="bayiKayitDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="bayi.firmaAdi"
                  label="Firma Adı"
                  prepend-icon="mail"
                  color="sari"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="bayi.mail"
                  label="Mail Adresi"
                  prepend-icon="mail"
                  color="sari"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="bayi.webAdresi"
                  label="Web Adresi"
                  prepend-icon="gesture"
                  color="sari"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="bayi.telefon"
                  label="Telefon"
                  prepend-icon="phone"
                  color="sari"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="bayi.adres"
                  label="Adres"
                  prepend-icon="map"
                  color="sari"
                  rows="2"
                ></v-textarea>
              </v-col>
             <!--  <v-col cols="6">
                <v-text-field
                  v-model="bayi.tarih"
                  label="Başlangıç Tarihi"
                  prepend-icon="watch"
                  color="sari"
                ></v-text-field>
              </v-col>
              -->
              <v-col cols="12">
                 <v-select
                    item-text="name"
                    item-value="name"
                    :items="firma_kategorileri"
                    v-model="bayi.firma_kategori"
                    label="Firma Kategorisi"
                  ></v-select>
              </v-col>
               <v-col
                cols="12"
              >
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      slot="activator"
                      label="Tarih"
                      :value="bayi.tarih"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="bayi.tarih"
                    locale="turkish"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="bayi.username"
                  label="Kullanıcı Adı"
                  prepend-icon="mdi-account"
                  color="sari"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="bayi.password"
                  label="Şifre"
                  prepend-icon="mdi-lock"
                  color="sari"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="sari"
              dark
              @click="saveBayi"
              :loading="bayiKayitLoader"
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="uyelikDüzenleDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            Mail Kontrol
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="uyelikDüzenleDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="login.mail"
                  label="Geçerli mail adresi"
                  prepend-icon="mail"
                  color="#d2da24"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="#d2da24"
              @click="mailControl"
              :loading="loaderMail"
              dark
            >
              Gönder
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="uyeBilgileriDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            Üye Bilgileri
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="uyeBilgileriDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="uye_bilgi_duzenle.username"
                  label="Kullanıcı Adı"
                  prepend-icon="mdi-account"
                  color="#d2da24"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="uye_bilgi_duzenle.password"
                  label="Şifre"
                  prepend-icon="mdi-lock"
                  color="#d2da24"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="#d2da24"
              @click="uyeBilgiUpdate"
              :loading="loaderUyeBilgi"
              dark
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { db, fb } from '../../firebase/fb'
import { eventBus } from '../../main.js'

export default {
  data() {
    return {
     
      uyeBilgileriDialog: false,
      loaderUyeBilgi: false,
      uye_bilgi_duzenle: {
        username: '',
        password: '',
        docName: '',
      },
      uye_bilgi: [],
      uyelikDüzenleDialog: false,
      loaderMail: false,
      users: [],
      loadingUserLogin: false,
      login: {
        username: '',
        password: '',
        mail: '',
      },
      firma_kategorileri:[ 
        { name: 'Mobilya' },
        { name: 'Otel' },
        { name: 'Market' },
        { name: 'Restoran & Cafe' },
        { name: 'Call-Center' },
        { name: 'Teknoloji' },
        { name: 'Demo' },
        ],
      headers: [
        { text: "Firma Adı", value: "firmaAdi" },
        { text: "Firma Kategori", value: "firma_kategori" },
        { text: "Telefon", value: "telefon" },
        { text: "Web Adres", value: "webAdresi" },
        { text: "Mail Adresi", value: "mail" },
        { text: "Başlangıç Tarihi", value: "tarih" },
        
        { text: "", value: "action" },
      ],
      searching: "",
      bayiKayitDialog: false,
      bayiKayitLoader: false,
      bayi: {
        id: null,
        password: "",
        mail: "",
        username: "",
        docName: "",
        firmaAdi: "",
        adres: "",
        telefon: "",
        webAdresi: "",
        firma_kategori:'',
        tarih:this.formatDate(new Date().toISOString().substr(0, 10)),
      },
      snackbar: {
        status: false,
        text: '',
        color: '',
        timeout: 4000,
      },
      bayiler:[],
      kullanicilar:[],
      linkID:5
    }
  },
  mounted() {
     this.getBayi();
    //this.getLoginList()
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    updateBayi() {
      let vm = this;
      vm.bayiKayitLoader = true;
      db.collection("kullanici")
        .doc(vm.bayi.docName)
        .update({
            username: vm.bayi.username,
            password: vm.bayi.password,
            mail: vm.bayi.mail,
            firmaAdi: vm.bayi.firmaAdi,
            telefon : vm.bayi.telefon,
            webAdresi : vm.bayi.webAdresi,
            adres  :vm.bayi.adres,
            firma_kategori: vm.bayi.firma_kategori,
            tarih :vm.bayi.tarih,
        })
        .then(function () {
          console.log("Document successfully updated!");
          vm.bayiKayitLoader = false;
          vm.snackbar.status = true;
          vm.snackbar.color = "sari";
          vm.snackbar.text = "Kullanıcı Bilgileri Güncellendi";
          vm.bayiKayitDialog = !vm.bayiKayitDialog;
          vm.clearBayi();
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },
    editBayi(bayi) {
      console.log(bayi)
      this.bayi = bayi;
      this.bayi.id = bayi.docName;
      this.bayiKayitDialog = true;
    },
    deleteBayi(bayi) {
      let vm = this;
      vm.bayi.id = bayi.docName;
      if (confirm("Kullanıcı Bilgisini Silmek İstediğinize emin misiniz?")) {
        db.collection("kullanici")
          .doc(vm.bayi.id)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true;
            vm.snackbar.color = "red";
            vm.snackbar.text = "Bayi Bilgileri Silindi";
            vm.clearBayi();
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    },
    clearBayi() {
      this.bayi = {
        id: null,
        username: '',
        password: '',
        mail: '',
        firmaAdi: '',
        telefon: '',
        webAdresi: '',
        adres: '',
        firma_kategori:'',
        tarih:''
      };
    },
    getBayi() {
      db.collection("kullanici").onSnapshot((querySnapshot) => {
        this.bayiler = [];
        querySnapshot.forEach((doc) => {
          this.bayiler.push({ docName: doc.id, ...doc.data() });
        });
        // console.log(this.products)
      });
    },
    newBayi() {
    
      this.clearBayi();
      this.bayiKayitDialog = true;
    },
    saveBayi() {
       const current = new Date();
      this.bayi.tarih = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      console.log(this.bayi.tarih)
      if (this.bayi.id == null) {
        db.collection("kullanici")
          .add({
            username: this.bayi.username,
            password: this.bayi.password,
            mail: this.bayi.mail,
            firmaAdi: this.bayi.firmaAdi,
            telefon: this.bayi.telefon,
            webAdresi: this.bayi.webAdresi,
            adres: this.bayi.adres,
            firma_kategori: this.bayi.firma_kategori,
            tarih: this.bayi.tarih,
          })
          .then((docRef) => {
             db.collection("kullanici").doc(docRef.id).collection("adres").doc("adres").set({
            alan8 : this.bayi.telefon,
            webAdresi : this.bayi.webAdresi,
            alan1  :this.bayi.adres,
            brosurTitle:this.bayi.firmaAdi,
            aciklamaAlani:"Günlük Taze Sebze & Meyvede İndirimlerimiz Devam Ediyor",
            firmaAdi:this.bayi.firmaAdi
          })
          db.collection("kullanici").doc(docRef.id).collection("logo").doc("logo").set({
            fileName :"",
            resim : "",
           
          })
            console.log("Document written with ID");
            this.bayiKayitLoader = false;
            this.bayiKayitDialog = false;
            this.snackbar.status = true;
            this.snackbar.color = "green";
            this.snackbar.text = "Yeni Kullanıcı Eklendi";

          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
        //console.log(this.product)
      } else if (this.bayi.id) {
        this.updateBayi();
      }
    },
    newMember(){
      this.$router.push("");    
    }, 
    uyeBilgiUpdate() {
      let vm = this
      vm.loaderUyeBilgi = true
      db.collection('login')
        .doc(vm.uye_bilgi_duzenle.docName)
        .update({
          username: vm.uye_bilgi_duzenle.username,
          password: vm.uye_bilgi_duzenle.password,
        })
        .then(function () {
          console.log('Document successfully updated!')
          vm.loaderUyeBilgi = false
          vm.snackbar.status = true
          vm.snackbar.color = 'success'
          vm.snackbar.text = 'Üye Giriş Bilgileri Güncellendi'
          vm.uyeBilgileriDialog = false
          vm.clearUyeBilgi()
        })
        .catch(function (error) {
          console.error('Error updating document: ', error)
        })
    },
    clearUyeBilgi() {
      this.uye_bilgi_duzenle = {
        username: '',
        password: '',
        docName: '',
      }
    },
    mailControl() {
      this.loaderMail = true
      db.collection('login')
        .where('mail', '==', this.login.mail)
        .onSnapshot((querySnapshot) => {
          this.uye_bilgi = []
          querySnapshot.forEach((doc) => {
            this.uye_bilgi.push({ docName: doc.id, ...doc.data() })
            if (this.uye_bilgi.length == 0) {
              this.snackbar.status = true
              this.snackbar.color = 'red darken-1'
              this.uyelikDüzenleDialog = false
              this.snackbar.text =
                'Sistemde kayıtlı böyle bir mail adresi bulunmamaktadır'
            } else {
              this.uyelikDüzenleDialog = false
              this.uyeBilgileriDialog = true
              this.uye_bilgi_duzenle.username = this.uye_bilgi[0].username
              this.uye_bilgi_duzenle.password = this.uye_bilgi[0].password
              this.uye_bilgi_duzenle.docName = this.uye_bilgi[0].docName
              console.log(this.uye_bilgi_duzenle.docName)
            }
          })
          //console.log(this.products)
        })
      this.loaderMail = false
    },
    uyelikBilgileriDuzenle() {
      this.uyelikDüzenleDialog = true
    },

     userLogin() {
      
      if (this.login.username === 'nfssoft' && this.login.password === 'Furkan.x4322') {
        this.loadingUserLogin = true
        this.snackbar.status = true
        this.snackbar.color = 'sari'
        this.snackbar.text = 'Başarıyla Giriş Yaptınız'
        this.loadingUserLogin = false
        setTimeout(() => {
         // this.$router.push('/home/urunler')
          this.$router.push('/home/users')
        }, 1000)
      } else if (
        this.login.username != 'nfssoft' &&
        this.login.password != 'Furkan.x4322'
      ) {


       if (this.login.username != '' && this.login.password != '') {
        db.collection('kullanici')
          .where('username', '==', this.login.username)
          .where('password', '==', this.login.password)
          .onSnapshot((querySnapshot) => {
            this.kullanicilar = []
            querySnapshot.forEach((doc) => {
              this.kullanicilar.push({ docName: doc.id, ...doc.data() })
            })
            if (this.kullanicilar.length > 0) {
             // setTimeout(() => {
             //   eventBus.$emit('changedData', this.bayiler)
             // }, 500)
              this.snackbar.status = true
              this.snackbar.color = 'sari'
              this.snackbar.text = 'Online Broşür Sistemine Hoşgeldiniz'
              this.loadingUserLogin = false
              setTimeout(() => {
               // this.$router.push('/users')
                this.$router.push({path:`/users/${this.kullanicilar[0].docName}/sablonlar`})
                //this.$router.push('/')
              }, 1000)
            } else if (this.musteriler.length == 0) {
              this.snackbar.status = true
              this.snackbar.color = 'red darken-1'
              this.snackbar.text = 'Kullanıcı Adı veya Şifre Yanlış.'
              this.clearLogin()
            }
          })
      }
       else{
         this.loadingUserLogin = true
            this.snackbar.status = true
            this.snackbar.color = 'red'
            this.snackbar.text = 'Kullanıcı Adı veya Şifre Hatalı'
            this.loadingUserLogin = false
            this.clearLogin()
       }
        /* for (let key in this.users) {


          if (
            this.users[key].username == this.login.username &&
            this.users[key].password == this.login.password
          ) {
            this.loadingUserLogin = true
            this.snackbar.status = true
            this.snackbar.color = 'sari'
            this.snackbar.text = 'Başarıyla Giriş Yaptınız'
            this.loadingUserLogin = false
            EventBus.$emit('hesapBilgi', this.login)
            setTimeout(() => {
              this.$router.push('/dashboard/home')
            }, 1000)
          } else {
            this.loadingUserLogin = true
            this.snackbar.status = true
            this.snackbar.color = 'red'
            this.snackbar.text = 'Kullanıcı Adı veya Şifre Hatalı'
            this.loadingUserLogin = false
            setTimeout(() => {
              this.$router.push('/')
            }, 1000)
          }



        }*/
      }
    },


    clearLogin() {
      this.login = {
        username: '',
        password: '',
      }
    },
  },
}
</script>

<style scoped>
@media (min-width: 300px) and (max-width: 601px) {
  .container {
  height: 100%;
  margin: 0;
  padding: 0;
  background: url('');
  background-size: cover;
  font-family: sans-serif;
}
.box2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 280px;
  padding: 2.5rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto auto;
  border-radius: 5px;
  box-shadow: 0 0 10px #000;
}
.loginBox {
  width: 150px;
  height: 100%;
}

}
@media screen and (min-width: 601px) {
  .container {
  height: 100%;
  margin: 0;
  padding: 0;
  background: url('../../assets/img/login-bg-nfs.png');
  background-size: cover;
  font-family: sans-serif;
}

.box2 {
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 280px;
  padding: 2.5rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto auto;
  border-radius: 5px;
  box-shadow: 0 0 10px #000;
}
.loginBox {
  width: 375px;
  height: 100%;
}
.textBox {
  width: 375px;
  height: 100%;
  font-family: 'Microsoft Yi Baiti';
  color: white;
}
}

</style>
