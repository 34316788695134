<template>
  <v-row>
    <v-snackbar
      class="mt-10"
      v-model="snackbar.status"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      top
      right
    >
      {{ snackbar.text }}
      <v-btn dark text icon @click="snackbar.status = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-col
      cols="6"
      sm="6"
      md="4"
      lg="3"
      v-for="product in kategoriler"
      :key="product.id"
    >
      <v-card tile class="mb-2 ml-1" color="grey lighten-4">
        <v-img
          :aspect-ratio="16 / 9"
          :src="product.resim"
          height="250px"
        ></v-img>
        <v-row no-gutters>
          <v-col cols="8">
            <v-card-subtitle>
              {{ product.urunadi }}
            </v-card-subtitle>
          </v-col>
          <v-col cols="4" class="mt-3">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="teal darken-1"
                  dark
                  v-on="on"
                  @click="editKategori(product)"
                >
                  edit
                </v-icon>
              </template>
              <span>Düzenle</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="red darken-1"
                  dark
                  v-on="on"
                  @click="deleteKategori(product)"
                >
                  delete
                </v-icon>
              </template>
              <span>Sil</span>
            </v-tooltip>
             <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="orange darken-1"
                  dark
                  v-on="on"
                  @click="urunleriGoster(product)"
                >
                  remove_red_eye
                </v-icon>
              </template>
              <span>Ürünleri Görüntüle</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  <!--Ürünler Dialog -->
    <v-dialog
      v-model="allUrunlerDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >


          Ürün Düzenle
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn text class="float-right" color="white" dark @click="brosur">BROŞÜR SAYFASINA GİT</v-btn>
          <v-btn icon color="grey" text @click="allUrunlerDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
       <v-row>
          <v-col
        cols="6"
        md="3"
        v-for="product in products"
        :key="product.id"
    >
      <v-card tile class="mb-2 ml-1" color="grey lighten-4">
        <v-img
          :aspect-ratio="16 / 9"
          :src="product.resim"
          height="250px"
        >
        </v-img>
        <v-row no-gutters>
          <v-col cols="9">
            <v-card-subtitle>
              {{ product.urunadi }}
            </v-card-subtitle>
          </v-col>
          <v-col cols="3" class="mt-3">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="teal darken-1"
                  dark
                  v-on="on"
                  @click="editProduct(product)"
                >
                  edit
                </v-icon>
              </template>
              <span>Düzenle</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="red darken-1"
                  dark
                  v-on="on"
                  @click="deleteProduct(product)"
                >
                  delete
                </v-icon>
              </template>
              <span>Sil</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
       </v-row>
    <!-- Ürünler Dialog-->
  <v-dialog v-model="urunDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="urunDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    label="Resim"
                    color="sari"
                    @change="imageUrunUpload"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="product.urunadi"
                    label="Ürün Adı"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="product.urunaciklama"
                    label="Açıklama"
                    prepend-icon="gesture"
                    color="sari"
                    rows="1"
                  ></v-textarea>
                </v-col>
                 <v-col cols="12">
                  <v-text-field
                    v-model="product.eski_fiyat"
                    label="Eski Fiyat"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="product.fiyat"
                    label="Yeni Fiyat"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    item-text="name"
                    item-value="sembol"
                    :items="paraBirimleri"
                    v-model="product.paraBirimi"
                    @change="changeParaBirimi()"
                    prepend-icon="euro"
                    label="Para Birimi"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            @click="saveProduct"
            :loading="loader"
            dark
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   <!--Ürünler Edit Dialog-->
    <v-dialog v-model="editUrunDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="editUrunDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <span class="ml-5">
                    <v-label>Resim</v-label>
                  </span>
                  <v-img
                    :src="product.resim"
                    aspect-ratio="1"
                    class="grey lighten-2 align-end ml-5"
                    max-width="300"
                    max-height="150"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          :loading="loadingUrunImage"
                          color="pink darken-1"
                          small
                          class="float-right"
                        >
                          <v-label>
                            <input type="file" hidden @change="updateUrunImage" />
                            <v-icon>mdi-plus</v-icon>
                          </v-label>
                        </v-btn>
                      </template>
                      <span>Yeni Resim</span>
                    </v-tooltip>
                  </v-img>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="product.urunadi"
                    label="Ürün Adı"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="product.urunaciklama"
                    label="Açıklama"
                    prepend-icon="gesture"
                    color="sari"
                    rows="1"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="product.eski_fiyat"
                    label="Eski Fiyat"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="product.fiyat"
                    label="Yeni Fiyat"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    item-text="name"
                    item-value="sembol"
                    :items="paraBirimleri"
                    v-model="product.paraBirimi"
                    @change="changeParaBirimi()"
                    prepend-icon="euro"
                    label="Para Birimi"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            @click="updateProduct"
            :loading="updateLoader"
            dark
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-btn
          class="mb-10"
          bottom
          color="blue darken-2"
          dark
          outlined
          fixed
          right
          v-on="on"
          @click="newProduct"
        >
          YENİ ÜRÜN
        </v-btn>
      </v-card>
    </v-dialog>
  <!--Kategori Dialog -->
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    label="Resim"
                    color="sari"
                    @change="imageUpload"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="kategori.urunadi"
                    label="Kategori Adı"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="kategori.urunaciklama"
                    label="Açıklama"
                    prepend-icon="gesture"
                    color="sari"
                    rows="1"
                  ></v-textarea>
                </v-col>


              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            @click="saveKategori"
            :loading="loaderKategori"
            dark
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  <!---Kategori Dialog -->
    <v-dialog v-model="editDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="editDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <span class="ml-5">
                    <v-label>Resim</v-label>
                  </span>
                  <v-img
                    :src="kategori.resim"
                    aspect-ratio="1"
                    class="grey lighten-2 align-end ml-5"
                    max-width="300"
                    max-height="150"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          :loading="loading"
                          color="pink darken-1"
                          small
                          class="float-right"
                        >
                          <v-label>
                            <input type="file" hidden @change="updateImage" />
                            <v-icon>mdi-plus</v-icon>
                          </v-label>
                        </v-btn>
                      </template>
                      <span>Yeni Resim</span>
                    </v-tooltip>
                  </v-img>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="kategori.urunadi"
                    label="Kategori Adı"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="kategori.urunaciklama"
                    label="Açıklama"
                    prepend-icon="gesture"
                    color="sari"
                    rows="1"
                  ></v-textarea>
                </v-col>


              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            @click="updateKategori"
            :loading="updateLoaderKategori"
            dark
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  <!--Broşür Dialog--->
<v-dialog v-model="brosurDialog" max-width="700">
        <v-card>
          <v-card-title class="headline">
            Broşür
            <v-spacer></v-spacer>
            <div id="editor"></div>
            <v-btn
              id="cmd"
              outlined
              color="success"
              class="ma-2 white--text"
              @click="makePDF"
              :loading="loaderBrosur"
            >
              İNDİR
              <v-icon right dark>
                mdi-cloud-upload
              </v-icon>
            </v-btn>
            <v-btn icon color="grey" text @click="brosurDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
           <div id="capture" ref="capture">
          <v-card-text
            id="test_1"
          >
            <v-container fluid>
              <v-row dense>
                <v-col>
                  <v-card tile class="mt-4">
                    <v-img
                      class="white--text brosurBackground"
                      src="../../assets/img/zemin2.jpg"
                    >
                      <v-container fluid>
                        <v-row dense>
                          <v-col
                            style="margin-top: 25px; margin-bottom: 2px;"
                            cols="12"
                          >
                            <a>
                              <h2
                                class="text-center font-weight-black brosurTitle"
                                style="color: #e3dd2f; font-weight: bolder;"
                                @click="editTitle"
                                v-text="brosurTitle"
                              ></h2>
                            </a>
                          </v-col>
                          <v-col
                            v-for="product in selectedProducts"
                            :key="product.id"
                            cols="6"
                          >
                            <v-card tile>
                              <v-hover v-slot:default="{ hover }">
                                <v-img
                                  :src="product.resim"
                                  class="selectedProductImage"
                                  height="250"
                                >
                                  <v-row dense>
                                    <v-col cols="12">
                                      <v-layout>
                                        <span>
                                          <h3
                                            style="font-weight: bolder;"
                                            class="font-weight-black white--text ml-1 selectedProductUrunadi"
                                          >
                                            {{ product.urunadi }}
                                          </h3>
                                        </span>
                                      </v-layout>
                                      <!--
                                        <v-layout>
                                        <span>
                                          <h4
                                            class="font-weight-black ml-1 selectedProductUrunAciklama"
                                          >
                                            {{ product.urunaciklama }}
                                          </h4>
                                        </span>
                                      </v-layout>
                                       -->
                                    </v-col>
                                    <v-col cols="6">
                                      <v-layout>
                                        <div width="100px" height="100px">
                                          <v-img
                                            class="selectedProductFiyatAvatar"
                                            src="../../assets/img/bomba_fiyat.png"
                                          >
                                            <span>
                                              <h3
                                                class="font-weight-black selectedProductFiyatText"
                                              >
                                                {{ product.fiyat }}
                                                {{ product.paraBirimi }}
                                              </h3>
                                            </span>
                                          </v-img>
                                        </div>
                                      </v-layout>
                                    </v-col>
                                  </v-row>
                                  <v-expand-transition>
                                    <div
                                      v-if="hover"
                                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-3 white--text"
                                      style="height: 100%;"
                                    >
                                      <v-btn
                                        class="ma-2"
                                        tile
                                        color="black"
                                        dark
                                        @click="deleteUrun(product)"
                                      >
                                        Çıkar
                                      </v-btn>
                                    </div>
                                  </v-expand-transition>
                                </v-img>
                              </v-hover>
                            </v-card>
                          </v-col>
                          <v-col
                            class="d-flex child-flex mb-9"
                            cols="12"
                            @click="editAdress"
                          >
                            <v-row no-gutters>
                              <v-col cols="4">
                                <div class="bosluk">
                                  <h4
                                    class="white--text font-weight-bold adresAlanMedium"
                                  >
                                    {{ adres.alan1 }}
                                  </h4>
                                </div>
                                <div class="bosluk">
                                  <span
                                    class="white--text font-weight-light adresAlanMedium"
                                  >
                                    {{ adres.alan2 }}
                                  </span>
                                </div>
                                <div class="bosluk">
                                  <span
                                    class="white--text font-weight-light adresAlanSmall"
                                  >
                                    {{ adres.alan21 }}
                                  </span>
                                </div>
                                <div class="bosluk">
                                  <span
                                    class="white--text font-weight-light adresAlanSmall"
                                  >
                                    {{ adres.alan3 }}
                                  </span>
                                </div>
                                <div bo>
                                  <span
                                    class="white--text font-weight-light adresAlanSmall"
                                  >
                                    {{ adres.alan4 }}
                                  </span>
                                </div>
                              </v-col>
                              <v-col cols="4">
                                <h4
                                  class="white--text text-center font-weight-bold adresAlanMedium bosluk"
                                >
                                  {{ adres.alan5 }}
                                </h4>
                                <h4
                                  class="white--text text-center font-weight-bold adresAlanMedium bosluk"
                                >
                                  {{ adres.alan6 }}
                                </h4>
                                <h4
                                  class="white--text text-center font-weight-bold adresAlanMedium bosluk"
                                >
                                  {{ adres.alan61 }}
                                </h4>
                                <h4
                                  class="white--text text-center font-weight-bold adresAlanMedium"
                                >
                                  {{ adres.alan7 }}
                                </h4>
                                <h4 class="white--text"></h4>
                              </v-col>
                              <v-col cols="4">
                                <h4
                                  class="white--text font-weight-bold text-center adresAlanMedium bosluk8"
                                >
                                  {{ adres.alan8 }}
                                </h4>
                                <div
                                  class="yesil font-weight-bold mt-2 adresAlanMedium ml-8"
                                  v-if="adres.alan9"
                                >
                                  <h3
                                    style="color: #1b0d0c;"
                                    class="ml-2 adresAlanBold"
                                  >
                                    {{ adres.alan9 }}
                                  </h3>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>


        </div>
        </v-card>

      </v-dialog>

  <!--Buton-->
   <v-btn
          class="mb-10"
          bottom
          color="blue darken-4"
          dark
          outlined
          fixed
          right
          v-on="on"
          @click="newKategori"
        >
          YENİ KATEGORİ
        </v-btn>

  </v-row>
</template>
<script>
import { db, fb } from '../../firebase/fb'
import { jsPDF } from 'jspdf'
import domtoimage from 'dom-to-image'
import html2canvas from 'html2canvas'
export default {
  data() {
    return {
      loadingUrunImage:false,
      editTitle:'',
      brosurTitle:'Broşür Başlığı',
      loaderBrosur:false,
      brosurDialog:false,
      selection:2,
      updateUrunLoader:false,
      editUrunDialog:false,
      urunDialog:false,
       title: '',
      dialog: false,
      loader: false,
      updateLoaderKategori: false,
      product: {
        id: null,
        urunadi: '',
        urunaciklama: '',
        resim: '',
        docName: '',
        eski_fiyat:'',
        fiyat: '',
        active: false,
        activeDurum: 'Pasif',
        fileName: '',
        paraBirimi: '',
        kategori:'',
      },
      products:[],
      allUrunlerDialog:false,
      editDialog: false,
      disabled: true,
      kategoriler: [],
      snackbar: {
        status: false,
        text: '',
        color: '',
        timeout: 4000,
      },
      title: '',
      dialog: false,
      loader: false,
      loaderKategori: false,
      updateLoader: false,
      kategori: {
        id: null,
        urunadi: '',
        urunaciklama: '',
        resim: '',
        docName: '',
        fileName: '',
      },
      loading: false,
      paraBirimleri: [
        { name: 'CHF', sembol: 'CHF' },
        { name: 'DDK', sembol: 'DDK' },
        { name: 'NOK', sembol: 'NOK' },
        { name: 'SEK', sembol: 'SEK ' },
        { name: '£', sembol: '£ ' },
        { name: '₺', sembol: '₺' },
      ],
      selectedProducts:[],
      output: null,
      adres: {
        alan1: 'VUSLAT DERGAHI',
        alan2: 'Verein für Familie',
        alan21: 'Jugend und Ethik e.V.',
        alan3: 'Robert Bunsen Str. 75',
        alan4: 'Rüsselsheim am Main',
        alan5: 'SİPARİŞLER',
        alan6: 'CUMA GÜNÜ',
        alan61: 'SAAT 21:00',
        alan7: 'KADAR VERİLMELİDİR',
        alan8: 'SİPARİŞ İÇİN',
        alan9: '0172/7879492'
      },
    }
  },
  mounted() {
    this.getKategori()
  },
  methods: {
    brosur(){
      setTimeout(() => {
          this.$router.push('/home/urunler')
        }, 600)
    },
    newBrosur () {
      this.brosurDialog = true
      if (this.selectedProducts.length > 2) {
        this.brosurDialogBoyut = 1200
      }
    },
    editAdress(){

    },
    deleteUrun(product) {
      this.selectedProducts.splice(product, 1)
    },
     async print () {
      const el = this.$refs.capture
      const options = {
        type: 'dataURL'
      }
      this.output = await this.$html2canvas(el, options)
      var link = document.createElement('a')
      link.download = 'borusur.png'
      link.href = this.output
      document.body.appendChild(link)
      link.click()
    },
    makePDF(){
      this.loaderBrosur = true;
      const self = this
      var canvas = this.$refs.capture
      html2canvas(canvas, { type: 'dataURL' }).then(function (canvas) {
        self.print()

      })
      setTimeout(() => {
          this.loaderBrosur = false;
        }, 1000)
    },
    selectedProduct (product) {
      console.log(product)
      // eslint-disable-next-line eqeqeq
      if (product.active == true) {
        this.selectedProducts.push(product)
        this.snackbar.status = true
        this.snackbar.color = 'sari'
        this.snackbar.text = 'Seçilen Ürün Broşüre Eklendi'
        // eslint-disable-next-line eqeqeq
      } else if (product.active == false) {
        this.selectedProducts.splice(this.selectedProducts.indexOf(product), 1)
      }
    },
     saveProduct() {
      this.loader = true
      this.urunAdiEkle()
      //console.log(this.product.kategori)
      //console.log(this.product.docName)
      db.collection('kategoriler')
          .doc(this.product.kategori)
          .collection('urunler')
          .doc(this.product.docName)
          .set({
            id: this.product.id,
            fiyat: this.product.fiyat,
            eski_fiyat:this.product.eski_fiyat,
            urunadi: this.product.urunadi,
            urunaciklama: this.product.urunaciklama,
            resim: this.product.resim,
            active: true,
            activeDurum: 'Aktif',
            fileName: this.product.fileName,
            paraBirimi: this.product.paraBirimi,
            kategori:this.product.kategori
          })
          .then((docRef) => {
            console.log('Document written with ID', docRef)
            this.loader = false
            this.urunDialog = false
            this.snackbar.status = true
            this.snackbar.color = 'green'
            this.snackbar.text = 'Yeni Ürün Eklendi'
            //this.urunleriGoster(this.product)
          })
          .catch((error) => {
            console.error('Error adding document: ', error)
          })
    },
    newProduct() {
      this.clearProduct()
      this.title = 'Yeni Ürün'
      this.urunDialog = !this.urunDialog
    },
    editProduct(product) {
      this.title = 'Ürün Güncelleme'
      this.product = product
      this.editUrunDialog = !this.editUrunDialog
      // console.log(this.product)
    },
    updateProduct() {
      let vm = this
      vm.updateLoader = true
      db.collection('kategoriler')
      .doc(vm.product.kategori)
      .collection('urunler')
        .doc(vm.product.docName)
        .update({
          urunadi: vm.product.urunadi,
          urunaciklama: vm.product.urunaciklama,
          fiyat: vm.product.fiyat,
          eski_fiyat: vm.product.eski_fiyat,
          fileName: vm.product.fileName,
          paraBirimi: vm.product.paraBirimi,
          kategori:vm.product.kategori,
          resim:vm.product.resim
        })
        .then(function () {
          console.log('Document successfully updated!')
          vm.updateLoader = false
          vm.snackbar.status = true
          vm.snackbar.color = 'sari'
          vm.snackbar.text = 'Ürün Bilgileri Güncellendi'
          vm.editUrunDialog = !vm.editUrunDialog
        })
        .catch(function (error) {
          console.error('Error updating document: ', error)
        })
    },
    clearProduct() {
      this.product = {
        id: null,
        urunadi: '',
        urunaciklama: '',
        resim: '',
        docName: '',
        fiyat: '',
        active: false,
        activeDurum: 'Pasif',
        fileName: '',
        paraBirimi: '',
      }
    },
    deleteProduct(product) {
      let vm = this
      vm.product.docName = product.docName
      if (confirm('Ürünü Silmek İstediğinize emin misiniz?')) {
        db.collection('kategoriler')
          .doc(vm.product.kategori)
          .collection('urunler')
          .doc(vm.product.docName)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true
            vm.snackbar.color = 'red'
            vm.snackbar.text = 'Ürün Silindi'
            //vm.clearProduct()
          })
          .catch(function (error) {
            console.error('Error removing document: ', error)
          })
      }
    },
    tumUrunleriGoster(){
      this.allUrunlerDialog = true
      db.collection('kategoriler')
        .onSnapshot((querySnapshot) => {
          //this.kategoriler = []
          this.products=[]
          querySnapshot.forEach((doc) => {
            db.collection('kategoriler')
              .doc(doc.id)
              .collection('urunler')
              .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((urun) => {
                  fetch(urun.data().resim).then(r => {
              return r.blob()
            }).then(blobFile => {
             // fileName = window.URL.createObjectURL(new File([blobFile], { type: 'image/jpeg' }))
              // this.blbUrl = fileName
              this.products.push({ docName: urun.id, ...urun.data() })
            })
          })
           //console.log("ürünler")
        })
          })
         //  console.log(this.kategoriler)
        })
    },
    urunleriGoster(product){
      console.log(product)
      this.product.kategori = product.docName
      this.allUrunlerDialog = true
      db.collection('kategoriler')
        .doc(product.docName)
        .collection('urunler')
        .onSnapshot((querySnapshot) => {
          this.products = []
          querySnapshot.forEach((doc) => {
            fetch(doc.data().resim).then(r => {
              return r.blob()
            }).then(blobFile => {
              //fileName = window.URL.createObjectURL(new File([blobFile], { type: 'image/jpeg' }))
              // this.blbUrl = fileName
              this.products.push({ docName: doc.id, ...doc.data() })
            })

          })
          // console.log(this.products)
        })
    },
    updateUrunImage(e) {
      let file = e.target.files[0]
      if (file) {
        this.loadingUrunImage = true
        this.product.fileName = file.name
        var storageRef = fb.storage().ref('images/' + file.name)
        let uploadTask = storageRef.put(file)
        uploadTask.on(
          'state_changed',
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.resim = downloadURL
              let vm = this
              db.collection('kategoriler')
              .doc(vm.kategori.docName)
              .collection('urunler')
                .doc(vm.product.docName)
                .update({
                  resim: vm.product.resim,
                  fileName: vm.product.fileName,
                })
                .then(function () {
                  console.log('Document successfully updated!')
                  vm.loadingUrunImage = false
                })
                .catch(function (error) {
                  console.error('Error updating document: ', error)
                })
            })
          },
        )
      }
    },
    imageUrunUpload(e) {
      let file = e
      this.product.fileName = file.name
      var storageRef = fb.storage().ref('images/' + file.name)
      let uploadTask = storageRef.put(file)
      uploadTask.on(
        'state_changed',
        (snapshot) => {},
        function (error) {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.product.resim = downloadURL
            console.log('File available at', downloadURL)
            this.disabled = false
          })
        },
      )
    },
    deleteKategori(product) {
      console.log(product)
      let vm = this
      vm.kategori.docName = product.docName
      if (confirm('Kategoriyi Silmek İstediğinize emin misiniz?')) {
        db.collection('kategoriler')
          .doc(vm.kategori.docName)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true
            vm.snackbar.color = 'red'
            vm.snackbar.text = 'Kategori Silindi'
            //vm.clearProduct()
          })
          .catch(function (error) {
            console.error('Error removing document: ', error)
          })

      }
    },
    updateImage(e) {
      let file = e.target.files[0]
      if (file) {
        this.loading = true
        this.kategori.fileName = file.name
        var storageRef = fb.storage().ref('images/' + file.name)
        let uploadTask = storageRef.put(file)
        uploadTask.on(
          'state_changed',
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.kategori.resim = downloadURL
              let vm = this
              db.collection('kategoriler')
                .doc(vm.kategori.docName)
                .update({
                  resim: vm.kategori.resim,
                  fileName: vm.kategori.fileName,
                })
                .then(function () {
                  console.log('Document successfully updated!')
                  vm.loading = false
                })
                .catch(function (error) {
                  console.error('Error updating document: ', error)
                })
            })
          },
        )
      }
    },
    editKategori(kategori) {
      this.title = 'Kategori Güncelleme'
      this.kategori = kategori
      this.editDialog = !this.editDialog
       console.log(this.kategori)
    },
    getKategori() {
      db.collection('kategoriler')
        .orderBy('id', 'desc')
        .onSnapshot((querySnapshot) => {
          this.kategoriler = []
          querySnapshot.forEach((doc) => {
            this.kategoriler.push({ docName: doc.id, ...doc.data() })
          })
          // console.log(this.products)
        })
    },
    updateKategori() {
      let vm = this
      vm.updateLoaderKategori = true
      db.collection('kategoriler')
        .doc(vm.kategori.docName)
        .update({
          urunadi: vm.kategori.urunadi,
          urunaciklama: vm.kategori.urunaciklama
        })
        .then(function () {
          console.log('Document successfully updated!')
          vm.updateLoaderKategori = false
          vm.snackbar.status = true
          vm.snackbar.color = 'sari'
          vm.snackbar.text = 'Kategori Bilgileri Güncellendi'
          vm.editDialog = !vm.editDialog
        })
        .catch(function (error) {
          console.error('Error updating document: ', error)
        })

    },

    saveKategori() {
      this.loaderKategori = true
      //this.product.fiyat = parseFloat(this.product.fiyat)
      if (this.kategori.id == null) {
        if (this.kategoriler.length > 0) {
          let id = this.kategoriler[0].id
          this.kategori.id = parseInt(id) + 1
        } else {
          this.kategori.id = 1
        }
        this.boslukSil()
        db.collection('kategoriler')
          .doc(this.kategori.docName)
          .set({
            id: this.kategori.id,
            urunadi: this.kategori.urunadi,
            urunaciklama: this.kategori.urunaciklama,
            resim: this.kategori.resim,
            fileName: this.kategori.fileName,

          })
          .then((docRef) => {
            console.log('Document written with ID', docRef)
            this.loaderKategori = false
            this.dialog = false
            this.snackbar.status = true
            this.snackbar.color = 'green'
            this.snackbar.text = 'Yeni Kategori Eklendi'
          })
          .catch((error) => {
            console.error('Error adding document: ', error)
          })

        //console.log(this.product)
      }
    },
    clearProduct() {
      this.kategori = {
        id: null,
        urunadi: '',
        urunaciklama: '',
        resim: '',
        fileName: '',

      }
    },
    imageUpload(e) {
      let file = e
      this.kategori.fileName = file.name
      var storageRef = fb.storage().ref('images/' + file.name)
      let uploadTask = storageRef.put(file)
      uploadTask.on(
        'state_changed',
        (snapshot) => {},
        function (error) {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.kategori.resim = downloadURL
            console.log('File available at', downloadURL)
            this.disabled = false
          })
        },
      )
    },
    newKategori() {
      this.clearProduct()
      this.title = 'Yeni Kategori'
      this.dialog = !this.dialog
    },
    boslukSil() {
      var charMap = {
        Ç: 'C',
        Ö: 'O',
        Ş: 'S',
        İ: 'I',
        I: 'i',
        Ü: 'U',
        Ğ: 'G',
        ç: 'c',
        ö: 'o',
        ş: 's',
        ı: 'i',
        ü: 'u',
        ğ: 'g',
      }
      var str = this.kategori.urunadi
      var str_array = str.split('')
      for (var i = 0, len = str_array.length; i < len; i++) {
        str_array[i] = charMap[str_array[i]] || str_array[i]
      }
      str = str_array.join('')

      var clearStr = str
        .replace(' ', '')
        .replace('-', '')
        .replace(/[^a-z0-9-.çöşüğı]/gi, '')
      this.kategori.docName = clearStr
      //var res = new Array();
      //res = this.kelime.split(" ");
      //var sonuc = res.join("");
      //console.log(sonuc);
    },
    urunAdiEkle() {
      var charMap = {
        Ç: 'C',
        Ö: 'O',
        Ş: 'S',
        İ: 'I',
        I: 'i',
        Ü: 'U',
        Ğ: 'G',
        ç: 'c',
        ö: 'o',
        ş: 's',
        ı: 'i',
        ü: 'u',
        ğ: 'g',
      }
      var str = this.product.urunadi
      var str_array = str.split('')
      for (var i = 0, len = str_array.length; i < len; i++) {
        str_array[i] = charMap[str_array[i]] || str_array[i]
      }
      str = str_array.join('')

      var clearStr = str
        .replace(' ', '')
        .replace('-', '')
        .replace(/[^a-z0-9-.çöşüğı]/gi, '')
      this.product.docName = clearStr
      //var res = new Array();
      //res = this.kelime.split(" ");
      //var sonuc = res.join("");
      //console.log(sonuc);
    },
  },
  watch: {
    editDialog(val) {
      !val && this.clearProduct()
    },
  },
}
</script>
<style scoped>
.container-bg {
  background-color: #1b0d0c;
}
.kahverengi {
  height: 25px;
  width: 5px;
  background-color: #000000;
  float: left;
}
.beyaz {
  height: 25px;
  width: 5px;
  background-color: #ffffff;
  float: left;
}

.content {
  width: 595px;
  height: 842px;
  background: url('../../assets/img/zemin.jpg');
}
.content2 {
  width: 595px;
  height: 948px;
  background: url('../../assets/img/zemin2.jpg');
}
.content3 {
  width: 595px;
  height: 1200px;
  background: url('../../assets/img/zemin3.jpg');
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}
.musterilink {
  padding-left: 0px;
}
@media screen and (min-width: 601px) {
  .alGoturToGo {
    margin-left: 20px;
    margin-top: 70px;
  }
  .urunListBosluk {
    margin-top: 50px;
  }
  .urunListFiyat {
    margin-right: 10px;
  }
  .yesil {
    background-color: #e3dd2f;
    height: 25px;
    width: 150px;
    float: left;
  }
  .selectedProductFiyatAvatar {
    margin-top: 100px;
    margin-left: -10px;
  }
  .selectedProductFiyatText {
    margin-top: 42px;
    margin-left: 25px;
  }
  .brosurTitle {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .editAdres {
    margin-bottom: 15px;
  }
  .ambalaj {
    font-size: small;
    font-weight: bolder;
    margin-left: 5px;
  }
  .productImage {
    height: 250px;
  }
  .productImageRow {
    height: 30px;
  }
}
@media (min-width: 300px) and (max-width: 601px) {
  .selectedProductImage {
    height: 150px;
  }
  .selectedProductUrunadi {
    font-size: x-small;
    margin-bottom: 6px;
  }
  .selectedProductUrunAciklama {
    font-size: 8px;
    margin-top: -16px;
  }
  .selectedProductFiyatAvatar {
    width: 65px;
    height: 65px;
    margin-top: 42px;
    margin-left: -7px;
  }
  .selectedProductFiyatText {
    font-size: 11px;
    font-weight: bolder;
    margin-top: 25px;
    margin-left: 11px;
  }
  .brosurTitle {
    margin-top: 5px;
    font-size: 12px;
    font-weight: bolder;
  }
  .selectedAdresTextMedium {
    font-size: x-small;
    font-weight: bolder;
  }
  .selectedAdresTextSmall {
    font-size: xx-small;
    margin-bottom: 0px;
  }
  .yesil {
    background-color: #e3dd2f;
    height: 20px;
    width: 70px;
    float: right;
  }
  .bosluk {
    margin-top: 0px;
    margin-bottom: -13px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .bosluk8 {
    margin-top: 0px;
    margin-bottom: -10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .urunListFiyat {
    margin-right: 3px;
  }
  .alGoturToGo {
    font-size: 9px;
    font-weight: bolder;
  }
  .urunListBosluk {
    margin-top: 20px;
  }
  .editAdres {
    margin-bottom: 3px;
  }
  .alGoturCard {
    height: 150px;
    width: 250;
  }
  .ambalaj {
    font-size: xx-small;
    font-weight: bolder;
    margin-top: -15px;
    margin-left: 1px;
  }
  .alGoturToGoText {
    font-size: xx-small;
    font-weight: bolder;
  }
  .alGoturToGoNokta {
    font-size: 5px;
    font-weight: bolder;
  }
  .adresAlanMedium {
    font-size: 7px;
    font-weight: bolder;
  }
  .adresAlanSmall {
    font-size: 7px;
    font-weight: medium;
  }
  .adresAlanBold {
    font-size: 10px;
    font-weight: bolder;
  }
  .brosurTitle8 {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: bolder;
  }
  .brosurBtn {
    font-size: small;
    margin-right: 45px;
  }
  .productImage {
    height: 150px;
  }
  .productImageRow {
    height: 15px;
  }
  .productUrunadi {
    font-size: small;
  }
  .productFiyat {
    font-size: large;
  }
  .productActive {
    font-size: xx-small;
  }
  .productSwitch {
    font-size: xx-small;
  }
  .opacity-0 {
    opacity: 0 !important;
  }
}
</style>
