<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row>
          <!-------------MOBİLYA------------------->
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon36></mobilyaSablon36>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon54></mobilyaSablon54>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon50></mobilyaSablon50>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon49></mobilyaSablon49>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon52></mobilyaSablon52>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon53></mobilyaSablon53>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon3></mobilyaSablon3>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon5></mobilyaSablon5>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon7></mobilyaSablon7>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon6></mobilyaSablon6>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon30></mobilyaSablon30>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon51></mobilyaSablon51>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon9></mobilyaSablon9>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon29></mobilyaSablon29>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon8></mobilyaSablon8>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon35></mobilyaSablon35>
          </v-col>         
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon37></mobilyaSablon37>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon38></mobilyaSablon38>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon39></mobilyaSablon39>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon4></mobilyaSablon4>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon10></mobilyaSablon10>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon11></mobilyaSablon11>
          </v-col>
           <v-col cols="3" v-if="mobilya">
            <mobilyaSablon43></mobilyaSablon43>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon2></mobilyaSablon2>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon13></mobilyaSablon13>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon14></mobilyaSablon14>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon21></mobilyaSablon21>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon15></mobilyaSablon15>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon16></mobilyaSablon16>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon17></mobilyaSablon17>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon18></mobilyaSablon18>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon19></mobilyaSablon19>
          </v-col>
          <v-col cols="3" v-if="mobilya">
            <mobilyaSablon20></mobilyaSablon20>
          </v-col>
          
          
          
       <!-------------MARKET------------------->
          <v-col cols="3" v-if="market">
            <marketSablon4></marketSablon4>
          </v-col>
          <v-col cols="3" v-if="market">
            <marketSablon5></marketSablon5>
          </v-col>
          <v-col cols="3" v-if="market">
            <marketSablon6></marketSablon6>
          </v-col>
          <v-col cols="3" v-if="market">
            <marketSablon7></marketSablon7>
          </v-col>
          <v-col cols="3" v-if="market">
            <marketSablon8></marketSablon8>
          </v-col>
          <v-col cols="3" v-if="market">
            <marketSablon9></marketSablon9>
          </v-col>
          <v-col cols="3" v-if="market">
            <marketSablon10></marketSablon10>
          </v-col>
          <v-col cols="3" v-if="market">
            <marketSablon11></marketSablon11>
          </v-col>
          <v-col cols="3" v-if="market">
            <marketSablon12></marketSablon12>
          </v-col>
          <!-------------OTEL------------------->
          <v-col cols="3" v-if="otel">
           <otelSablon3></otelSablon3>
         </v-col>
         <v-col cols="3" v-if="otel">
           <otelSablon9></otelSablon9>
         </v-col>
          <v-col cols="3" v-if="otel">
           <otelSablon8></otelSablon8>
         </v-col>
         <v-col cols="3" v-if="otel">
           <otelSablon11></otelSablon11>
         </v-col>
         <v-col cols="3" v-if="otel">
           <otelSablon6></otelSablon6>
         </v-col>
         <v-col cols="3" v-if="otel"> 
           <otelSablon10></otelSablon10>
         </v-col>
         <v-col cols="3" v-if="otel">
           <otelSablon29></otelSablon29>
         </v-col>
         <v-col cols="3" v-if="otel">
           <otelSablon30></otelSablon30>
         </v-col>
         <v-col cols="3" v-if="otel">
           <otelSablon14></otelSablon14>
         </v-col>
         <v-col cols="3" v-if="otel">
           <otelSablon17></otelSablon17>
         </v-col>
         <v-col cols="3" v-if="otel">
           <otelSablon18></otelSablon18>
         </v-col>
         <v-col cols="3" v-if="otel">
           <otelSablon19></otelSablon19>
         </v-col>
         <!-------------RESTORAN------------------->
         <v-col cols="3" v-if="restoran">
           <restoranSablon43></restoranSablon43>
         </v-col>
         <v-col cols="3" v-if="restoran">
           <restoranSablon50></restoranSablon50>
         </v-col>
         <v-col cols="3" v-if="restoran">
           <restoranSablon49></restoranSablon49>
         </v-col>
         <v-col cols="3" v-if="restoran">
           <restoranSablon46></restoranSablon46>
         </v-col>
         <v-col cols="3" v-if="restoran">
           <restoranSablon52></restoranSablon52>
         </v-col>
         <v-col cols="3" v-if="restoran">
           <restoranSablon47></restoranSablon47>
         </v-col>
         <v-col cols="3" v-if="restoran">
           <restoranSablon54></restoranSablon54>
         </v-col>
         <v-col cols="3" v-if="restoran">
           <restoranSablon44></restoranSablon44>
         </v-col>
         <v-col cols="3" v-if="restoran">
           <restoranSablon53></restoranSablon53>
         </v-col>
         <v-col cols="3" v-if="restoran">
           <restoranSablon51></restoranSablon51>
         </v-col>
         <v-col cols="3" v-if="restoran">
           <restoranSablon48></restoranSablon48>
         </v-col>
         <v-col cols="3" v-if="restoran">
           <restoranSablon45></restoranSablon45>
         </v-col>

         <!-------------CALL-CENTER------------------->
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon13></callcenterSablon13>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon31></callcenterSablon31>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon28></callcenterSablon28>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon29></callcenterSablon29>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon30></callcenterSablon30>
         </v-col>
          <v-col cols="3" v-if="callcenter">
           <callcenterSablon2></callcenterSablon2>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon3></callcenterSablon3>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon4></callcenterSablon4>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon5></callcenterSablon5>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon6></callcenterSablon6>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon7></callcenterSablon7>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon8></callcenterSablon8>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon9></callcenterSablon9>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon10></callcenterSablon10>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon11></callcenterSablon11>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon12></callcenterSablon12>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon1></callcenterSablon1>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon14></callcenterSablon14>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon15></callcenterSablon15>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon16></callcenterSablon16>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon17></callcenterSablon17>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon18></callcenterSablon18>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon19></callcenterSablon19>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon20></callcenterSablon20>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon21></callcenterSablon21>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon22></callcenterSablon22>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon23></callcenterSablon23>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon24></callcenterSablon24>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon25></callcenterSablon25>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon26></callcenterSablon26>
         </v-col>
         <v-col cols="3" v-if="callcenter">
           <callcenterSablon27></callcenterSablon27>
         </v-col>
         
         

         <!-------------TEKNOLOJİ------------------->
          <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon12></teknolojiSablon12>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon2></teknolojiSablon2>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon3></teknolojiSablon3>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon4></teknolojiSablon4>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon5></teknolojiSablon5>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon6></teknolojiSablon6>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon7></teknolojiSablon7>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon8></teknolojiSablon8>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon9></teknolojiSablon9>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon10></teknolojiSablon10>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon11></teknolojiSablon11>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon1></teknolojiSablon1>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon13></teknolojiSablon13>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon14></teknolojiSablon14>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon15></teknolojiSablon15>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon16></teknolojiSablon16>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon17></teknolojiSablon17>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon18></teknolojiSablon18>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon19></teknolojiSablon19>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon20></teknolojiSablon20>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon21></teknolojiSablon21>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon22></teknolojiSablon22>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon23></teknolojiSablon23>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon24></teknolojiSablon24>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon25></teknolojiSablon25>
         </v-col>
         <v-col cols="3" v-if="teknoloji">
           <teknolojiSablon26></teknolojiSablon26>
         </v-col>

          <!-------------DEMO------------------->
          <v-col cols="12" sm="3" v-if="yoresel">
           <demoSablon44></demoSablon44>
         </v-col>
          <v-col cols="12" sm="3" v-if="goldroyal">
           <demoSablon46></demoSablon46>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon1></demoSablon1>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon2></demoSablon2>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon3></demoSablon3>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon4></demoSablon4>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon5></demoSablon5>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon6></demoSablon6>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon7></demoSablon7>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon8></demoSablon8>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon43></demoSablon43>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon45></demoSablon45>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon9></demoSablon9>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon10></demoSablon10>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon41></demoSablon41>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon42></demoSablon42>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon11></demoSablon11>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon12></demoSablon12>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon13></demoSablon13>
         </v-col>
         <v-col ccols="12" sm="3" v-if="demo">
           <demoSablon14></demoSablon14>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon15></demoSablon15>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon16></demoSablon16>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon17></demoSablon17>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon18></demoSablon18>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon19></demoSablon19>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon20></demoSablon20>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon21></demoSablon21>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon22></demoSablon22>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon23></demoSablon23>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon24></demoSablon24>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon25></demoSablon25>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon26></demoSablon26>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon27></demoSablon27>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon28></demoSablon28>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon29></demoSablon29>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon30></demoSablon30>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon31></demoSablon31>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon32></demoSablon32>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon33></demoSablon33>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon34></demoSablon34>
         </v-col>
         <v-col cols="12" sm="3" v-if="demo">
           <demoSablon35></demoSablon35>
         </v-col>
         <!--<v-col cols="12" sm="3" v-if="demo">
           <demoSablon47></demoSablon47>
         </v-col>-->
         
         
         
         
         
         
        </v-row>        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { fb, db } from "../../firebase/fb";
import MobilyaSablon2 from "../sablonlar/mobilya-sablon/sablon2.vue";
import MobilyaSablon3 from "../sablonlar/mobilya-sablon/sablon3.vue";
import MobilyaSablon4 from "../sablonlar/mobilya-sablon/sablon4.vue";
import MobilyaSablon5 from "../sablonlar/mobilya-sablon/sablon5.vue";
import MobilyaSablon6 from "../sablonlar/mobilya-sablon/sablon6.vue";
import MobilyaSablon7 from "../sablonlar/mobilya-sablon/sablon7.vue";
import MobilyaSablon8 from "../sablonlar/mobilya-sablon/sablon8.vue";
import MobilyaSablon9 from "../sablonlar/mobilya-sablon/sablon9.vue";
import MobilyaSablon10 from "../sablonlar/mobilya-sablon/sablon10.vue";
import MobilyaSablon11 from "../sablonlar/mobilya-sablon/sablon11.vue";
import MobilyaSablon13 from "../sablonlar/mobilya-sablon/sablon13.vue";
import MobilyaSablon14 from "../sablonlar/mobilya-sablon/sablon14.vue";
import MobilyaSablon15 from "../sablonlar/mobilya-sablon/sablon15.vue";
import MobilyaSablon16 from "../sablonlar/mobilya-sablon/sablon16.vue";
import MobilyaSablon17 from "../sablonlar/mobilya-sablon/sablon17.vue";
import MobilyaSablon18 from "../sablonlar/mobilya-sablon/sablon18.vue";
import MobilyaSablon19 from "../sablonlar/mobilya-sablon/sablon19.vue";
import MobilyaSablon20 from "../sablonlar/mobilya-sablon/sablon20.vue";
import MobilyaSablon21 from "../sablonlar/mobilya-sablon/sablon21.vue";
import MobilyaSablon29 from "../sablonlar/mobilya-sablon/sablon29.vue";
import MobilyaSablon30 from "../sablonlar/mobilya-sablon/sablon30.vue";
import MobilyaSablon35 from "../sablonlar/mobilya-sablon/sablon35.vue";
import MobilyaSablon36 from "../sablonlar/mobilya-sablon/sablon36.vue";
import MobilyaSablon37 from "../sablonlar/mobilya-sablon/sablon37.vue";
import MobilyaSablon38 from "../sablonlar/mobilya-sablon/sablon38.vue";
import MobilyaSablon39 from "../sablonlar/mobilya-sablon/sablon39.vue";
import MobilyaSablon43 from "../sablonlar/mobilya-sablon/sablon43.vue";
import MobilyaSablon49 from "../sablonlar/mobilya-sablon/sablon49.vue";
import MobilyaSablon50 from "../sablonlar/mobilya-sablon/sablon50.vue";
import MobilyaSablon51 from "../sablonlar/mobilya-sablon/sablon51.vue";
import MobilyaSablon52 from "../sablonlar/mobilya-sablon/sablon52.vue";
import MobilyaSablon53 from "../sablonlar/mobilya-sablon/sablon53.vue";
import MobilyaSablon54 from "../sablonlar/mobilya-sablon/sablon54.vue";

/********************   MARKET  ********************* */

import MarketSablon4 from "../sablonlar/market-sablon/sablon4.vue";
import MarketSablon5 from "../sablonlar/market-sablon/sablon5.vue";
import MarketSablon6 from "../sablonlar/market-sablon/sablon6.vue";
import MarketSablon7 from "../sablonlar/market-sablon/sablon7.vue";
import MarketSablon8 from "../sablonlar/market-sablon/sablon8.vue";
import MarketSablon9 from "../sablonlar/market-sablon/sablon9.vue";
import MarketSablon10 from "../sablonlar/market-sablon/sablon10.vue";
import MarketSablon11 from "../sablonlar/market-sablon/sablon11.vue";
import MarketSablon12 from "../sablonlar/market-sablon/sablon12.vue";

/****************************************************************** */
/**************************** OTEL ****************************** */

import OtelSablon14 from "../sablonlar/otel-sablon/sablon14.vue";
import OtelSablon17 from "../sablonlar/otel-sablon/sablon17.vue";
import OtelSablon18 from "../sablonlar/otel-sablon/sablon18.vue";
import OtelSablon19 from "../sablonlar/otel-sablon/sablon19.vue";
import OtelSablon6 from "../sablonlar/otel-sablon/sablon6.vue";
import OtelSablon10 from "../sablonlar/otel-sablon/sablon10.vue";
import OtelSablon29 from "../sablonlar/otel-sablon/sablon29.vue";
import OtelSablon30 from "../sablonlar/otel-sablon/sablon30.vue";
import OtelSablon3 from "../sablonlar/otel-sablon/sablon3.vue";
import OtelSablon9 from "../sablonlar/otel-sablon/sablon9.vue";
import OtelSablon8 from "../sablonlar/otel-sablon/sablon8.vue";
import OtelSablon11 from "../sablonlar/otel-sablon/sablon11.vue";

/****************************************************************** */
/**************************** RESTORAN ****************************** */
import RestoranSablon43 from "../sablonlar/restoran-sablon/sablon43.vue";
import RestoranSablon44 from "../sablonlar/restoran-sablon/sablon44.vue";
import RestoranSablon45 from "../sablonlar/restoran-sablon/sablon45.vue";
import RestoranSablon46 from "../sablonlar/restoran-sablon/sablon46.vue";
import RestoranSablon47 from "../sablonlar/restoran-sablon/sablon47.vue";
import RestoranSablon48 from "../sablonlar/restoran-sablon/sablon48.vue";
import RestoranSablon49 from "../sablonlar/restoran-sablon/sablon49.vue";
import RestoranSablon50 from "../sablonlar/restoran-sablon/sablon50.vue";
import RestoranSablon51 from "../sablonlar/restoran-sablon/sablon51.vue";
import RestoranSablon52 from "../sablonlar/restoran-sablon/sablon52.vue";
import RestoranSablon53 from "../sablonlar/restoran-sablon/sablon53.vue";
import RestoranSablon54 from "../sablonlar/restoran-sablon/sablon54.vue";

/****************************************************************** */
/************************ CALL-CENTER ****************************** */
import CallcenterSablon1 from "../sablonlar/callcenter-sablon/sablon1.vue";
import CallcenterSablon2 from "../sablonlar/callcenter-sablon/sablon2.vue";
import CallcenterSablon3 from "../sablonlar/callcenter-sablon/sablon3.vue";
import CallcenterSablon4 from "../sablonlar/callcenter-sablon/sablon4.vue";
import CallcenterSablon5 from "../sablonlar/callcenter-sablon/sablon5.vue";
import CallcenterSablon6 from "../sablonlar/callcenter-sablon/sablon6.vue";
import CallcenterSablon7 from "../sablonlar/callcenter-sablon/sablon7.vue";
import CallcenterSablon8 from "../sablonlar/callcenter-sablon/sablon8.vue";
import CallcenterSablon9 from "../sablonlar/callcenter-sablon/sablon9.vue";
import CallcenterSablon10 from "../sablonlar/callcenter-sablon/sablon10.vue";
import CallcenterSablon11 from "../sablonlar/callcenter-sablon/sablon11.vue";
import CallcenterSablon12 from "../sablonlar/callcenter-sablon/sablon12.vue";
import CallcenterSablon13 from "../sablonlar/callcenter-sablon/sablon13.vue";
import CallcenterSablon14 from "../sablonlar/callcenter-sablon/sablon14.vue";
import CallcenterSablon15 from "../sablonlar/callcenter-sablon/sablon15.vue";
import CallcenterSablon16 from "../sablonlar/callcenter-sablon/sablon16.vue";
import CallcenterSablon17 from "../sablonlar/callcenter-sablon/sablon17.vue";
import CallcenterSablon18 from "../sablonlar/callcenter-sablon/sablon18.vue";
import CallcenterSablon19 from "../sablonlar/callcenter-sablon/sablon19.vue";
import CallcenterSablon20 from "../sablonlar/callcenter-sablon/sablon20.vue";
import CallcenterSablon21 from "../sablonlar/callcenter-sablon/sablon21.vue";
import CallcenterSablon22 from "../sablonlar/callcenter-sablon/sablon22.vue";
import CallcenterSablon23 from "../sablonlar/callcenter-sablon/sablon23.vue";
import CallcenterSablon24 from "../sablonlar/callcenter-sablon/sablon24.vue";
import CallcenterSablon25 from "../sablonlar/callcenter-sablon/sablon25.vue";
import CallcenterSablon26 from "../sablonlar/callcenter-sablon/sablon26.vue";
import CallcenterSablon27 from "../sablonlar/callcenter-sablon/sablon27.vue";
import CallcenterSablon28 from "../sablonlar/callcenter-sablon/sablon28.vue";
import CallcenterSablon29 from "../sablonlar/callcenter-sablon/sablon29.vue";
import CallcenterSablon30 from "../sablonlar/callcenter-sablon/sablon30.vue";
import CallcenterSablon31 from "../sablonlar/callcenter-sablon/sablon31.vue";

/****************************************************************** */
/************************ TEKNOLOJİ ****************************** */
import TeknolojiSablon1 from "../sablonlar/teknoloji-sablon/sablon1.vue";
import TeknolojiSablon2 from "../sablonlar/teknoloji-sablon/sablon2.vue";
import TeknolojiSablon3 from "../sablonlar/teknoloji-sablon/sablon3.vue";
import TeknolojiSablon4 from "../sablonlar/teknoloji-sablon/sablon4.vue";
import TeknolojiSablon5 from "../sablonlar/teknoloji-sablon/sablon5.vue";
import TeknolojiSablon6 from "../sablonlar/teknoloji-sablon/sablon6.vue";
import TeknolojiSablon7 from "../sablonlar/teknoloji-sablon/sablon7.vue";
import TeknolojiSablon8 from "../sablonlar/teknoloji-sablon/sablon8.vue";
import TeknolojiSablon9 from "../sablonlar/teknoloji-sablon/sablon9.vue";
import TeknolojiSablon10 from "../sablonlar/teknoloji-sablon/sablon10.vue";
import TeknolojiSablon11 from "../sablonlar/teknoloji-sablon/sablon11.vue";
import TeknolojiSablon12 from "../sablonlar/teknoloji-sablon/sablon12.vue";
import TeknolojiSablon13 from "../sablonlar/teknoloji-sablon/sablon13.vue";
import TeknolojiSablon14 from "../sablonlar/teknoloji-sablon/sablon14.vue";
import TeknolojiSablon15 from "../sablonlar/teknoloji-sablon/sablon15.vue";
import TeknolojiSablon16 from "../sablonlar/teknoloji-sablon/sablon16.vue";
import TeknolojiSablon17 from "../sablonlar/teknoloji-sablon/sablon17.vue";
import TeknolojiSablon18 from "../sablonlar/teknoloji-sablon/sablon18.vue";
import TeknolojiSablon19 from "../sablonlar/teknoloji-sablon/sablon19.vue";
import TeknolojiSablon20 from "../sablonlar/teknoloji-sablon/sablon20.vue";
import TeknolojiSablon21 from "../sablonlar/teknoloji-sablon/sablon21.vue";
import TeknolojiSablon22 from "../sablonlar/teknoloji-sablon/sablon22.vue";
import TeknolojiSablon23 from "../sablonlar/teknoloji-sablon/sablon23.vue";
import TeknolojiSablon24 from "../sablonlar/teknoloji-sablon/sablon24.vue";
import TeknolojiSablon25 from "../sablonlar/teknoloji-sablon/sablon25.vue";
import TeknolojiSablon26 from "../sablonlar/teknoloji-sablon/sablon26.vue";

/****************************************************************** */
/************************ DEMO ****************************** */
import DemoSablon1 from "../sablonlar/demo-sablon/sablon1.vue";
import DemoSablon2 from "../sablonlar/demo-sablon/sablon2.vue";
import DemoSablon3 from "../sablonlar/demo-sablon/sablon3.vue";
import DemoSablon4 from "../sablonlar/demo-sablon/sablon4.vue";
import DemoSablon5 from "../sablonlar/demo-sablon/sablon5.vue";
import DemoSablon6 from "../sablonlar/demo-sablon/sablon6.vue";
import DemoSablon7 from "../sablonlar/demo-sablon/sablon7.vue";
import DemoSablon8 from "../sablonlar/demo-sablon/sablon8.vue";
import DemoSablon9 from "../sablonlar/demo-sablon/sablon9.vue";
import DemoSablon10 from "../sablonlar/demo-sablon/sablon10.vue";
import DemoSablon11 from "../sablonlar/demo-sablon/sablon11.vue";
import DemoSablon12 from "../sablonlar/demo-sablon/sablon12.vue";
import DemoSablon13 from "../sablonlar/demo-sablon/sablon13.vue";
import DemoSablon14 from "../sablonlar/demo-sablon/sablon14.vue";
import DemoSablon15 from "../sablonlar/demo-sablon/sablon15.vue";
import DemoSablon16 from "../sablonlar/demo-sablon/sablon16.vue";
import DemoSablon17 from "../sablonlar/demo-sablon/sablon17.vue";
import DemoSablon18 from "../sablonlar/demo-sablon/sablon18.vue";
import DemoSablon19 from "../sablonlar/demo-sablon/sablon19.vue";
import DemoSablon20 from "../sablonlar/demo-sablon/sablon20.vue";
import DemoSablon21 from "../sablonlar/demo-sablon/sablon21.vue";
import DemoSablon22 from "../sablonlar/demo-sablon/sablon22.vue";
import DemoSablon23 from "../sablonlar/demo-sablon/sablon23.vue";
import DemoSablon24 from "../sablonlar/demo-sablon/sablon24.vue";
import DemoSablon25 from "../sablonlar/demo-sablon/sablon25.vue";
import DemoSablon26 from "../sablonlar/demo-sablon/sablon26.vue";
import DemoSablon27 from "../sablonlar/demo-sablon/sablon27.vue";
import DemoSablon28 from "../sablonlar/demo-sablon/sablon28.vue";
import DemoSablon29 from "../sablonlar/demo-sablon/sablon29.vue";
import DemoSablon30 from "../sablonlar/demo-sablon/sablon30.vue";
import DemoSablon31 from "../sablonlar/demo-sablon/sablon31.vue";
import DemoSablon32 from "../sablonlar/demo-sablon/sablon32.vue";
import DemoSablon33 from "../sablonlar/demo-sablon/sablon33.vue";
import DemoSablon34 from "../sablonlar/demo-sablon/sablon34.vue";
import DemoSablon35 from "../sablonlar/demo-sablon/sablon35.vue";
import DemoSablon36 from "../sablonlar/demo-sablon/sablon36.vue";
import DemoSablon37 from "../sablonlar/demo-sablon/sablon37.vue";
import DemoSablon38 from "../sablonlar/demo-sablon/sablon38.vue";
import DemoSablon39 from "../sablonlar/demo-sablon/sablon39.vue";
import DemoSablon40 from "../sablonlar/demo-sablon/sablon40.vue";
import DemoSablon41 from "../sablonlar/demo-sablon/sablon41.vue";
import DemoSablon42 from "../sablonlar/demo-sablon/sablon42.vue";
import DemoSablon43 from "../sablonlar/demo-sablon/sablon43.vue";
import DemoSablon44 from "../sablonlar/demo-sablon/sablon44.vue";
import DemoSablon45 from "../sablonlar/demo-sablon/sablon45.vue";
import DemoSablon46 from "../sablonlar/demo-sablon/sablon46.vue";
//import DemoSablon47 from "../sablonlar/demo-sablon/sablon47.vue";



export default {
  data() {
    return {  
      docName:'',
      yoresel:false,
      goldroyal:false,
      firma_kategori: "",
      mobilya: false,
      market: false,
      otel: false,
      restoran: false,
      callcenter: false,
      teknoloji: false,
      demo: false,
      secilenSablonDialog: false,
      opacity: 1,
      absolute: true,
      overlay: false,
      sablonDialog: false,
      interval: {},
      value: 0,
    };
  },
  components: {
    /********************************* */
    /*********** MOBİLYA *************** */
    mobilyaSablon2: MobilyaSablon2,
    mobilyaSablon3: MobilyaSablon3,
    mobilyaSablon4: MobilyaSablon4,
    mobilyaSablon5: MobilyaSablon5,
    mobilyaSablon6: MobilyaSablon6,
    mobilyaSablon7: MobilyaSablon7,
    mobilyaSablon8: MobilyaSablon8,
    mobilyaSablon9: MobilyaSablon9,
    mobilyaSablon10: MobilyaSablon10,
    mobilyaSablon11: MobilyaSablon11,
    mobilyaSablon13: MobilyaSablon13,
    mobilyaSablon14: MobilyaSablon14,
    mobilyaSablon15: MobilyaSablon15,
    mobilyaSablon16: MobilyaSablon16,
    mobilyaSablon17: MobilyaSablon17,
    mobilyaSablon18: MobilyaSablon18,
    mobilyaSablon19: MobilyaSablon19,
    mobilyaSablon20: MobilyaSablon20,
    mobilyaSablon21: MobilyaSablon21,
    mobilyaSablon29: MobilyaSablon29,
    mobilyaSablon30: MobilyaSablon30,
    mobilyaSablon35: MobilyaSablon35,
    mobilyaSablon36: MobilyaSablon36,
    mobilyaSablon37: MobilyaSablon37,
    mobilyaSablon38: MobilyaSablon38,
    mobilyaSablon39: MobilyaSablon39,
    mobilyaSablon43: MobilyaSablon43,
    mobilyaSablon49: MobilyaSablon49,
    MobilyaSablon50: MobilyaSablon50,
    MobilyaSablon51: MobilyaSablon51,
    mobilyaSablon52: MobilyaSablon52,
    mobilyaSablon53: MobilyaSablon53,
    mobilyaSablon54: MobilyaSablon54,
    /********************************* */
    /*********** MARKET *************** */
    marketSablon4: MarketSablon4,
    marketSablon5: MarketSablon5,
    marketSablon6: MarketSablon6,
    marketSablon7: MarketSablon7,
    marketSablon8: MarketSablon8,
    marketSablon9: MarketSablon9,
    marketSablon10: MarketSablon10,
    marketSablon11: MarketSablon11,
    marketSablon12: MarketSablon12,

    /********************************* */
    /*********** OTEL *************** */
    otelSablon14: OtelSablon14,
    otelSablon17: OtelSablon17,
    otelSablon18: OtelSablon18,
    otelSablon19: OtelSablon19,
    otelSablon6: OtelSablon6,
    otelSablon10: OtelSablon10,
    otelSablon29: OtelSablon29,
    otelSablon30: OtelSablon30,
    otelSablon3: OtelSablon3,
    otelSablon9: OtelSablon9,
    otelSablon8: OtelSablon8,
    otelSablon11: OtelSablon11,

    /********************************* */
    /*********** RESTORAN *************** */
    restoranSablon43: RestoranSablon43,
    restoranSablon44: RestoranSablon44,
    restoranSablon45: RestoranSablon45,
    restoranSablon46: RestoranSablon46,
    restoranSablon47: RestoranSablon47,
    restoranSablon48: RestoranSablon48,
    restoranSablon49: RestoranSablon49,
    restoranSablon50: RestoranSablon50,
    restoranSablon51: RestoranSablon51,
    restoranSablon52: RestoranSablon52,
    restoranSablon53: RestoranSablon53,
    restoranSablon54: RestoranSablon54,

    /********************************* */
    /*********** CALL-CENTER*********** */
    callcenterSablon1: CallcenterSablon1,
    callcenterSablon2: CallcenterSablon2,
    callcenterSablon3: CallcenterSablon3,
    callcenterSablon4: CallcenterSablon4,
    callcenterSablon5: CallcenterSablon5,
    callcenterSablon6: CallcenterSablon6,
    callcenterSablon7: CallcenterSablon7,
    callcenterSablon8: CallcenterSablon8,
    callcenterSablon9: CallcenterSablon9,
    callcenterSablon10: CallcenterSablon10,
    callcenterSablon11: CallcenterSablon11,
    callcenterSablon12: CallcenterSablon12,
    callcenterSablon13: CallcenterSablon13,
    callcenterSablon14: CallcenterSablon14,
    callcenterSablon15: CallcenterSablon15,
    callcenterSablon16: CallcenterSablon16,
    callcenterSablon17: CallcenterSablon17,
    callcenterSablon18: CallcenterSablon18,
    callcenterSablon19: CallcenterSablon19,
    callcenterSablon20: CallcenterSablon20,
    callcenterSablon21: CallcenterSablon21,
    callcenterSablon22: CallcenterSablon22,
    callcenterSablon23: CallcenterSablon23,
    callcenterSablon24: CallcenterSablon24,
    callcenterSablon25: CallcenterSablon25,
    callcenterSablon26: CallcenterSablon26,
    callcenterSablon27: CallcenterSablon27,
    callcenterSablon28: CallcenterSablon28,
    callcenterSablon29: CallcenterSablon29,
    callcenterSablon30: CallcenterSablon30,
    callcenterSablon31: CallcenterSablon31,

    /********************************* */
    /*********** TEKNOLOJİ*********** */
    teknolojiSablon1: TeknolojiSablon1,
    teknolojiSablon2: TeknolojiSablon2,
    teknolojiSablon3: TeknolojiSablon3,
    teknolojiSablon4: TeknolojiSablon4,
    teknolojiSablon5: TeknolojiSablon5,
    teknolojiSablon6: TeknolojiSablon6,
    teknolojiSablon7: TeknolojiSablon7,
    teknolojiSablon8: TeknolojiSablon8,
    teknolojiSablon9: TeknolojiSablon9,
    teknolojiSablon10: TeknolojiSablon10,
    teknolojiSablon11: TeknolojiSablon11,
    teknolojiSablon12: TeknolojiSablon12,
    teknolojiSablon13: TeknolojiSablon13,
    teknolojiSablon14: TeknolojiSablon14,
    teknolojiSablon15: TeknolojiSablon15,
    teknolojiSablon16: TeknolojiSablon16,
    teknolojiSablon17: TeknolojiSablon17,
    teknolojiSablon18: TeknolojiSablon18,
    teknolojiSablon19: TeknolojiSablon19,
    teknolojiSablon20: TeknolojiSablon20,
    teknolojiSablon21: TeknolojiSablon21,
    teknolojiSablon22: TeknolojiSablon22,
    teknolojiSablon23: TeknolojiSablon23,
    teknolojiSablon24: TeknolojiSablon24,
    teknolojiSablon25: TeknolojiSablon25,
    teknolojiSablon26: TeknolojiSablon26,

    /********************************* */
    /*********** DEMO*********** */
    demoSablon1: DemoSablon1,
    demoSablon2: DemoSablon2,
    demoSablon3: DemoSablon3,
    demoSablon4: DemoSablon4,
    demoSablon5: DemoSablon5,
    demoSablon6: DemoSablon6,
    demoSablon7: DemoSablon7,
    demoSablon8: DemoSablon8,
    demoSablon9: DemoSablon9,
    demoSablon10: DemoSablon10,
    demoSablon11: DemoSablon11,
    demoSablon12: DemoSablon12,
    demoSablon13: DemoSablon13,
    demoSablon14: DemoSablon14,
    demoSablon15: DemoSablon15,
    demoSablon16: DemoSablon16,
    demoSablon17: DemoSablon17,
    demoSablon18: DemoSablon18,
    demoSablon19: DemoSablon19,
    demoSablon20: DemoSablon20,
    demoSablon21: DemoSablon21,
    demoSablon22: DemoSablon22,
    demoSablon23: DemoSablon23,
    demoSablon24: DemoSablon24,
    demoSablon25: DemoSablon25,
    demoSablon26: DemoSablon26,
    demoSablon27: DemoSablon27,
    demoSablon28: DemoSablon28,
    demoSablon29: DemoSablon29,
    demoSablon30: DemoSablon30,
    demoSablon31: DemoSablon31,
    demoSablon32: DemoSablon32,
    demoSablon33: DemoSablon33,
    demoSablon34: DemoSablon34,
    demoSablon35: DemoSablon35,
    demoSablon36: DemoSablon36,
    demoSablon37: DemoSablon37,
    demoSablon38: DemoSablon38,
    demoSablon39: DemoSablon39,
    demoSablon40: DemoSablon40,
    demoSablon41: DemoSablon41,
    demoSablon42: DemoSablon42,
    demoSablon43: DemoSablon43,
    demoSablon44: DemoSablon44,
    demoSablon45: DemoSablon45,
    demoSablon46: DemoSablon46,
    //demoSablon47: DemoSablon47,
    
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  beforeMount() {
    this.docName = this.$route.params.id;
    db.collection("kullanici")
      .doc(this.$route.params.id)
      .get()
      .then((doc) => {
        this.firma_kategori = doc.data().firma_kategori;
       // console.log(this.firma_kategori);
        // console.log(this.products)
      });
  },
  created() {
    this.overlay = true;
  },
  watch: {
    docName(val){
      if(val=="QraQUKZjdq7Sd5PxpeVC"){
        this.yoresel=true;
      
      }
      else if(val=="nlcUdE5vCkmz5qhw3QOl"){
        this.goldroyal=true;
      
      }

    },
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 2000);
    },
    firma_kategori(val) {
      // alert(val);
      if (val === "Mobilya") {
        this.mobilya = true;
        this.otel = false;
        this.market = false;
        this.restoran = false;
        this.callcenter = false;
        this.teknoloji = false;
        this.demo = false;
      } else if (val === "Otel") {
        this.mobilya = false;
        this.otel = true;
        this.market = false;
        this.restoran = false;
        this.callcenter = false;
        this.teknoloji = false;
        this.demo = false;
      } else if (val === "Market") {
        this.mobilya = false;
        this.otel = false;
        this.market = true;
        this.restoran = false;
        this.callcenter = false;
        this.teknoloji = false;
        this.demo = false;
      } else if (val === "Restoran & Cafe") {
        this.mobilya = false;
        this.otel = false;
        this.market = false;
        this.restoran = true;
        this.callcenter = false;
        this.teknoloji = false;
        this.demo = false;
      } else if (val === "Call-Center") {
        this.mobilya = false;
        this.otel = false;
        this.market = false;
        this.restoran = false;
        this.callcenter = true;
        this.teknoloji = false;
        this.demo = false;
      } else if (val === "Teknoloji") {
        this.mobilya = false;
        this.otel = false;
        this.market = false;
        this.restoran = false;
        this.callcenter = false;
        this.teknoloji = true;
        this.demo = false;
      } else if (val === "Demo") {
        this.mobilya = false;
        this.otel = false;
        this.market = false;
        this.restoran = false;
        this.callcenter = false;
        this.teknoloji = false;
        this.demo = true;
        
      }
      
    },
  },
  methods: {},
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}
.v-progress-circular {
  margin: 1rem;
}
</style>