<template>
<v-row>
    <v-col>
        <p>
        Online Brochure System V1 versiyon sürümü.
    </p>
    </v-col>
</v-row>  
</template>

<script>
export default {

}
</script>

<style>

</style>