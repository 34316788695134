<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card-title class="headline">
          <v-spacer></v-spacer>
          <div id="editor"></div>
          <v-btn
            id="cmd"
            tile
            color="success"
            class="white--text"
            @click="makePDF"
            :loading="loaderBrosur"
          >
            İNDİR
            <v-icon right dark> mdi-cloud-download </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
          style="margin-top:-15px"
            tile
            color="primary"
            outlined
            @click="fiyatlandirmaYap"
            v-if="fiyatButonShow"
          >
            {{fiyatButonName}}
          </v-btn>
        </v-card-title>
        <v-card-text class="d-flex justify-center" id="test_1">
          <div id="capture" ref="capture">
            <v-card width="600" height="730" :color="brosurBackgroundColor">
              <v-img
                class="brosurBackground"
                :src="brosurBackground.fileName"
                width="600"
                height="730"
              >
                <v-flex>
                  <v-row>
                    <v-col cols="3">
                      <v-img
                        src="../../assets/img/indirim1.png"
                        style="height: 52px"
                      ></v-img>
                    </v-col>
                    <v-col cols="9" class="pa-0 pt-5" style="color: white">
                      <v-flex>
                        <a>
                          <vue-draggable-resizable
                            :lock-aspect-ratio="true"
                            :resizable="true"
                          >
                            <h6
                              class="ml-n11"
                              :style="{
                                'font-size': fontSize,
                                color: color,
                              }"
                              @dblclick="editTitle"
                            >
                              {{ brosurTitle }}
                            </h6>
                          </vue-draggable-resizable></a
                        >
                        <a>
                          <vue-draggable-resizable>
                            <h2
                              class="ml-n8"
                              @dblclick="editSlogan"
                              :style="{
                                'line-height':'30px',
                                'font-size': adresYaziBoyutu.alan9,
                                color: adresYaziRenk.alan9,
                                'margin-top': '-180px',
                              }"
                            >
                              {{ yaziSlogan1 }}
                            </h2>
                          </vue-draggable-resizable></a
                        >
                      </v-flex>
                    </v-col>
                    <v-col
                      cols="7"
                      style="
                        z-index: -1;
                        position: absolute;
                        margin-left: 275px;
                      "
                    >
                      <v-img src="../../assets/img/indirim2.png"></v-img>
                    </v-col>
                  </v-row>
                </v-flex>
                <v-flex style="margin-top: -170px">
        
                  <v-row class="mt-n3" v-if="selectedProduct1">
                    
                    <v-col cols="9" class="pa-15 pt-3">
                      <v-card
                        color="#ed2624"
                        style="border-radius: 50px 20px"
                        class="pl-2 pb-2"
                      >
                        <v-card color="white" style="border: 1px solid white">
                          <v-hover v-slot:default="{ hover }">
                            <v-img :src="seciliUrun.product1" height="140px">
                              <v-expand-transition>
                                <div
                                  v-if="hover"
                                  class="
                                    d-flex
                                    transition-fast-in-fast-out
                                    sari
                                    v-card--reveal
                                    display-3
                                    white--text
                                  "
                                  style="height: 100%"
                                >
                                  <v-btn
                                    class="ma-2"
                                    tile
                                    color="black"
                                    dark
                                    @click="deleteProductUrunResmi1"
                                  >
                                    Çıkar
                                  </v-btn>
                                </div>
                              </v-expand-transition>
                            </v-img>
                          </v-hover>
                        </v-card>
                      </v-card>
                    </v-col>
                    <v-col cols="3" class="ml-n13 mt-14">
                      <v-img v-if="fiyatShow"                     
                        src="../../assets/img/_fiyat_kirmizi.png"
                        class="ml-n16"
                      >
                        <p
                          class="mt-8 ml-2 font-weight-bold text-center"
                          style="font-size: 28px"
                        >
                          {{ seciliUrun.fiyat1 }} ₺
                        </p>
                      </v-img>
                    </v-col>
                    <v-col cols="2" class="ml-n16">
                      <v-img
                        src="../../assets/img/yan_logo.png"
                        class="ml-n2"
                      ></v-img>
                      <p @dblclick="editYaziAlani" class="font-weight-bold text-center" style="line-height:12px">{{ yaziSlogan2 }}</p>
                      <p class="ml-n2 mt-n3 text-center" style="font-size: 9px">
                        {{ seciliUrun.urunadi1 }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n3" v-else>
                    <v-col cols="9" class="pa-15 pt-3 mt-0">
                      <v-card
                        color="#ed2624"
                        style="border-radius: 50px 20px"
                        class="pl-2 pb-2"
                      >
                        <v-card color="white" class="pa-1">
                          <v-img height="140px">
                            <p
                              class="font-weight-bold pl-1"
                              style="font-size: 10px; color: white"
                            >
                              1
                            </p>
                            <v-row style="height: 155px">
                              <v-col cols="9" class="pa-3 mt-n5">
                                <div
                                  class="
                                    d-flex
                                    transition-fast-in-fast-out
                                    darken-1
                                    v-card--reveal
                                    display-3
                                    white--text
                                  "
                                  style="height: 100%"
                                >
                                  <v-row justify="space-around">
                                    <span class="group pa-2">
                                      <v-btn
                                        icon
                                        color="indigo darken-4"
                                        :loading="loadingLogo"
                                        @click="selectProduct1"
                                      >
                                        <v-icon x-large dark>mdi-plus</v-icon>
                                      </v-btn>
                                    </span>
                                  </v-row>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                        </v-card>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n16" v-if="selectedProduct2">
                    <v-col cols="9" class="pa-15 pt-3">
                      <v-card
                        color="#ed2624"
                        style="border-radius: 50px 20px"
                        class="pl-2 pb-2"
                      >
                        <v-card color="white" style="border: 1px solid white">
                          <v-hover v-slot:default="{ hover }">
                            <v-img :src="seciliUrun.product2" height="140px">
                              <v-expand-transition>
                                <div
                                  v-if="hover"
                                  class="
                                    d-flex
                                    transition-fast-in-fast-out
                                    sari
                                    v-card--reveal
                                    display-3
                                    white--text
                                  "
                                  style="height: 100%"
                                >
                                  <v-btn
                                    class="ma-2"
                                    tile
                                    color="black"
                                    dark
                                    @click="deleteProductUrunResmi2"
                                  >
                                    Çıkar
                                  </v-btn>
                                </div>
                              </v-expand-transition>
                            </v-img>
                          </v-hover>
                        </v-card>
                      </v-card>
                    </v-col>
                    <v-col cols="3" class="ml-n16 mt-14">
                      <v-img v-if="fiyatShow"
                        src="../../assets/img/_fiyat_kirmizi.png"
                        class="ml-n16"
                      >
                        <p
                          class="mt-8 ml-2 font-weight-bold text-center"
                          style="font-size: 28px"
                        >
                          {{ seciliUrun.fiyat2 }} ₺
                        </p>
                      </v-img>
                    </v-col>
                    <v-col cols="2" class="ml-n16">
                      <v-img
                        src="../../assets/img/yan_logo.png"
                        class="ml-n2"
                      ></v-img>
                      <p @dblclick="editAcilisYazi" class="font-weight-bold text-center" style="line-height:12px">{{ acilisYazi }}</p>
                      <p class="ml-n3 mt-n2 text-center" style="font-size: 9px">
                        {{ seciliUrun.urunadi2 }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n16" v-else>
                    <v-col cols="9" class="pa-15 pt-3 mt-0">
                      <v-card
                        color="#ed2624"
                        style="border-radius: 50px 20px"
                        class="pl-2 pb-2"
                      >
                        <v-card color="white" class="pa-1">
                          <v-img height="140px">
                            <p
                              class="font-weight-bold pl-1"
                              style="font-size: 10px; color: white"
                            >
                              1
                            </p>
                            <v-row style="height: 155px">
                              <v-col cols="9" class="pa-3 mt-n5">
                                <div
                                  class="
                                    d-flex
                                    transition-fast-in-fast-out
                                    darken-1
                                    v-card--reveal
                                    display-3
                                    white--text
                                  "
                                  style="height: 100%"
                                >
                                  <v-row justify="space-around">
                                    <span class="group pa-2">
                                      <v-btn
                                        icon
                                        color="indigo darken-4"
                                        :loading="loadingLogo"
                                        @click="selectProduct2"
                                      >
                                        <v-icon x-large dark>mdi-plus</v-icon>
                                      </v-btn>
                                    </span>
                                  </v-row>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                        </v-card>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n16" v-if="selectedProduct3">
                    <v-col cols="9" class="pa-15 pt-3">
                      <v-card
                        color="#ed2624"
                        style="border-radius: 50px 20px"
                        class="pl-2 pb-2"
                      >
                        <v-card color="white" style="border: 1px solid white">
                          <v-hover v-slot:default="{ hover }">
                            <v-img :src="seciliUrun.product3" height="140px">
                              <v-expand-transition>
                                <div
                                  v-if="hover"
                                  class="
                                    d-flex
                                    transition-fast-in-fast-out
                                    sari
                                    v-card--reveal
                                    display-3
                                    white--text
                                  "
                                  style="height: 100%"
                                >
                                  <v-btn
                                    class="ma-2"
                                    tile
                                    color="black"
                                    dark
                                    @click="deleteProductUrunResmi3"
                                  >
                                    Çıkar
                                  </v-btn>
                                </div>
                              </v-expand-transition>
                            </v-img>
                          </v-hover>
                        </v-card>
                      </v-card>
                    </v-col>
                    <v-col cols="3" class="ml-n16 mt-14">
                      <v-img v-if="fiyatShow"
                        src="../../assets/img/_fiyat_kirmizi.png"
                        class="ml-n16"
                      >
                        <p
                          class="mt-8 ml-2 font-weight-bold text-center"
                          style="font-size: 28px"
                        >
                          {{ seciliUrun.fiyat3 }} ₺
                        </p>
                      </v-img>
                    </v-col>
                    <v-col cols="2" class="ml-n16">
                      <v-img
                        src="../../assets/img/yan_logo.png"
                        class="ml-n2"
                      ></v-img>
                      <p @dblclick="editAdress" class="font-weight-bold text-center" style="line-height:12px">{{ adres.alan8 }}</p>
                      <p class="ml-n3 mt-n2 text-center" style="font-size: 9px">
                        {{ seciliUrun.urunadi3 }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n16" v-else>
                    <v-col cols="9" class="pa-15 pt-3 mt-0">
                      <v-card
                        color="#ed2624"
                        style="border-radius: 50px 20px"
                        class="pl-2 pb-2"
                      >
                        <v-card color="white" class="pa-1">
                          <v-img height="140px">
                            <p
                              class="font-weight-bold pl-1"
                              style="font-size: 10px; color: white"
                            >
                              1
                            </p>
                            <v-row style="height: 155px">
                              <v-col cols="9" class="pa-3 mt-n5">
                                <div
                                  class="
                                    d-flex
                                    transition-fast-in-fast-out
                                    darken-1
                                    v-card--reveal
                                    display-3
                                    white--text
                                  "
                                  style="height: 100%"
                                >
                                  <v-row justify="space-around">
                                    <span class="group pa-2">
                                      <v-btn
                                        icon
                                        color="indigo darken-4"
                                        :loading="loadingLogo"
                                        @click="selectProduct3"
                                      >
                                        <v-icon x-large dark>mdi-plus</v-icon>
                                      </v-btn>
                                    </span>
                                  </v-row>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                        </v-card>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n16" v-if="selectedProduct4">
                    <v-col cols="9" class="pa-15 pt-3">
                      <v-card
                        color="#ed2624"
                        style="border-radius: 50px 20px"
                        class="pl-2 pb-2"
                      >
                        <v-card color="white" style="border: 1px solid white">
                          <v-hover v-slot:default="{ hover }">
                            <v-img :src="seciliUrun.product4" height="140px">
                              <v-expand-transition>
                                <div
                                  v-if="hover"
                                  class="
                                    d-flex
                                    transition-fast-in-fast-out
                                    sari
                                    v-card--reveal
                                    display-3
                                    white--text
                                  "
                                  style="height: 100%"
                                >
                                  <v-btn
                                    class="ma-2"
                                    tile
                                    color="black"
                                    dark
                                    @click="deleteProductUrunResmi4"
                                  >
                                    Çıkar
                                  </v-btn>
                                </div>
                              </v-expand-transition>
                            </v-img>
                          </v-hover>
                        </v-card>
                      </v-card>
                    </v-col>
                    <v-col cols="3" class="ml-n16 mt-14">
                      <v-img v-if="fiyatShow"
                        src="../../assets/img/_fiyat_kirmizi.png"
                        class="ml-n16"
                      >
                        <p
                          class="mt-8 ml-2  font-weight-bold text-center"
                          style="font-size: 28px"
                        >
                          {{ seciliUrun.fiyat4 }} ₺
                        </p>
                      </v-img>
                    </v-col>
                    <v-col cols="2" class="ml-n16">
                      <v-img
                        src="../../assets/img/yan_logo.png"
                        class="ml-n2"
                      ></v-img>
                      <p @dblclick="editAcilisYazi2" class="font-weight-bold text-center" style="line-height:12px">
                        {{ acilisYazi2 }}
                      </p>
                      <p class="ml-n3 mt-n2 text-center" style="font-size: 9px">
                        {{ seciliUrun.urunadi4 }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n16" v-else>
                    <v-col cols="9" class="pa-15 pt-3 mt-0">
                      <v-card
                        color="#ed2624"
                        style="border-radius: 50px 20px"
                        class="pl-2 pb-2"
                      >
                        <v-card color="white" class="pa-1">
                          <v-img height="140px">
                            <p
                              class="font-weight-bold pl-1"
                              style="font-size: 10px; color: white"
                            >
                              1
                            </p>
                            <v-row style="height: 155px">
                              <v-col cols="9" class="pa-3 mt-n5">
                                <div
                                  class="
                                    d-flex
                                    transition-fast-in-fast-out
                                    darken-1
                                    v-card--reveal
                                    display-3
                                    white--text
                                  "
                                  style="height: 100%"
                                >
                                  <v-row justify="space-around">
                                    <span class="group pa-2">
                                      <v-btn
                                        icon
                                        color="indigo darken-4"
                                        :loading="loadingLogo"
                                        @click="selectProduct4"
                                      >
                                        <v-icon x-large dark>mdi-plus</v-icon>
                                      </v-btn>
                                    </span>
                                  </v-row>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                        </v-card>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-img>
            </v-card>
          </div>
        </v-card-text>
         <v-card-actions>
            <v-row class="">
              <v-col offset="7" cols="3">
                <v-label>
              <span>Arka Plan Rengi</span>
              <input
                type="color"
                id="favcolor"
                name="favcolor"
                value="#b8b8b8"
                @change="updateBackgroundColor"
              />
               </v-label>                           
              </v-col>
            </v-row>
          </v-card-actions>
      </v-col>
    </v-row>
    <v-row dense>
      <v-snackbar
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        center
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-row>
    <v-dialog v-model="yaziSloganDialog" @keydown.enter="yaziSloganDialog = false" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Yazı Alanını Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="yaziSloganDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="yaziSlogan1"
                    prepend-icon="bookmark"
                    color="sari"
                    maxlength="12"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                    <div class="mt-3" v-text="(12 - yaziSlogan1.length)"></div>
                  </v-col>
                <v-col cols="6">Yazı Boyutu</v-col>
                <v-col cols="6">
                  <input
                    type="number"
                    value="20"
                    @input="changeFontAdresAlan9"
                  />
                </v-col>
                <v-col cols="6"><label>Yazı Rengi</label></v-col>
                <v-col cols="6">
                  <input
                    id="color-choos"
                    type="color"
                    name="color"
                    value="#ffffff"
                    @input="changeColorAdresAlan9"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="adresKaydet()"
            :loading="adresButon"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="yaziAlaniDialog" @keydown.enter="yaziAlaniDialog = false" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Yazı Alanını Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="yaziAlaniDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="yaziSlogan2"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="yaziAlaniDialog = false"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="yaziAlaniDialog2" @keydown.enter="yaziAlaniDialog2 = false" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Yazı Alanını Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="yaziAlaniDialog2 = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="acilisYazi"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="yaziAlaniDialog2 = false"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="acilisYaziDialog2" @keydown.enter="acilisYaziDialog2 = false" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Yazı Alanını Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="acilisYaziDialog2 = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="acilisYazi2"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
             @click="acilisYaziDialog2 = false"
            
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editTitleDialog" @keydown.enter="editTitleDialog = false" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Başlığı Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="editTitleDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row v-if="baslikShow">
                <v-col cols="10">
                  <v-text-field
                    v-model="brosurTitle"
                    label="Yeni Başlık"
                    prepend-icon="bookmark"
                    color="sari"
                    maxlength="38"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                    <div class="mt-3" v-text="(38 - brosurTitle.length)"></div>
                  </v-col>
                <v-col cols="6">Yazı Boyutu</v-col>
                <v-col cols="6">
                  <input type="number" value="9" @input="changeFontSize" />
                </v-col>
                <v-col cols="6"><label>Yazı Rengi</label></v-col>
                <v-col cols="6">
                  <input
                    id="color-choos"
                    type="color"
                    name="color"
                    value="#ffffff"
                    @input="changeColor"
                  />
                </v-col>
              </v-row>
              <v-row v-if="altbaslikShow">
                <v-col cols="12">
                  <v-text-field
                    v-model="altBaslikalani"
                    label="Alt Başlık"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">Yazı Boyutu</v-col>
                <v-col cols="6">
                  <input
                    type="number"
                    value="41"
                    @input="changeFontALtBaslik"
                  />
                </v-col>
                <v-col cols="6"><label>Yazı Rengi</label></v-col>
                <v-col cols="6">
                  <input
                    id="color-choos"
                    type="color"
                    name="color"
                    value="#ffffff"
                    @input="changeColorAltBaslik"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="adresKaydet()"
            :loading="adresButon"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="adresEditDiaog" @keydown.enter="adresEditDiaog = false" max-width="500">
      <v-card>
        <v-card-title class="headline">
         Yazı Alanını Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="adresEditDiaog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="adres.alan8"
                    color="sari"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
           @click="adresEditDiaog = false"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="urunDialog" @keydown.enter="urunDialog = false" max-width="400">
        <v-card>
          <v-card-title class="headline">
            {{ title }}
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="urunDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      label="Resim"
                      color="sari"
                      @change="imageUrunUpload"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.urunadi"
                      label="Ürün Adı"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="product.urunaciklama"
                      label="Açıklama"
                      prepend-icon="gesture"
                      color="sari"
                      rows="1"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.fiyat"
                      label="Fiyat"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      item-text="name"
                      item-value="sembol"
                      :items="paraBirimleri"
                      v-model="product.paraBirimi"
                      prepend-icon="euro"
                      label="Para Birimi"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="sari"
              @click="saveProduct"
              :loading="loader"
              dark
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="selectedProductDialog"
        @keydown.enter="selectedProductDialog = false"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="indigo">
            <v-row>
              <v-col cols="8" offset="4">
                <v-text-field
                  v-model="searchKey"
                  cache-items
                  flat
                  hide-no-data
                  hide-details
                  label="Aramak istediğiniz ürünü ekleyin..."
                  solo-inverted
                ></v-text-field>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="selectedProductDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <!--ürünler -->
          <v-row dense class="mt-2">
            <v-col
              cols="6"
              sm="6"
              md="4"
              lg="3"
              v-for="product in filteredList"
              :key="product.id"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card tile color="grey lighten-4">
                  <v-img
                    :aspect-ratio="16 / 9"
                    class="productImage"
                    :src="product.blobUrl"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="
                          d-flex
                          transition-fast-in-fast-out
                          sari
                          v-card--reveal
                          display-3
                          white--text
                        "
                        style="height: 100%"
                      >
                        <v-btn
                          class="ma-2"
                          tile
                          color="black"
                          dark
                          @click="selectedProduct(product)"
                        >
                          Ekle
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text style="position: relative">
                    <v-row no-gutters class="productImageRow">
                      <v-col cols="12">
                        <v-row no-gutters>
                          <v-col cols="12" class="text-center">
                            <h3 class="text-center productUrunadi">
                              {{ product.urunadi }}
                            </h3>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-1"></v-divider>
                    <v-row no-gutters>
                      <v-col cols="12" class="mt-1">
                        <h2 class="text-center">
                          <strong
                            class="font-weight-black productFiyat text-center"
                            style="font-weight: older"
                          >
                            {{ product.fiyat }} {{ product.paraBirimi }}
                          </strong>
                        </h2>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
const clamp = (num, lower = 0, upper) => {
  return num < lower ? lower : num > upper ? upper : num;
};

import { fb, db } from "../../firebase/fb";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";
import VueDraggableResizable from "vue-draggable-resizable";
import Brosur1 from "../brosur/brosur1.vue";
import facebookVue from "../boyutlar/facebook.vue";

export default {
  data() {
    return {
      fiyatButonShow:false,
      fiyatShow:true,
      fiyatButonName:'Fiyat Kaldır',
      acilisYaziDialog2: false,
      acilisYazi2: "NFS SOFT MOBİLYA",
      acilisYazi: "NFS SOFT MOBİLYA",
      yaziAlaniDialog2: false,
      yaziSloganDialog: false,
      yaziSlogan1: "DEV İNDİRİM",
      yaziSlogan2: "NFS SOFT MOBİLYA",
      yaziAlaniDialog: false,
      tarihAlani: "11 OCAK 2022 -   24 OCAK 2022",
      sayi: "280",
      sayiAlani: "SAYI",
      yaziAlani1: "Yazılım,",
      yaziAlani2: "Heyecan Aynı,",
      firma: "Firma İletişim Bilgileri",
      web: "WEB ZİYARET",
      hemen_al: "HEMEN AL",
      urunFiyatButon: "Fiyat Ekle",
      selectedProduct1: "",
      selectedProduct2: "",
      selectedProduct3: "",
      selectedProduct4: "",
      backgrounds: [],
      urunSayisiDialog: false,
      searchKey: "",
      loadingLogo: false,
      logo: {
        resim: "",
        fileName: "",
      },
      logoButon: true,
      logoResim: false,
      bosluk: true,
      selectedProductFiyatTextUrun1A5: false,
      selectedProductUrunAdiFontSizeUrun1: "30px",
      x: 100,
      y: 100,
      w: 250,
      h: 50,
      min_w: 250,
      min_h: 10,
      max_w: 300,
      max_h: 60,
      windowStyle: {
        width: "400px",
        position: "relative",
      },
      cubukA4Class: true,
      cubukA5Class: false,
      selectedProductFiyatTextA4: true,
      selectedProductFiyatTextA5: false,
      selectedProductUrunAdiFontSize: "20px",
      selectedProductUrunAdiA4: true,
      selectedProductUrunAdiA5: false,
      cubukHeight: "25px",
      brosurA4Class: true,
      brosurA5Class: false,
      adresAlan9Width: "130px",
      adresAlan9Height: "25px",
      isDragging: false,
      dragItemCoords: {
        top: 5,
        left: 5,
      },
      selection: 1,
      brosurBackground: {
        resim: "",
        fileName: "",
      },
      fontFamily: "Georgia, serif",
      color: "#ffffff",
      fontSize: "9px",
      updateUrunLoader: false,
      editUrunDialog: false,
      urunDialog: false,
      loadingUrunImage: false,
      brosurSecimDialog: false,
      loaderBrosurA2: false,
      brosurDialogA2: false,
      loaderBrosur: false,
      paraBirimleri: [
        { name: "TL", sembol: "TL" },
        { name: "$", sembol: "$" },
        { name: "£", sembol: "£ " },
        { name: "€", sembol: "€" },
      ],
      imageShow: true,
      id: this.$route.params.id,
      brosurDialogBoyut: 800,
      adresAlani: "",
      blbUrl: null,
      adresEditDiaog: false,
      brosurUrun: {
        urunAdi: "",
        urunFiyat: "",
        bosluk: " ...................... ",
        paraBirimi: "",
      },
      urunListDialog: false,
      renk: "success",
      chk: false,
      editTitleDialog: false,
      brosurTitle: "AÇILIŞA ÖZEL DEĞİL HER ZAMAN",
      checked: false,
      editDialog: false,
      disabled: true,
      products: [],
      snackbar: {
        status: false,
        text: "",
        color: "",
        timeout: 2000,
      },
      title: "",
      dialog: false,
      loader: false,
      updateLoader: false,
      product: {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      },
      loading: false,
      selectedProducts: [],
      brosurDialog: false,
      allUrunlerDialog: false,
      list: [],
      adresYaziRenk: {
        acilis: "#ffffff",
        alan1: "#000000",
        alan8: "#ffffff",
        alan9: "#ffffff",
        alan10: " #ffffff",
        webAdresi: "#ffffff",
        web: "#063062",
        firma: "#000000",
        hemenAl: "#ffffff",
      },
      adresYaziBoyutu: {
        acilis: "38px",
        alan1: "15px",
        alan8: "25px",
        alan9: "22px",
        alan10: "20px",
        webAdresi: "25px",
        web: "20px",
        firma: "12px",
        hemenAl: "12px",
      },
      adres: {
        alan1: "Ataüni Teknokent Yakutiye ERZURUM",
        alan8: "NFS SOFT MOBİLYA",
        alan9: "MARKA",
        webAdresi: "wwww.kardelenyatak.com",
      },
      urunler: [],
      icecekler: [],
      tatlilar: [],
      bayi: {
        id: null,
        docName: "",
      },
      output: null,
      kategoriler: [],
      updateLoader: false,
      urunFiyatDialog: false,
      loadingBgImage: false,
      cubuk: true,
      brosurBoyutWidth: "1080px",
      brosurBoyutHeight: "1080px",
      selectedProductImage: "",
      productFiyatAvatarMarginTop: "",
      productFiyatAvatarMarginLeft: "",
      selectedProductImageUrun1: "",
      adresButon: false,
      instagram: false,
      facebook: false,
      a4Boyut: true,
      a5boyut: false,
      selectedProductDialog: false,
      seciliUrun: {
        product1: "",
        product2: "",
        product3: "",
        product4: "",
        product5: "",
        product6: "",
        product7: "",
        product8: "",
        product9: "",
        urunadi1: "",
        urunadi2: "",
        urunadi3: "",
        urunadi4: "",
        urunadi5: "",
        urunadi6: "",
        urunadi7: "",
        urunadi8: "",
        urunadi9: "",
        fiyat1: "",
        fiyat2: "",
        fiyat3: "",
        fiyat4: "",
        fiyat6: "",
        fiyat7: "",
        fiyat8: "",
        fiyat9: "",
      },
      fiyatAlani: false,
      urun1: false,
      urun2: false,
      urun3: false,
      urun4: false,
      backgrounds: [],
      firmaBilgiShow: false,
      adresBilgiShow: false,
      hemenalShow: false,
      brosurBackgroundColor: "#b8b8b8",
      altBaslikalani: "BU İNDİRİMLERİ KAÇIRMAYIN",
      altBalikRenk: "white",
      altBaslikFontBoyut: "41px",
      baslikShow: false,
      altbaslikShow: false,
      selectedProduct1: "",
      selectedProduct2: "",
      selectedProduct3: "",
      selectedProduct4: "",
      selectedProduct5: "",
      selectedProduct6: "",
      selectedProduct7: "",
      selectedProduct8: "",
      selectedProduct9: "",
    };
  },

  components: {
    VueDraggableResizable,
    brosur1: Brosur1,
  },
  mounted() {
    this.getUrunler();
    this.getBackround();
    this.getAdresBilgi();
  },
  watch: {
    brosurTitle: (val) => {
      this.brosurTitle = val;
    },

    selectedProducts: {
      handler(val, oldVal) {
        if (val.length > 6) {
          this.urunSayisiDialog = true;
        }
      },
    },
    selectedProduct1(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product1 = urunResmi;
        this.seciliUrun.urunadi1 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat1 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
    selectedProduct2(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product2 = urunResmi;
        this.seciliUrun.urunadi2 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat2 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
    selectedProduct3(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product3 = urunResmi;
        this.seciliUrun.urunadi3 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat3 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
    selectedProduct4(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product4 = urunResmi;
        this.seciliUrun.urunadi4 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat4 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
    selectedProduct5(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product5 = urunResmi;
        this.seciliUrun.urunadi5 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat5 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
    selectedProduct6(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product6 = urunResmi;
        this.seciliUrun.urunadi6 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat6 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
    selectedProduct7(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product7 = urunResmi;
        this.seciliUrun.urunadi7 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat7 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
    selectedProduct8(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product8 = urunResmi;
        this.seciliUrun.urunadi8 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat8 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
    selectedProduct9(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product9 = urunResmi;
        this.seciliUrun.urunadi9 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat9 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
        this.fiyatButonShow = true
      }
    },
  },
  methods: {
    fiyatlandirmaYap(){
      this.fiyatShow = !this.fiyatShow;
      if(this.fiyatShow == false){
        this.fiyatButonName = "Fiyat Ekle"
      }
      else{
         this.fiyatButonName = "Fiyat Kaldır"
      }
    },
    editAcilisYazi2() {
      this.acilisYaziDialog2 = true;
    },
    editAcilisYazi() {
      this.yaziAlaniDialog2 = true;
    },
    editSlogan() {
      this.yaziSloganDialog = true;
    },
    editYaziAlani() {
      this.yaziAlaniDialog = true;
    },
    updateBackgroundColor(event) {
      this.brosurBackground.fileName = "";
      this.brosurBackgroundColor = event.target.value;
    },
    hemenAlEdit() {
      this.firmaBilgiShow = false;
      this.adresBilgiShow = false;
      this.hemenalShow = true;
      this.adresEditDiaog = true;
    },
    editAdress() {
      this.adresEditDiaog = true;
    },
    editFirma() {
      this.firmaBilgiShow = true;
      this.adresBilgiShow = false;
      this.hemenalShow = false;
      this.adresEditDiaog = true;
    },
    arkaPlan1() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("brosur20")
        .doc("asil")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan2() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("2")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan3() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("3")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan4() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("4")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan5() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("5")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan6() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("6")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    wrapperHeight() {},
    fiyatVisibility() {
      this.fiyatAlani = !this.fiyatAlani;
      if (this.fiyatAlani == true) {
        this.urunFiyatButon = "Fiyat Kaldır";
      } else {
        this.urunFiyatButon = "Fiyat Ekle";
      }
      this.seciliUrun.fiyatAlan1 = !this.seciliUrun.fiyatAlan1;
      this.seciliUrun.fiyatAlan2 = !this.seciliUrun.fiyatAlan2;
      this.seciliUrun.fiyatAlan3 = !this.seciliUrun.fiyatAlan3;
      this.seciliUrun.fiyatAlan4 = !this.seciliUrun.fiyatAlan4;
    },
    deleteProductUrunResmi1() {
      this.selectedProduct1 = false;
    },
    deleteProductUrunResmi2() {
      this.selectedProduct2 = false;
    },
    deleteProductUrunResmi3() {
      this.selectedProduct3 = false;
    },
    deleteProductUrunResmi5() {
      this.selectedProduct5 = false;
    },
    deleteProductUrunResmi6() {
      this.selectedProduct6 = false;
    },
    deleteProductUrunResmi7() {
      this.selectedProduct7 = false;
    },
    deleteProductUrunResmi8() {
      this.selectedProduct8 = false;
    },
    deleteProductUrunResmi9() {
      this.selectedProduct9 = false;
    },
    deleteProductUrunResmi4() {
      this.selectedProduct4 = false;
    },
    selectProduct1() {
      this.selectedProductDialog = true;
      this.urun1 = true;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
    },
    selectProduct2() {
      this.selectedProductDialog = true;
      this.urun2 = true;
      this.urun1 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
    },
    selectProduct3() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = true;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
    },
    selectProduct4() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = true;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
    },
    selectProduct5() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = true;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
    },
    selectProduct6() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = true;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
    },
    selectProduct7() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = true;
      this.urun8 = false;
      this.urun9 = false;
    },
    selectProduct8() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = true;
      this.urun9 = false;
    },
    selectProduct9() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = true;
    },

    getAdresBilgi() {
      db.collection("adres")
        .doc("adres")
        .onSnapshot((doc) => {
          //this.brosurTitle = doc.data().brosurTitle;
          this.adres = {
            alan1: doc.data().alan1,
            alan8: "NFS SOFT MOBİLYA",
            webAdresi: doc.data().webAdresi,
          };
        });
    },
    adresKaydet() {
      this.adresEditDiaog = false;
      this.editTitleDialog = false;
      this.yaziAlaniDialog2 = false;
      this.yaziSloganDialog = false;
      this.snackbar.status = true;
      this.snackbar.color = "green darken-3";
      this.snackbar.text = "Bilgileriniz Kaydedildi";
      db.collection("adres")
        .doc("adres")
        .update({
          alan1: this.adres.alan1,
          // alan8: this.adres.alan8,
          // brosurTitle: this.brosurTitle,
          webAdresi: this.adres.webAdresi,
        })
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },
    deleteProductUrunResmi() {
      this.logoResim = false;
      this.logoButon = true;
    },
    uploadLogo(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingLogo = true;
        this.logo.fileName = file.name;
        var storageRef = fb.storage().ref("logo/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.logo.resim = downloadURL;
              let vm = this;
              db.collection("logo")
                .doc("logo")
                .set({
                  resim: vm.logo.resim,
                  fileName: vm.logo.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingLogo = false;
                  vm.logoButon = false;
                  vm.logoResim = true;
                  db.collection("logo")
                    .doc("logo")
                    .onSnapshot((doc) => {
                      let fileName = "";
                      fetch(doc.data().resim)
                        .then((r) => {
                          return r.blob();
                        })
                        .then((blobFile) => {
                          fileName = window.URL.createObjectURL(
                            new File([blobFile], { type: "image/jpeg" })
                          );
                          vm.logo.fileName = fileName;
                        });
                    });
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    /* Boyutlar */

    updateBackgroundImage(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingBgImage = true;
        this.brosurBackground.fileName = file.name;
        var storageRef = fb.storage().ref("arka-plan/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.brosurBackground.resim = downloadURL;
              let vm = this;
              db.collection("arka_plan")
                .doc("resim")
                .collection("brosur20")
                .doc("brosur20")
                .update({
                  resim: vm.brosurBackground.resim,
                  fileName: vm.brosurBackground.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingBgImage = false;
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    getBackround() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("brosur20")
        .doc("asil")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    changeColorAcilisYazi: function (event) {
      this.adresYaziRenk.acilis = event.target.value;
    },
    changeFontAcilisYazi: function (event) {
      this.adresYaziBoyutu.acilis = event.target.value + "px";
    },
    changeColorAdresAlan10: function (event) {
      this.adresYaziRenk.alan10 = event.target.value;
    },
    changeFontAdresAlan10: function (event) {
      this.adresYaziBoyutu.alan10 = event.target.value + "px";
    },
    changeColorAdresAlan9: function (event) {
      this.adresYaziRenk.alan9 = event.target.value;
    },
    changeFontAdresAlan9: function (event) {
      if (event.target.value < 39) {
        this.adresYaziBoyutu.alan9 = event.target.value + "px";
      } else {
        this.adresYaziBoyutu.alan9 = "39px";
      }
    },
    changeColorAdresAlan8: function (event) {
      this.adresYaziRenk.alan8 = event.target.value;
    },
    changeFontAdresAlan8: function (event) {
      this.adresYaziBoyutu.alan8 = event.target.value + "px";
    },
    changeFontAdresAlan1: function (event) {
      this.adresYaziBoyutu.webAdresi = event.target.value + "px";
    },
    changeColorAdresAlan1: function (event) {
      this.adresYaziRenk.webAdresi = event.target.value;
    },
    changeFontALtBaslik: function (event) {
      this.altBaslikFontBoyut = event.target.value + "px";
    },
    changeColorAltBaslik: function (event) {
      this.altBalikRenk = event.target.value;
    },
    changeFontSize: function (event) {
      if (event.target.value < 15) {
        this.fontSize = event.target.value + "px";
      } else {
        this.fontSize = "15px";
      }
    },
    changeColor: function (event) {
      this.color = event.target.value;
    },
    brosurA1() {
      this.brosurDialog = !this.brosurDialog;
    },
    brosurA2() {
      this.brosurDialogA2 = !this.brosurDialogA2;
    },
    async print() {
      const el = this.$refs.capture;
      const options = {
        type: "dataURL",
      };
      this.output = await this.$html2canvas(el, options);
      var link = document.createElement("a");
      link.download = "brosur.png";
      link.href = this.output;
      document.body.appendChild(link);
      link.click();
    },
    makePDF() {
      this.loaderBrosur = true;
      const self = this;
      var canvas = this.$refs.capture;
      html2canvas(canvas, { type: "dataURL" }).then(function (canvas) {
        // var myImage = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
        // var link = document.createElement('a')
        // link.download = 's.png'
        // link.href = myImage
        // document.body.appendChild(link)
        // link.click()

        self.print();
      });
      setTimeout(() => {
        this.loaderBrosur = false;
      }, 1000);
    },

    newMusteriLink() {
      this.$router.push({ path: `/customer-login/${this.$route.params.id}` });
    },
    imageShowDelete() {
      this.imageShow = false;
    },
    deleteProduct(product) {
      console.log(product);
      this.selectedProducts.splice(product, 1);
    },
    deleteAdresItem(index) {
      this.adres.splice(index, 1);
    },

    addItem() {
      if (this.brosurUrun.urunAdi !== "") {
        this.list.push({
          urunAdi: this.brosurUrun.urunAdi,
          urunFiyat: this.brosurUrun.urunFiyat,
          bosluk: this.brosurUrun.bosluk,
          paraBirimi: this.brosurUrun.paraBirimi,
        });
        this.brosurUrun = {
          urunAdi: "",
          urunFiyat: "",
          bosluk: "  ...............................  ",
          paraBirimi: "",
        };
      }
    },
    deleteItem(index) {
      this.list.splice(index, 1);
    },
    clearProduct() {
      this.product = {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      };
    },
    updateUrunImage(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingUrunImage = true;
        this.product.fileName = file.name;
        var storageRef = fb.storage().ref("images/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.resim = downloadURL;
              let vm = this;
              db.collection("urun")
                .doc(vm.product.docName)
                .update({
                  resim: vm.product.resim,
                  fileName: vm.product.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingUrunImage = false;
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    imageUrunUpload(e) {
      let file = e;
      this.product.fileName = file.name;
      var storageRef = fb.storage().ref("images/" + file.name);
      let uploadTask = storageRef.put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        function (error) {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.product.resim = downloadURL;
            console.log("File available at", downloadURL);
            this.disabled = false;
          });
        }
      );
    },
    getUrunler() {
      db.collection("brosur")
              .doc("brosur").collection("urun")
        .onSnapshot((querySnapshot) => {
          this.urunler = [];
          let fileName = "";
          querySnapshot.forEach((doc) => {
            fetch(doc.data().resim)
              .then((r) => {
                return r.blob();
              })
              .then((blobFile) => {
                fileName = window.URL.createObjectURL(
                  new File([blobFile], { type: "image/jpeg" })
                );
                // this.blbUrl = fileName
                this.urunler.push({
                  docName: doc.id,
                  ...doc.data(),
                  blobUrl: fileName,
                });
              });
          });
        });
    },
    deleteUrun(product) {
      let vm = this;
      vm.product.docName = product.docName;
      if (confirm("Ürünü Silmek İstediğinize emin misiniz?")) {
        db.collection("urun")
          .doc(vm.product.docName)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true;
            vm.snackbar.color = "red";
            vm.snackbar.text = "Ürün Silindi";
            //vm.clearProduct()
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    },
    editUrunList() {
      this.urunListDialog = true;
    },
    generatePdf() {},
    downloadBrosur() {},
    editTitle() {
      this.baslikShow = true;
      this.altbaslikShow = false;
      this.editTitleDialog = true;
    },
    editAltBaslik() {
      this.baslikShow = false;
      this.altbaslikShow = true;
      this.editTitleDialog = true;
    },
    newBrosurClose() {
      this.brosurSecimDialog = false;
    },
    newBrosur() {
      //this.boyutA4Export();
      this.brosurDialog = !this.brosurDialog;
      //if (this.selectedProducts.length > 1) {
      //  this.brosurDialogBoyut = 1200
      // }
    },
    saveProduct() {
      this.loader = true;
      this.urunAdiEkle();
      db.collection("urun")
        .doc(this.product.docName)
        .set({
          id: this.product.id,
          fiyat: this.product.fiyat,
          urunadi: this.product.urunadi,
          urunaciklama: this.product.urunaciklama,
          resim: this.product.resim,
          active: true,
          activeDurum: "Aktif",
          fileName: this.product.fileName,
          paraBirimi: this.product.paraBirimi,
        })
        .then((docRef) => {
          console.log("Document written with ID", docRef);
          this.loader = false;
          this.urunDialog = false;
          this.snackbar.status = true;
          this.snackbar.color = "green darken-3";
          this.snackbar.text = "Yeni Ürün Eklendi";
          //this.urunleriGoster(this.product)
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    selectedProduct(product) {
      this.selectedProducts.push(product);
      if (this.urun1 == true) {
        this.selectedProduct1 = true;
        //this.urun2 = false
      } else if (this.urun2 == true) {
        this.selectedProduct2 = true;
      } else if (this.urun3 == true) {
        this.selectedProduct3 = true;
      } else if (this.urun4 == true) {
        this.selectedProduct4 = true;
      } else if (this.urun5 == true) {
        this.selectedProduct5 = true;
      } else if (this.urun6 == true) {
        this.selectedProduct6 = true;
      } else if (this.urun7 == true) {
        this.selectedProduct7 = true;
      } else if (this.urun8 == true) {
        this.selectedProduct8 = true;
      } else if (this.urun9 == true) {
        this.selectedProduct9 = true;
      }
    },
    newProduct() {
      this.clearProduct();
      this.title = "Yeni Ürün";
      this.urunDialog = !this.urunDialog;
    },
    editProduct(product) {
      this.title = "Ürün Güncelleme";
      this.product = product;
      this.editUrunDialog = !this.editUrunDialog;
      // console.log(this.product)
    },
    updateProduct() {
      let vm = this;
      vm.updateLoader = true;
      db.collection("urun")
        .doc(vm.product.docName)
        .update({
          urunadi: vm.product.urunadi,
          urunaciklama: vm.product.urunaciklama,
          fiyat: vm.product.fiyat,
          paraBirimi: vm.product.paraBirimi,
        })
        .then(function () {
          console.log("Document successfully updated!");
          vm.updateLoader = false;
          vm.snackbar.status = true;
          vm.snackbar.color = "sari";
          vm.snackbar.text = "Ürün Bilgileri Güncellendi";
          vm.editUrunDialog = !vm.editUrunDialog;
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },

    clearProduct() {
      this.product = {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      };
    },
    urunAdiEkle() {
      var charMap = {
        Ç: "C",
        Ö: "O",
        Ş: "S",
        İ: "I",
        I: "i",
        Ü: "U",
        Ğ: "G",
        ç: "c",
        ö: "o",
        ş: "s",
        ı: "i",
        ü: "u",
        ğ: "g",
      };
      var str = this.product.urunadi;
      var str_array = str.split("");
      for (var i = 0, len = str_array.length; i < len; i++) {
        str_array[i] = charMap[str_array[i]] || str_array[i];
      }
      str = str_array.join("");

      var clearStr = str
        .replace(" ", "")
        .replace("-", "")
        .replace(/[^a-z0-9-.çöşüğı]/gi, "");
      this.product.docName = clearStr;
      //var res = new Array();
      //res = this.kelime.split(" ");
      //var sonuc = res.join("");
      //console.log(sonuc);
    },
  },

  computed: {
    filteredList() {
      if (this.searchKey) {
        return this.urunler.filter((urun) => {
          return urun.urunadi
            .toLowerCase()
            .startsWith(this.searchKey.toLowerCase());
        });
      } else {
        return this.urunler;
      }
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}

.brosurA4 {
  margin-top: 10px;
}
.selectedProductUrunAdiA4 {
  color: white;
  margin-top: 5px;
  margin-left: 5px;
}
.selectedProductUrunAdiA5 {
  color: white;
  margin-top: 3px;
  margin-left: 3px;
}
.selectedProductFiyatTextUrun1A4 {
  margin-top: 35px;
  margin-left: 30px;
  font-weight: 900;
  font-size: 45px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}

.selectedProductFiyatTextUrun1A5 {
  margin-top: 35px;
  margin-left: 30px;
  font-weight: 900;
  font-size: 45px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.selectedProductFiyatTextA4 {
  margin-top: 35px;
  margin-left: 33px;
  font-weight: 900;
  font-size: 25px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.selectedProductFiyatTextA5 {
  margin-top: 25px;
  margin-left: 22px;
  font-weight: 900;
  font-size: 22px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.turkLirasiUrun1 {
  font-size: 30px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  font-weight: 900;
}

.cubukA4 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.cubukA5 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.brosurBackground {
  position: "relative";
}
.brosurTitle {
  margin-top: 8px;
  font-weight: bolder;
}

@media screen and (min-width: 601px) {
  .alGoturToGo {
    margin-left: 20px;
    margin-top: 70px;
  }
  .urunListBosluk {
    margin-top: 50px;
  }
  .urunListFiyat {
    margin-right: 10px;
  }
  .yesil {
    background-color: #ffe414;
    float: left;
    margin-left: 80px;
    margin-top: 20px;
  }
  .selectedProductFiyatAvatar {
    margin-top: 200px;
    margin-left: -17px;
  }
  .selectedProductFiyatAvatarA2 {
    margin-top: 100px;
    margin-left: -10px;
  }

  .selectedProductFiyatText {
    margin-top: 25px;
    margin-left: 18px;
    font-weight: 900;
    font-size: 30px;
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  }
  .turkLirasi {
    font-size: 20px;
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
    font-weight: 900;
  }

  .editAdres {
    margin-bottom: 15px;
  }
  .editAdresA2 {
    margin-bottom: 15px;
  }
  .ambalaj {
    font-size: small;
    font-weight: bolder;
    margin-left: 5px;
  }
  .productImage {
    height: 250px;
  }
  .productImageRow {
    height: 30px;
  }
}

@media (min-width: 300px) and (max-width: 601px) {
  .selectedProductImage {
    height: 150px;
  }
  .selectedProductUrunadi {
    font-size: x-small;
    margin-bottom: 6px;
  }
  .selectedProductUrunAciklama {
    font-size: 8px;
    margin-top: -16px;
  }
  .selectedProductFiyatAvatar {
    width: 65px;
    height: 65px;
    margin-top: 42px;
    margin-left: -7px;
  }
  .selectedProductFiyatText {
    font-size: 11px;
    font-weight: bolder;
    margin-top: 25px;
    margin-left: 11px;
  }
  .brosurTitle {
    font-weight: bolder;
  }
  .selectedAdresTextMedium {
    font-size: x-small;
    font-weight: bolder;
  }
  .selectedAdresTextSmall {
    font-size: xx-small;
    margin-bottom: 0px;
  }
  .yesil {
    background-color: #e3dd2f;
  }
  .bosluk {
    margin-top: 0px;
    margin-bottom: -13px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .bosluk8 {
    margin-top: 0px;
    margin-bottom: -10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .urunListFiyat {
    margin-right: 3px;
  }
  .alGoturToGo {
    font-size: 9px;
    font-weight: bolder;
  }
  .urunListBosluk {
    margin-top: 20px;
  }
  .editAdres {
    margin-bottom: 3px;
  }
  .alGoturCard {
    height: 150px;
    width: 250;
  }
  .ambalaj {
    font-size: xx-small;
    font-weight: bolder;
    margin-top: -15px;
    margin-left: 1px;
  }
  .alGoturToGoText {
    font-size: xx-small;
    font-weight: bolder;
  }
  .alGoturToGoNokta {
    font-size: 5px;
    font-weight: bolder;
  }
  .adresAlanMedium {
    font-size: 7px;
    font-weight: bolder;
  }
  .adresAlanSmall {
    font-size: 7px;
    font-weight: medium;
  }
  .adresAlanBold {
    font-size: 10px;
    font-weight: bolder;
  }
  .brosurTitle8 {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: bolder;
  }
  .brosurBtn {
    font-size: small;
    margin-right: 45px;
  }
  .productImage {
    height: 150px;
  }
  .productImageRow {
    height: 15px;
  }
  .productUrunadi {
    font-size: small;
  }
  .productFiyat {
    font-size: large;
  }
  .productActive {
    font-size: xx-small;
  }
  .productSwitch {
    font-size: xx-small;
  }
  .opacity-0 {
    opacity: 0 !important;
  }
}
</style>
