<template>
  <div>
    <v-row>
      <v-col cols="11" class="mx-auto">
        <v-row>
         <v-col cols="12">
            <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Mobilya Broşürleri
                </v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="3">
                  <sablon2></sablon2>
                </v-col>
                <v-col cols="3">
                  <sablon13></sablon13>
                </v-col>
                <v-col cols="3">
                  <sablon14></sablon14>
                </v-col>
                <v-col cols="3">
                  <sablon15></sablon15>
                </v-col>
                <v-col cols="3">
                  <sablon16></sablon16>
                </v-col>
                <v-col cols="3">
                  <sablon17></sablon17>
                </v-col>
                <v-col cols="3">
                  <sablon18></sablon18>
                </v-col>
                <v-col cols="3">
                  <sablon19></sablon19>
                </v-col>
                <v-col cols="3">
                  <sablon20></sablon20>
                </v-col>
                <v-col cols="3">
                  <sablon21></sablon21>
                </v-col>
                <v-col cols="3">
                  <sablon36></sablon36>
                </v-col>
                <v-col cols="3">
                  <sablon37></sablon37>
                </v-col>
                <v-col cols="3">
                  <sablon38></sablon38>
                </v-col>
                <v-col cols="3">
                  <sablon39></sablon39>
                </v-col>
                <!--
                  <v-col cols="3">
                  <sablon40></sablon40>
                </v-col>
                <v-col cols="3">
                  <sablon41></sablon41>
                </v-col>
                <v-col cols="3">
                  <sablon42></sablon42>
                </v-col>
                 -->
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Market Broşürleri
                </v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                 <!--

                    <v-col cols="3">
                  <sablon1></sablon1>
                </v-col>
                  -->
                
                <v-col cols="3">
                  <sablon4></sablon4>
                </v-col>
                <v-col cols="3">
                  <sablon5></sablon5>
                </v-col>
                <v-col cols="3">
                  <sablon6></sablon6>
                </v-col>
                <v-col cols="3">
                  <sablon7></sablon7>
                </v-col>
                <v-col cols="3">
                  <sablon8></sablon8>
                </v-col>
                <v-col cols="3">
                  <sablon9></sablon9>
                </v-col>
                <v-col cols="3">
                  <sablon10></sablon10>
                </v-col>
                <v-col cols="3">
                  <sablon11></sablon11>
                </v-col>
                <v-col cols="3">
                  <sablon12></sablon12>
                </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Otel & Spa Broşürleri
                </v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="3">
                  <sablon23></sablon23>
                </v-col>
                
                <v-col cols="3">
                  <sablon24></sablon24>
                </v-col>
                <v-col cols="3">
                  <sablon25></sablon25>
                </v-col>
                <v-col cols="3">
                  <sablon26></sablon26>
                </v-col>
                <v-col cols="3">
                  <sablon27></sablon27>
                </v-col>
                 <v-col cols="3">
                  <sablon28></sablon28>
                </v-col>
                 <v-col cols="3">
                  <sablon29></sablon29>
                </v-col>
                 <v-col cols="3">
                  <sablon30></sablon30>
                </v-col>
                <v-col cols="3">
                  <sablon31></sablon31>
                </v-col>
                <v-col cols="3">
                  <sablon32></sablon32>
                </v-col>
                <v-col cols="3">
                  <sablon33></sablon33>
                </v-col>
                <v-col cols="3">
                  <sablon34></sablon34>
                </v-col>
                
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Restoran & Cafe Broşürleri
                </v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                 <!--

                    <v-col cols="3">
                  <sablon1></sablon1>
                </v-col>
                  -->
                
                <v-col cols="3">
                  <sablon43></sablon43>
                </v-col>
                <v-col cols="3">
                  <sablon44></sablon44>
                </v-col> 
                <v-col cols="3">
                  <sablon45></sablon45>
                </v-col> 
                <v-col cols="3">
                  <sablon46></sablon46>
                </v-col> 
                <v-col cols="3">
                  <sablon47></sablon47>
                </v-col> 
                <v-col cols="3">
                  <sablon48></sablon48>
                </v-col>               
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
           <!-- 
              <v-expansion-panel>
              <v-expansion-panel-header
                >Restaurant Broşürleri
                </v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                
              
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

           -->
          </v-expansion-panels>
         </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { fb, db } from "../../firebase/fb";
import Sablon1 from "./sablon1.vue";
import Sablon2 from "./sablon2.vue";
import Sablon3 from "./sablon3.vue";
import Sablon4 from "./sablon4.vue";
import Sablon5 from "./sablon5.vue";
import Sablon6 from "./sablon6.vue";
import Sablon7 from "./sablon7.vue";
import Sablon8 from "./sablon8.vue";
import Sablon9 from "./sablon9.vue";
import Sablon10 from "./sablon10.vue";
import Sablon11 from "./sablon11.vue";
import Sablon12 from "./sablon12.vue";
import Sablon13 from "./sablon13.vue";
import Sablon14 from "./sablon14.vue";
import Sablon15 from "./sablon15.vue";
import Sablon16 from "./sablon16.vue";
import Sablon17 from "./sablon17.vue";
import Sablon18 from "./sablon18.vue";
import Sablon19 from "./sablon19.vue";
import Sablon20 from "./sablon20.vue";
import Sablon21 from "./sablon21.vue";
import Sablon22 from "./sablon22.vue";
import Sablon23 from "./sablon23.vue";
import Sablon24 from "./sablon24.vue";
import Sablon25 from "./sablon25.vue";
import Sablon26 from "./sablon26.vue";
import Sablon27 from "./sablon27.vue";
import Sablon28 from "./sablon28.vue";
import Sablon29 from "./sablon29.vue";
import Sablon30 from "./sablon30.vue";
import Sablon31 from "./sablon31.vue";
import Sablon32 from "./sablon32.vue";
import Sablon33 from "./sablon33.vue";
import Sablon34 from "./sablon34.vue";
import Sablon35 from "./sablon35.vue";
import Sablon36 from "./sablon36.vue";
import Sablon37 from "./sablon37.vue";
import Sablon38 from "./sablon38.vue";
import Sablon39 from "./sablon39.vue";
import Sablon40 from "./sablon40.vue";
import Sablon41 from "./sablon41.vue";
import Sablon42 from "./sablon42.vue";
import Sablon43 from "./sablon43.vue";
import Sablon44 from "./sablon44.vue";
import Sablon45 from "./sablon45.vue";
import Sablon46 from "./sablon46.vue";
import Sablon47 from "./sablon47.vue";
import Sablon48 from "./sablon48.vue";
export default {
  data() {
    return {
      secilenSablonDialog: false,
      opacity: 1,
      absolute: true,
      overlay: false,
      sablonDialog: false,
      interval: {},
      value: 0,
    };
  },
  components: {
    sablon1: Sablon1,
    sablon2: Sablon2,
    sablon3: Sablon3,
    sablon4: Sablon4,
    sablon5: Sablon5,
    sablon6: Sablon6,
    sablon7: Sablon7,
    sablon8: Sablon8,
    sablon9: Sablon9,
    sablon10: Sablon10,
    sablon11: Sablon11,
    sablon12: Sablon12,
    sablon13: Sablon13,
    sablon14: Sablon14,
    sablon15: Sablon15,
    sablon16: Sablon16,
    sablon17: Sablon17,
    sablon18: Sablon18,
    sablon19: Sablon19,
    sablon20: Sablon20,
    sablon21: Sablon21,
    sablon22: Sablon22,
    sablon23: Sablon23,
    sablon24: Sablon24,
    sablon25: Sablon25,
    sablon26: Sablon26,
    sablon27: Sablon27,
    sablon28: Sablon28,
    sablon29: Sablon29,
    sablon30: Sablon30,
    sablon31: Sablon31,
    sablon32: Sablon32,
    sablon33: Sablon33,
    sablon34: Sablon34,
    sablon35: Sablon35,
    sablon36: Sablon36,
    sablon37: Sablon37,
    sablon38: Sablon38,
    sablon39: Sablon39,
    sablon40: Sablon40,
    sablon41: Sablon41,
    sablon42: Sablon42,
    sablon43: Sablon43,
    Sablon44: Sablon44,
    sablon45: Sablon45,
    sablon46: Sablon46,
    sablon47: Sablon47,
    sablon48: Sablon48,
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  mounted() {
    this.overlay = true;
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 2000);
    },
  },
  methods: {},
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}
.v-progress-circular {
  margin: 1rem;
}
</style>