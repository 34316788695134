<template>
  <v-app id="sandbox">
    <v-navigation-drawer v-model="drawer" app color="grey darken-4" dark>
      <v-list></v-list>
      <v-list rounded>
        <template>
          <v-list-item link :to="'/users/' + $route.params.id + '/urunler'">
            <v-list-item-action>
              <v-icon>dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Ürünler
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="'/users/' + $route.params.id + '/logo'">
            <v-list-item-action>
              <v-icon>list</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Logo
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="'/users/' + $route.params.id + '/sablonlar'">
            <v-list-item-action>
              <v-icon>all_inclusive</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Şablonlar
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="'/users/' + $route.params.id + '/sistem-hakkinda'">
            <v-list-item-action>
              <v-icon>settings_applications</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Sistem Hakkında
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="'/'">
            <v-list-item-action>
              <v-icon>power_settings_new</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Çıkış Yap
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app flat color="#EEEEEE">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" /> 
       <!--
         <v-icon
      class="mr-2"
      @click="$router.go(-1)"
    >
      mdi-keyboard-backspace
    </v-icon>
       -->
      <v-toolbar-title>{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      

    </v-app-bar>

    <v-content class="grey lighten-3">
      <v-container fluid color="#EEEEEE">
        <router-view></router-view>
      </v-container>
    </v-content>

    <v-footer :inset="footer.inset" color="#EEEEEE" app>
      <v-spacer></v-spacer>
      <span class="px-4">&copy; {{ new Date().getFullYear() }} by Bilmar Bilişim Teknolojileri Ltd Şti.</span>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    title:'Ürünler',
    footer: {
      inset: true,
    },
    items: [
      {
        icon: 'dashboard',
        text: 'Ürünler',
        route: '/users/urunler',
      },
     {
        icon: ' all_inclusive',
        text: 'Logo',
        route: '/users/logo',
      },
      {
        icon: ' all_inclusive',
        text: 'Şablonlar',
        route: '/users/sablonlar',
      },

      {
        icon: ' wysiwyg',
        text: 'Sosyal Medya Şablonları',
        route: '/users/sosyal-medya',
      },

      /**
       {
        icon: 'wysiwyg',
        text: 'Lisans',
        route: '/home/lisans',
      },
       */
      {
        icon: 'settings_applications',
        text: 'Sistem Hakkında',
        route: '/users/sistem-hakkinda',
      },
      {
        icon: 'power_settings_new',
        text: 'Çıkış Yap',
        route: '/',
      },
      /**
      {
        icon: 'fas fa-bars',
        text: 'Menü',
        route: '/menu',
      },
     */
    ],
  }),
  methods:{
     cikisYap() {
      setTimeout(() => {
        this.$router.push('/')
      }, 1000)
    },
    titleTextView(item){
      this.title = item
    }
  },

}
</script>
