<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row class="d-flex justify-end">
          <v-col cols="2" class="mt-n7">
            <v-card-title class="headline">
              <v-spacer></v-spacer>
              <div id="editor"></div>
              <v-btn
                id="cmd"
                tile
                color="success"
                class="white--text"
                @click="makePDF"
                :loading="loaderBrosur"
              >
                İNDİR
                <v-icon right dark> mdi-cloud-download </v-icon>
              </v-btn>
            </v-card-title>
          </v-col>
          <v-col cols="2" class="mt-n3">
            <v-btn
              id="cmd"
              tile
              color="indigo"
              class="white--text"
              outlined
              @click="deleteProductUrunResmi1"
            >
              Yeni Resim
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text class="d-flex justify-center" id="test_1">
          <div id="capture" ref="capture">
            <v-card width="600" height="700" :color="brosurBackgroundColor">
              <v-col
                style="
                  z-index: 2;
                  position: absolute;
                  height: 535px;
                  border: 10px solid #666666;
                  border-radius: 0% 0% 50% 50%;
                "
                class="pa-0 ma-0"
                cols="12"
              ></v-col>

              <v-col cols="12" v-if="selectedProduct1">
                <v-img
                  class="mt-n3 ml-n3"
                  style="
                    border-radius: 0% 0% 50% 50%;
                    z-index: 3;
                    position: absolute;
                  "
                  :src="seciliUrun.product1"
                  height="526"
                  width="600"
                >
                  <a>
                    <vue-draggable-resizable w="550" h="60">
                      <v-row
                        class="d-flex justify-center"
                        @dblclick="editAdress"
                      >
                        <v-col cols="12" class="mt-6 text-center">
                          <h1
                            :style="{
                              'font-size': yaziAciklamaBoyut,
                              color: yaziAciklamaRenk,
                            }"
                          >
                            {{ yaziAciklamaAlani }}
                          </h1>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center mt-n10">
                        <v-col cols="12" class="mt-6 text-center">
                          <h1
                            class="mt-9"
                            :style="{
                              'font-size': adresYaziBoyutu.alan10,
                              color: adresYaziRenk.alan10,
                            }"
                            style="
                              font-family: Brush Script MT, Brush Script Std;
                            "
                          >
                            {{ yaziSlogan2 }}
                          </h1>
                        </v-col>
                      </v-row>
                    </vue-draggable-resizable>
                  </a>

                  <v-card
                    color="rgba(100, 100, 100, 0.0)"
                    style="
                      margin-top: -160px;
                      z-index: 4;
                      position: absolute;
                      border: 6px solid #cbc9c9;
                      border-radius: 100%;
                    "
                    height="600"
                    width="700"
                  ></v-card>
                </v-img>
              </v-col>

              <v-col cols="12" class="ml-n3 " v-else>
                <v-card color="white"  style="z-index: 3;position:absolute;border-radius: 0% 0% 50% 50%;" class="pa-1 mt-n5">
                  <v-img height="526px" width="600px">
                    <p
                      class="font-weight-bold pl-1"
                      style="font-size: 10px; color: white"
                    >
                      1
                    </p>
                    <v-row style="height: 155px">
                      <v-col cols="9" class="pa-3 mt-n5">
                        <div
                          class="
                            d-flex
                            transition-fast-in-fast-out
                            darken-1
                            v-card--reveal
                            display-3
                            white--text
                          "
                          style="height: 100%"
                        >
                          <v-row justify="space-around">
                            <span class="group pa-2">
                              <v-btn
                                icon
                                color="indigo darken-4"
                                :loading="loadingLogo"
                                @click="selectProduct1"
                              >
                                <v-icon x-large dark>mdi-plus</v-icon>
                              </v-btn>
                            </span>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-img>
                </v-card>
              </v-col>

              <v-card
                class="ml-8"
                style="
                  z-index: 1;
                  position: absolute;
                  border: 4px solid #0c59ad;
                "
                :color="brosurBackgroundColor"
                height="640"
                width="530"
              >
                <a>
                  <vue-draggable-resizable w="550" h="60">
                    <v-card
                      @dblclick="editYaziAlani"
                      :color="cardArkaPlan1"
                      height="50"
                      width="150"
                      style="margin-top: 515px; margin-left: 190px"
                    >
                      <h3
                        class="pa-3"
                        style="text-align: center; color: #ffffff"
                        :style="{
                          'font-size': adresYaziBoyutu.alan12,
                          color: adresYaziRenk.alan12,
                        }"
                      >
                        {{ alan12 }}
                      </h3>
                    </v-card>
                  </vue-draggable-resizable>
                </a>

                <v-row>
                  <v-col cols="12">
                    <a>
                      <vue-draggable-resizable w="550" h="60">
                        <h1
                          @dblclick="editYaziAlani4"
                          style="margin-top: 10px; margin-left: 160px"
                          :style="{
                            'font-size': adresYaziBoyutu.alan11,
                            color: adresYaziRenk.alan11,
                          }"
                        >
                          {{ alan11 }}
                        </h1>
                      </vue-draggable-resizable>
                    </a>
                  </v-col>
                </v-row>

                <v-row class="mt-n16">
                  <v-col cols="12">
                    <a>
                      <vue-draggable-resizable w="550" h="60">
                        <p
                          @dblclick="edityaziAciklama"
                          style="margin-left: 170px; margin-top: 5px"
                          :style="{
                            'font-size': adresYaziBoyutu.webAdresi,
                            color: adresYaziRenk.webAdresi,
                          }"
                        >
                          {{ adres.webAdresi }}
                        </p>
                      </vue-draggable-resizable>
                    </a>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
          </div>
        </v-card-text>
        <v-card-text class="d-flex justify-center mt-3">
          <v-row>
            <v-col offset="7" cols="4">
              <v-label>
                <span>Arka Plan Rengi</span>
                <input
                  type="color"
                  id="favcolor"
                  name="favcolor"
                  value="#ff0000"
                  @change="updateBackgroundColor"
                />
              </v-label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row dense>
      <v-snackbar
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        center
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-row>
    <v-dialog v-model="editTitleDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Başlığı Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="editTitleDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="brosurTitle"
                    label="Yeni Başlık"
                    color="sari"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6">Yazı Boyutu</v-col>
                <v-col cols="6">
                  <input type="number" value="50" @input="changeFontSize" />
                </v-col>
                <v-col cols="6"><label>Yazı Rengi</label></v-col>
                <v-col cols="6">
                  <input
                    id="color-choos"
                    type="color"
                    name="color"
                    value="#ffffff"
                    @input="changeColor"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="adresKaydet()"
            :loading="adresButon"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--let's go adventure-->
    <v-dialog v-model="adresEditDiaog" max-width="800">
      <v-card>
        <v-card-title class="headline">
          Bilgileri Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="adresEditDiaog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                    label="Web Adresi"
                    v-model="yaziAciklamaAlani"
                    color="sari"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="6">Yazı Boyutu</v-col>
                    <v-col cols="6">
                      <input
                        type="number"
                        value="45"
                        @input="changeFontAlanAciklama"
                      />
                    </v-col>
                    <v-col cols="6"><label>Yazı Rengi</label></v-col>
                    <v-col cols="6">
                      <input
                        id="color-choos"
                        type="color"
                        name="color"
                        value="#000000"
                        @input="changeColorAciklama"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    label="Telefon Numarası"
                    v-model="yaziSlogan2"
                    color="sari"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="6">Yazı Boyutu</v-col>
                    <v-col cols="6">
                      <input
                        type="number"
                        value="90"
                        @input="changeFontAdresAlan10"
                      />
                    </v-col>
                    <v-col cols="6"><label>Yazı Rengi</label></v-col>
                    <v-col cols="6">
                      <input
                        id="color-choos"
                        type="color"
                        name="color"
                        value="#000000 "
                        @input="changeColorAdresAlan10"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            dark
            @click="adresKaydet()"
            :loading="adresButon"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <!-- A1 Broşür-->
      <v-dialog v-model="yaziSloganDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">
            Yazı Alanını Düzenle
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="yaziSloganDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="yaziSlogan1"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">Yazı Boyutu</v-col>
                  <v-col cols="6">
                    <input
                      type="number"
                      value="20"
                      @input="changeFontAdresAlan9"
                    />
                  </v-col>
                  <v-col cols="6"><label>Yazı Rengi</label></v-col>
                  <v-col cols="6">
                    <input
                      id="color-choos"
                      type="color"
                      name="color"
                      value="#ffffff"
                      @input="changeColorAdresAlan9"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mr-4 mb-3" color="sari" dark> Kaydet </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--web adresi-->
      <v-dialog v-model="yaziAciklamaDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">
            Yazı Alanını Düzenle
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="yaziAciklamaDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="adres.webAdresi"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">Yazı Boyutu</v-col>
                  <v-col cols="6">
                    <input
                      type="number"
                      value="15"
                      @input="changeFontAdresAlan1"
                    />
                  </v-col>
                  <v-col cols="6"><label>Yazı Rengi</label></v-col>
                  <v-col cols="6">
                    <input
                      id="color-choos"
                      type="color"
                      name="color"
                      value="#000000"
                      @input="changeColorAdresAlan1"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mr-4 mb-3" color="sari" dark> Kaydet </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="urunDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            {{ title }}
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="urunDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      label="Resim"
                      color="sari"
                      @change="imageUrunUpload"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.urunadi"
                      label="Ürün Adı"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="product.urunaciklama"
                      label="Açıklama"
                      prepend-icon="gesture"
                      color="sari"
                      rows="1"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="product.fiyat"
                      label="Fiyat"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      item-text="name"
                      item-value="sembol"
                      :items="paraBirimleri"
                      v-model="product.paraBirimi"
                      prepend-icon="euro"
                      label="Para Birimi"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="sari"
              @click="saveProduct"
              :loading="loader"
              dark
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="selectedProductDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="indigo">
            <v-row>
              <v-col cols="8" offset="4">
                <v-text-field
                  v-model="searchKey"
                  cache-items
                  flat
                  hide-no-data
                  hide-details
                  label="Aramak istediğiniz ürünü ekleyin..."
                  solo-inverted
                ></v-text-field>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="selectedProductDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <!--ürünler -->
          <v-row dense class="mt-2">
            <v-col
              cols="6"
              sm="6"
              md="4"
              lg="3"
              v-for="product in filteredList"
              :key="product.id"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card tile color="grey lighten-4">
                  <v-img
                    :aspect-ratio="16 / 9"
                    class="productImage"
                    :src="product.blobUrl"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="
                          d-flex
                          transition-fast-in-fast-out
                          sari
                          v-card--reveal
                          display-3
                          white--text
                        "
                        style="height: 100%"
                      >
                        <v-btn
                          class="ma-2"
                          tile
                          color="black"
                          dark
                          @click="selectedProduct(product)"
                        >
                          Ekle
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text style="position: relative">
                    <v-row no-gutters class="productImageRow">
                      <v-col cols="12">
                        <v-row no-gutters>
                          <v-col cols="12" class="text-center">
                            <h3 class="text-center productUrunadi">
                              {{ product.urunadi }}
                            </h3>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-1"></v-divider>
                    <v-row no-gutters>
                      <v-col cols="12" class="mt-1">
                        <h2 class="text-center">
                          <strong
                            class="font-weight-black productFiyat text-center"
                            style="font-weight: older"
                          >
                            {{ product.fiyat }} {{ product.paraBirimi }}
                          </strong>
                        </h2>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <!--clik here-->
      <v-dialog v-model="yaziAlaniDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">
            Yazı Alanını Düzenle
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="yaziAlaniDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="alan12"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">Yazı Boyutu</v-col>
                  <v-col cols="6">
                    <input
                      type="number"
                      value="30"
                      @input="changeFontAdresAlan12"
                    />
                  </v-col>
                  <v-col cols="6"><label>Yazı Rengi</label></v-col>
                  <v-col cols="6">
                    <input
                      id="color-choos"
                      type="color"
                      name="color"
                      value="#ffffff"
                      @input="changeColorAdresAlan12"
                    />
                  </v-col>
                  <v-col cols="6"><label>Arka Plan Rengi</label></v-col>
                  <v-col cols="6">
                    <input
                      id="color-choos"
                      type="color"
                      name="color"
                      value="#f68513"
                      @input="changeColorArkaPlan1"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mr-4 mb-3" color="sari" dark> Kaydet </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--free 1 ticket-->
      <v-dialog v-model="yaziAlaniDialog4" max-width="500">
        <v-card>
          <v-card-title class="headline">
            Yazı Alanını Düzenle
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="yaziAlaniDialog4 = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="alan11"
                      prepend-icon="bookmark"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">Yazı Boyutu</v-col>
                  <v-col cols="6">
                    <input
                      type="number"
                      value="30"
                      @input="changeFontAdresAlan11"
                    />
                  </v-col>
                  <v-col cols="6"><label>Yazı Rengi</label></v-col>
                  <v-col cols="6">
                    <input
                      id="color-choos"
                      type="color"
                      name="color"
                      value="#ff8b00"
                      @input="changeColorAdresAlan11"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mr-4 mb-3" color="sari" dark> Kaydet </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
const clamp = (num, lower = 0, upper) => {
  return num < lower ? lower : num > upper ? upper : num;
};

import { fb, db } from "../../firebase/fb";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";
import VueDraggableResizable from "vue-draggable-resizable";
import Brosur1 from "../brosur/brosur1.vue";
import Home from "../dashboard/home.vue";

export default {
  data() {
    return {
      cardArkaPlan1: "#ff8b00",
      yaziAciklamaAlani: "LET'S GO",
      alan11: "FREE 1 TICKET",
      alan12: "CLICK HERE",
      yaziAciklamaDialog: false,
      yaziAciklamaBoyut: "45px",
      yaziAciklamaRenk: "#000000",
      yaziSlogan2: "Adventure",
      yaziAlaniDialog: false,
      yaziAlaniDialog4: false,
      brosurBackgroundColor: "#e8e6e5",
      selectedProduct1: "",
      selectedProduct2: "",
      selectedProduct3: "",
      selectedProduct4: "",
      selectedProduct5: "",
      selectedProduct6: "",
      selectedProduct7: "",
      selectedProduct8: "",
      selectedProduct9: "",
      selectedProduct10: "",
      selectedProduct11: "",
      selectedProduct12: "",
      selectedProduct13: "",
      selectedProduct14: "",
      selectedProduct15: "",
      selectedProduct16: "",
      backgrounds: [],
      urunSayisiDialog: false,
      searchKey: "",
      loadingLogo: false,
      logo: {
        resim: "",
        fileName: "",
      },
      logoButon: true,
      logoResim: false,
      bosluk: true,
      selectedProductFiyatTextUrun1A5: false,
      selectedProductUrunAdiFontSizeUrun1: "30px",
      x: 100,
      y: 100,
      w: 250,
      h: 50,
      min_w: 250,
      min_h: 10,
      max_w: 300,
      max_h: 60,
      windowStyle: {
        width: "400px",
        position: "relative",
      },
      cubukA4Class: true,
      cubukA5Class: false,
      selectedProductFiyatTextA4: true,
      selectedProductFiyatTextA5: false,
      selectedProductUrunAdiFontSize: "20px",
      selectedProductUrunAdiA4: true,
      selectedProductUrunAdiA5: false,
      cubukHeight: "25px",
      brosurA4Class: true,
      brosurA5Class: false,
      adresAlan9Width: "130px",
      adresAlan9Height: "25px",
      isDragging: false,
      dragItemCoords: {
        top: 5,
        left: 5,
      },
      selection: 1,
      brosurBackground: {
        resim: "",
        fileName: "",
      },
      color: "#FF0000",
      fontSize: "45px",
      titleWidth: " 550px",
      updateUrunLoader: false,
      editUrunDialog: false,
      urunDialog: false,
      loadingUrunImage: false,
      brosurSecimDialog: false,
      loaderBrosurA2: false,
      brosurDialogA2: false,
      loaderBrosur: false,
      paraBirimleri: [
        { name: "TL", sembol: "TL" },
        { name: "$", sembol: "$" },
        { name: "£", sembol: "£ " },
        { name: "€", sembol: "€" },
      ],
      imageShow: true,
      id: this.$route.params.id,
      brosurDialogBoyut: 800,
      adresAlani: "",
      blbUrl: null,
      adresEditDiaog: false,
      brosurUrun: {
        urunAdi: "",
        urunFiyat: "",
        bosluk: " ...................... ",
        paraBirimi: "",
      },
      urunListDialog: false,
      renk: "success",
      chk: false,
      editTitleDialog: false,
      brosurTitle: "NFS SOFT",
      checked: false,
      editDialog: false,
      disabled: true,
      products: [],
      snackbar: {
        status: false,
        text: "",
        color: "",
        timeout: 2000,
      },
      title: "",
      dialog: false,
      loader: false,
      updateLoader: false,
      product: {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      },
      loading: false,
      selectedProducts: [],
      brosurDialog: false,
      allUrunlerDialog: false,
      list: [],
      adresYaziRenk: {
        alan1: "#000000",
        alan8: "#063062",
        alan9: "#FF0000",
        alan10: "#000000",
        alan11: "#ff8b00",
        alan12: "#ffffff",
        webAdresi: "#000000",
      },
      adresYaziBoyutu: {
        alan1: "15px",
        alan8: "10px",
        alan9: "55px",
        alan10: "90px",
        alan11: "30px",
        alan12: "20px",
        webAdresi: "15px",
      },
      adres: {
        firmaAdi: "KARDELEN",
        alan1: "Ataüni Teknokent Yakutiye ERZURUM",
        alan8: "448448737363",
        alan9: "448448737363",
        webAdresi: "wwww.kardelenyatak.com",
      },
      urunler: [],
      icecekler: [],
      tatlilar: [],
      bayi: {
        id: null,
        docName: "",
      },
      output: null,
      kategoriler: [],
      updateLoader: false,
      urunFiyatDialog: false,
      loadingBgImage: false,
      cubuk: true,
      brosurBoyutWidth: "1080px",
      brosurBoyutHeight: "1080px",
      selectedProductImage: "",
      productFiyatAvatarMarginTop: "",
      productFiyatAvatarMarginLeft: "",
      selectedProductImageUrun1: "",
      adresButon: false,
      instagram: false,
      facebook: false,
      a4Boyut: true,
      a5boyut: false,
      selectedProductDialog: false,
      seciliUrun: {
        product1: "",
        product2: "",
        product3: "",
        product4: "",
        product5: "",
        product6: "",
        product7: "",
        product8: "",
        product9: "",
        product10: "",
        product11: "",
        product12: "",
        product13: "",
        product14: "",
        product15: "",
        product16: "",
        fiyat1: "",
        fiyat2: "",
        fiyat3: "",
        fiyat4: "",
        fiyat5: "",
        fiyat6: "",
        fiyat7: "",
        fiyat8: "",
        fiyat9: "",
        fiyat10: "",
        fiyat11: "",
        fiyat12: "",
        fiyat13: "",
        fiyat14: "",
        fiyat15: "",
        fiyat16: "",
        urunadi1: "",
        urunadi2: "",
        urunadi3: "",
        urunadi4: "",
        urunadi5: "",
        urunadi6: "",
        urunadi7: "",
        urunadi8: "",
        urunadi9: "",
        urunadi10: "",
        urunadi11: "",
        urunadi12: "",
        urunadi13: "",
        urunadi14: "",
        urunadi15: "",
        urunadi16: "",
      },
      urun1: false,
      urun2: false,
      urun3: false,
      urun4: false,
      urun5: false,
      urun6: false,
      urun7: false,
      urun8: false,
      urun9: false,
      urun10: false,
      urun11: false,
      urun12: false,
      urun13: false,
      urun14: false,
      urun15: false,
      urun16: false,
      fontSizeFirma: "55px",
      colorFirma: "#7ccbd8",
      yaziAlaniDialog: false,
      yaziAlaniDialog4: false,
      yaziSlogan1: "Ramazan Erzurum'da",
      yaziSloganDialog: false,
    };
  },

  components: {
    VueDraggableResizable,
    brosur1: Brosur1,
    Home,
  },
  mounted() {
    this.getUrunler();
    this.getBackround();
    this.getAdresBilgi();
  },
  watch: {
    brosurTitle: (val) => {
      this.brosurTitle = val;
    },
    selectedProducts: {
      handler(val, oldVal) {
        if (val.length > 6) {
          this.urunSayisiDialog = true;
        }
      },
    },
    selectedProduct1(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product1 = urunResmi;
        this.seciliUrun.urunadi1 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat1 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct2(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product2 = urunResmi;
        this.seciliUrun.urunadi2 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat2 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct3(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product3 = urunResmi;
        this.seciliUrun.urunadi3 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat3 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct4(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product4 = urunResmi;
        this.seciliUrun.urunadi4 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat4 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct5(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product5 = urunResmi;
        this.seciliUrun.urunadi5 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat5 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct6(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product6 = urunResmi;
        this.seciliUrun.urunadi6 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat6 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct7(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product7 = urunResmi;
        this.seciliUrun.urunadi7 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat7 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct8(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product8 = urunResmi;
        this.seciliUrun.urunadi8 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat8 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct9(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product9 = urunResmi;
        this.seciliUrun.urunadi9 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat9 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct10(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product10 = urunResmi;
        this.seciliUrun.urunadi10 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat10 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct11(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product11 = urunResmi;
        this.seciliUrun.urunadi11 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat11 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct12(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product12 = urunResmi;
        this.seciliUrun.urunadi12 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat12 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct13(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product13 = urunResmi;
        this.seciliUrun.urunadi13 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat13 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct14(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product14 = urunResmi;
        this.seciliUrun.urunadi14 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat14 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct15(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product15 = urunResmi;
        this.seciliUrun.urunadi15 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat15 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
    selectedProduct16(val) {
      if (val == true) {
        console.log(this.selectedProducts[0]);
        let urunResmi = this.selectedProducts[0].blobUrl;
        this.seciliUrun.product16 = urunResmi;
        this.seciliUrun.urunadi16 = this.selectedProducts[0].urunadi;
        this.seciliUrun.fiyat16 = this.selectedProducts[0].fiyat;
        this.snackbar.status = true;
        this.snackbar.color = "green darken-3";
        this.snackbar.text = "Seçilen Ürün Broşüre Eklendi";
        this.selectedProductDialog = false;
        this.selectedProducts = [];
      }
    },
  },
  methods: {
    edityaziAciklama() {
      this.yaziAciklamaDialog = true;
    },
    deleteProductLogo() {
      this.logoResim = false;
    },
    editSlogan() {
      this.yaziSloganDialog = true;
    },
    arkaPlan1() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("brosur11")
        .doc("asil")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan2() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("2")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan3() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("3")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan4() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("4")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan5() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("5")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    arkaPlan6() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("arka-plan")
        .doc("6")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    updateBackgroundColor(event) {
      this.brosurBackground.fileName = "";
      this.brosurBackgroundColor = event.target.value;
    },

    changeColorAciklama: function (event) {
      this.yaziAciklamaRenk = event.target.value;
    },
    changeFontAlanAciklama: function (event) {
      this.yaziAciklamaBoyut = event.target.value + "px";
    },
    changeColorAdresAlan10: function (event) {
      this.adresYaziRenk.alan10 = event.target.value;
    },
    changeFontAdresAlan10: function (event) {
      this.adresYaziBoyutu.alan10 = event.target.value + "px";
    },
    changeColorAdresAlan11: function (event) {
      this.adresYaziRenk.alan11 = event.target.value;
    },
    changeFontAdresAlan11: function (event) {
      this.adresYaziBoyutu.alan11 = event.target.value + "px";
    },
    changeColorAdresAlan12: function (event) {
      this.adresYaziRenk.alan12 = event.target.value;
    },
    changeFontAdresAlan12: function (event) {
      this.adresYaziBoyutu.alan12 = event.target.value + "px";
    },
    changeColorArkaPlan1: function (event) {
      this.cardArkaPlan1 = event.target.value;
    },
    editYaziAlani() {
      this.yaziAlaniDialog = true;
    },
    editYaziAlani4() {
      this.yaziAlaniDialog4 = true;
    },
    deleteProductUrunResmi1() {
      this.selectedProductDialog = true;
      this.selectedProduct1 = false;
      this.seciliUrun.product1 = "";
    },
    deleteProductUrunResmi2() {
      this.selectedProduct2 = false;
    },
    deleteProductUrunResmi3() {
      this.selectedProduct3 = false;
    },
    deleteProductUrunResmi4() {
      this.selectedProduct4 = false;
    },
    deleteProductUrunResmi5() {
      this.selectedProduct5 = false;
    },
    deleteProductUrunResmi6() {
      this.selectedProduct6 = false;
    },
    deleteProductUrunResmi7() {
      this.selectedProduct7 = false;
    },
    deleteProductUrunResmi8() {
      this.selectedProduct8 = false;
    },
    deleteProductUrunResmi9() {
      this.selectedProduct9 = false;
    },
    deleteProductUrunResmi10() {
      this.selectedProduct10 = false;
    },

    deleteProductUrunResmi11() {
      this.selectedProduct11 = false;
    },
    deleteProductUrunResmi12() {
      this.selectedProduct12 = false;
    },
    deleteProductUrunResmi13() {
      this.selectedProduct13 = false;
    },
    deleteProductUrunResmi14() {
      this.selectedProduct14 = false;
    },
    deleteProductUrunResmi15() {
      this.selectedProduct15 = false;
    },
    deleteProductUrunResmi16() {
      this.selectedProduct16 = false;
    },
    selectProduct1() {
      this.selectedProductDialog = true;
      this.urun1 = true;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct2() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = true;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct3() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = true;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct4() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = true;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct5() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = true;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = true;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct6() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = true;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct7() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = true;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct8() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = true;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct9() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = true;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct10() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = true;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct11() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = true;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct12() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = true;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct13() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = true;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct14() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = true;
      this.urun15 = false;
      this.urun16 = false;
    },
    selectProduct15() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = true;
      this.urun16 = false;
    },
    selectProduct16() {
      this.selectedProductDialog = true;
      this.urun1 = false;
      this.urun2 = false;
      this.urun3 = false;
      this.urun4 = false;
      this.urun5 = false;
      this.urun6 = false;
      this.urun7 = false;
      this.urun8 = false;
      this.urun9 = false;
      this.urun10 = false;
      this.urun11 = false;
      this.urun12 = false;
      this.urun13 = false;
      this.urun14 = false;
      this.urun15 = false;
      this.urun16 = true;
    },
    boyutInstagramExport() {
      this.instagram = true;
      this.a4Boyut = false;
      this.a5Boyut = false;
      this.facebook = false;
      this.brosurBoyutWidth = "500px";
      this.brosurBoyutHeight = "500px";
      if (this.selectedProducts.length == 1) {
        this.brosurA4Class = false;
        this.bosluk = false;
        this.selectedProductImageUrun1 = "300px";
        this.productFiyatAvatarMarginTopUrun1 = "165px";
        this.selectedProductUrunAdiFontSizeUrun1 = "30px";
        this.cubukHeight = "15px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (this.selectedProducts.length == 2) {
        this.brosurA4Class = false;
        this.selectedProductImageUrun1 = "145px";
        this.productFiyatAvatarMarginTopUrun1 = "10px";
        this.selectedProductUrunAdiFontSizeUrun1 = "15px";
        this.cubukHeight = "15px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (
        this.selectedProducts.length > 2 &&
        this.selectedProducts.length < 5
      ) {
        this.selectedProductImage = "135px";
        this.productFiyatAvatarMarginTop = "36px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.brosurA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = true;
      } else if (
        this.selectedProducts.length > 4 &&
        this.selectedProducts.length < 7
      ) {
        this.selectedProductImage = "85px";
        this.productFiyatAvatarMarginTop = "-16px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.brosurA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = true;
      }
    },
    boyutA4Export() {
      this.instagram = false;
      this.a4Boyut = true;
      this.a5Boyut = false;
      this.facebook = false;
      this.brosurA4Class = true;
      this.brosurBoyutWidth = "595px";
      this.brosurBoyutHeight = "842px";
      if (this.selectedProducts.length == 1) {
        this.selectedProductImageUrun1 = "570px";
        this.productFiyatAvatarMarginTopUrun1 = "435px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (this.selectedProducts.length == 2) {
        this.selectedProductImageUrun1 = "295px";
        this.productFiyatAvatarMarginTopUrun1 = "160px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (
        this.selectedProducts.length > 2 &&
        this.selectedProducts.length < 5
      ) {
        this.selectedProductImage = "295px";
        this.productFiyatAvatarMarginTop = "194px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiA4 = true;
        this.selectedProductUrunAdiA5 = false;
      } else if (
        this.selectedProducts.length > 4 &&
        this.selectedProducts.length < 7
      ) {
        this.selectedProductImage = "193px";
        this.productFiyatAvatarMarginTop = "89px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiA4 = true;
        this.selectedProductUrunAdiA5 = false;
        // this.selectedProductFiyatTextA4 = true;
      }
    },
    boyutA5Export() {
      this.instagram = false;
      this.a4Boyut = false;
      this.a5Boyut = true;
      this.facebook = false;
      this.brosurBoyutWidth = " 421px";
      this.brosurBoyutHeight = "605px";
      if (this.selectedProducts.length == 1) {
        this.selectedProductUrunAdiA4 = false;
        this.selectedProductUrunAdiA5 = true;
        this.selectedProductUrunAdiFontSizeUrun1 = "20px";
        this.fontSize = "23px";
        this.cubukA5Class = true;
        this.cubukA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.cubukHeight = "20px";
        this.brosurA4Class = false;
        this.brosurA5Class = true;
        this.selectedProductImageUrun1 = "405px";
        this.productFiyatAvatarMarginTopUrun1 = "270px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (this.selectedProducts.length == 2) {
        this.selectedProductImageUrun1 = "188px";
        this.productFiyatAvatarMarginTopUrun1 = "55px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiFontSizeUrun1 = "15px";
        this.cubukHeight = "20px";
      } else if (
        this.selectedProducts.length > 2 &&
        this.selectedProducts.length < 5
      ) {
        this.selectedProductImage = "175px";
        this.productFiyatAvatarMarginTop = "75px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = false;
        this.selectedProductUrunAdiA5 = true;
        // this.selectedProductFiyatTextA4 = true;
      } else if (
        this.selectedProducts.length > 4 &&
        this.selectedProducts.length < 7
      ) {
        this.selectedProductImage = "122px";
        this.productFiyatAvatarMarginTop = "23px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.selectedProductUrunAdiA4 = false;
        this.selectedProductUrunAdiA5 = true;
        this.selectedProductFiyatTextA4 = false;
        this.selectedProductFiyatTextA5 = true;
        this.cubukA5Class = true;
        this.cubukA4Class = false;
        this.cubukHeight = "20px";
        this.fontSize = "20px";
        this.selectedProductUrunAdiFontSize = "15px";
        // this.selectedProductFiyatTextA4 = true;
      }
    },
    boyutFacebookExport() {
      this.instagram = false;
      this.a4Boyut = false;
      this.a5Boyut = false;
      this.facebook = true;
      this.brosurBoyutWidth = "600px";
      this.brosurBoyutHeight = "600px";
      if (this.selectedProducts.length == 1) {
        this.brosurA4Class = false;
        this.bosluk = false;
        this.selectedProductImageUrun1 = "390px";
        this.productFiyatAvatarMarginTopUrun1 = "250px";
        this.selectedProductUrunAdiFontSizeUrun1 = "30px";
        this.cubukHeight = "15px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (this.selectedProducts.length == 2) {
        this.brosurA4Class = false;
        this.selectedProductImageUrun1 = "195px";
        this.productFiyatAvatarMarginTopUrun1 = "60px";
        this.selectedProductUrunAdiFontSizeUrun1 = "15px";
        this.cubukHeight = "15px";
        this.productFiyatAvatarMarginLeft = "-17px";
      } else if (
        this.selectedProducts.length > 2 &&
        this.selectedProducts.length < 5
      ) {
        this.selectedProductImage = "195px";
        this.productFiyatAvatarMarginTop = "95px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.brosurA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = true;
      } else if (
        this.selectedProducts.length > 4 &&
        this.selectedProducts.length < 7
      ) {
        this.selectedProductImage = "118px";
        this.productFiyatAvatarMarginTop = "18px";
        this.productFiyatAvatarMarginLeft = "-17px";
        this.brosurA4Class = false;
        this.selectedProductUrunAdiFontSize = "15px";
        this.selectedProductUrunAdiA4 = true;
      }
    },
    getAdresBilgi() {
      db.collection("adres")
        .doc("adres")
        .onSnapshot((doc) => {
          //this.brosurTitle = doc.data().brosurTitle;
          this.adres = {
            firmaAdi: doc.data().doc.data().firmaAdi,
            alan1: doc.data().alan1,
            alan8: doc.data().alan8,
            webAdresi: doc.data().webAdresi,
          };
        });
    },
    adresKaydet() {
      this.adresEditDiaog = false;
      this.editTitleDialog = false;
      this.snackbar.status = true;
      this.snackbar.color = "green darken-3";
      this.snackbar.text = "Bilgileriniz Kaydedildi";
      db.collection("adres")
        .doc("adres")
        .update({
          firmaAdi: this.adres.firmaAdi,
          alan1: this.adres.alan1,
          alan8: this.adres.alan8,
          //  brosurTitle: this.brosurTitle,
          webAdresi: this.adres.webAdresi,
        })
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },
    deleteProductUrunResmi() {
      this.logoResim = false;
      this.logoButon = true;
    },
    uploadLogo(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingLogo = true;
        this.logo.fileName = file.name;
        var storageRef = fb.storage().ref("logo/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.logo.resim = downloadURL;
              let vm = this;
              db.collection("logo")
                .doc("logo")
                .set({
                  resim: vm.logo.resim,
                  fileName: vm.logo.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingLogo = false;
                  vm.logoButon = false;
                  vm.logoResim = true;
                  db.collection("logo")
                    .doc("logo")
                    .onSnapshot((doc) => {
                      let fileName = "";
                      fetch(doc.data().resim)
                        .then((r) => {
                          return r.blob();
                        })
                        .then((blobFile) => {
                          fileName = window.URL.createObjectURL(
                            new File([blobFile], { type: "image/jpeg" })
                          );
                          vm.logo.fileName = fileName;
                        });
                    });
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    /* Boyutlar */

    updateBackgroundImage(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingBgImage = true;
        this.brosurBackground.fileName = file.name;
        var storageRef = fb.storage().ref("background/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.brosurBackground.resim = downloadURL;
              let vm = this;
              db.collection("arka_plan")
                .doc("resim")
                .collection("brosur11")
                .doc("brosur11")
                .update({
                  resim: vm.brosurBackground.resim,
                  fileName: vm.brosurBackground.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingBgImage = false;
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    getBackround() {
      db.collection("arka_plan")
        .doc("resim")
        .collection("brosur11")
        .doc("asil")
        .onSnapshot((doc) => {
          let fileName = "";
          fetch(doc.data().resim)
            .then((r) => {
              return r.blob();
            })
            .then((blobFile) => {
              fileName = window.URL.createObjectURL(
                new File([blobFile], { type: "image/jpeg" })
              );
              this.brosurBackground.fileName = fileName;
            });
        });
    },
    changeWidthAdresAlan9: function (event) {
      this.adresAlan9Width = event.target.value + "px";
    },
    changeHeightAdresAlan9: function (event) {
      this.adresAlan9Height = event.target.value + "px";
    },
    changeColorAdresAlan10: function (event) {
      this.adresYaziRenk.alan10 = event.target.value;
    },
    changeColorAdresAlan9: function (event) {
      this.adresYaziRenk.alan9 = event.target.value;
    },
    changeFontAdresAlan9: function (event) {
      this.adresYaziBoyutu.alan9 = event.target.value + "px";
    },
    changeColorAdresAlan8: function (event) {
      this.adresYaziRenk.alan8 = event.target.value;
    },
    changeFontAdresAlan8: function (event) {
      this.adresYaziBoyutu.alan8 = event.target.value + "px";
    },
    changeColorAdresAlan1: function (event) {
      this.adresYaziRenk.webAdresi = event.target.value;
    },
    changeFontAdresAlan1: function (event) {
      this.adresYaziBoyutu.webAdresi = event.target.value + "px";
    },
    changeFontSizeFirma: function (event) {
      this.fontSizeFirma = event.target.value + "px";
    },
    changeColorFirma: function (event) {
      this.colorFirma = event.target.value;
    },
    changeFontSize: function (event) {
      this.fontSize = event.target.value + "px";
    },
    changeColor: function (event) {
      this.color = event.target.value;
    },
    brosurA1() {
      this.brosurDialog = !this.brosurDialog;
    },
    brosurA2() {
      this.brosurDialogA2 = !this.brosurDialogA2;
    },
    async print() {
      const el = this.$refs.capture;
      const options = {
        type: "dataURL",
      };
      this.output = await this.$html2canvas(el, options);
      var link = document.createElement("a");
      link.download = "brosur.png";
      link.href = this.output;
      document.body.appendChild(link);
      link.click();
    },
    makePDF() {
      this.loaderBrosur = true;
      const self = this;
      var canvas = this.$refs.capture;
      html2canvas(canvas, { type: "dataURL" }).then(function (canvas) {
        // var myImage = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
        // var link = document.createElement('a')
        // link.download = 's.png'
        // link.href = myImage
        // document.body.appendChild(link)
        // link.click()

        self.print();
      });
      setTimeout(() => {
        this.loaderBrosur = false;
      }, 1000);
    },

    newMusteriLink() {
      this.$router.push({ path: `/customer-login/${this.$route.params.id}` });
    },
    imageShowDelete() {
      this.imageShow = false;
    },
    deleteProduct(product) {
      console.log(product);
      this.selectedProducts.splice(product, 1);
    },
    deleteAdresItem(index) {
      this.adres.splice(index, 1);
    },
    editAdress() {
      this.adresEditDiaog = true;
    },
    addItem() {
      if (this.brosurUrun.urunAdi !== "") {
        this.list.push({
          urunAdi: this.brosurUrun.urunAdi,
          urunFiyat: this.brosurUrun.urunFiyat,
          bosluk: this.brosurUrun.bosluk,
          paraBirimi: this.brosurUrun.paraBirimi,
        });
        this.brosurUrun = {
          urunAdi: "",
          urunFiyat: "",
          bosluk: "  ...............................  ",
          paraBirimi: "",
        };
      }
    },
    deleteItem(index) {
      this.list.splice(index, 1);
    },
    clearProduct() {
      this.product = {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      };
    },
    updateUrunImage(e) {
      let file = e.target.files[0];
      if (file) {
        this.loadingUrunImage = true;
        this.product.fileName = file.name;
        var storageRef = fb.storage().ref("images/" + file.name);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.resim = downloadURL;
              let vm = this;
              db.collection("urun")
                .doc(vm.product.docName)
                .update({
                  resim: vm.product.resim,
                  fileName: vm.product.fileName,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  vm.loadingUrunImage = false;
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          }
        );
      }
    },
    imageUrunUpload(e) {
      let file = e;
      this.product.fileName = file.name;
      var storageRef = fb.storage().ref("images/" + file.name);
      let uploadTask = storageRef.put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        function (error) {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.product.resim = downloadURL;
            console.log("File available at", downloadURL);
            this.disabled = false;
          });
        }
      );
    },
    getUrunler() {
      db.collection("urun")
        .orderBy("id", "desc")
        .onSnapshot((querySnapshot) => {
          this.urunler = [];
          let fileName = "";
          querySnapshot.forEach((doc) => {
            fetch(doc.data().resim)
              .then((r) => {
                return r.blob();
              })
              .then((blobFile) => {
                fileName = window.URL.createObjectURL(
                  new File([blobFile], { type: "image/jpeg" })
                );
                // this.blbUrl = fileName
                this.urunler.push({
                  docName: doc.id,
                  ...doc.data(),
                  blobUrl: fileName,
                });
              });
          });
        });
    },
    deleteUrun(product) {
      let vm = this;
      vm.product.docName = product.docName;
      if (confirm("Ürünü Silmek İstediğinize emin misiniz?")) {
        db.collection("urun")
          .doc(vm.product.docName)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true;
            vm.snackbar.color = "red";
            vm.snackbar.text = "Ürün Silindi";
            //vm.clearProduct()
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    },
    editUrunList() {
      this.urunListDialog = true;
    },
    generatePdf() {},
    downloadBrosur() {},
    editTitle() {
      this.editTitleDialog = true;
    },
    newBrosurClose() {
      this.brosurSecimDialog = false;
    },
    newBrosur() {
      //this.boyutA4Export();
      this.brosurDialog = !this.brosurDialog;
      //if (this.selectedProducts.length > 1) {
      //  this.brosurDialogBoyut = 1200
      // }
    },
    saveProduct() {
      this.loader = true;
      this.urunAdiEkle();
      db.collection("urun")
        .doc(this.product.docName)
        .set({
          id: this.product.id,
          fiyat: this.product.fiyat,
          urunadi: this.product.urunadi,
          urunaciklama: this.product.urunaciklama,
          resim: this.product.resim,
          active: true,
          activeDurum: "Aktif",
          fileName: this.product.fileName,
          paraBirimi: this.product.paraBirimi,
        })
        .then((docRef) => {
          console.log("Document written with ID", docRef);
          this.loader = false;
          this.urunDialog = false;
          this.snackbar.status = true;
          this.snackbar.color = "green darken-3";
          this.snackbar.text = "Yeni Ürün Eklendi";
          //this.urunleriGoster(this.product)
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    selectedProduct(product) {
      this.selectedProducts.push(product);
      if (this.urun1 == true) {
        this.selectedProduct1 = true;
        //this.urun2 = false
      } else if (this.urun2 == true) {
        this.selectedProduct2 = true;
      } else if (this.urun3 == true) {
        this.selectedProduct3 = true;
      } else if (this.urun4 == true) {
        this.selectedProduct4 = true;
      } else if (this.urun5 == true) {
        this.selectedProduct5 = true;
      } else if (this.urun6 == true) {
        this.selectedProduct6 = true;
      } else if (this.urun7 == true) {
        this.selectedProduct7 = true;
      } else if (this.urun8 == true) {
        this.selectedProduct8 = true;
      } else if (this.urun9 == true) {
        this.selectedProduct9 = true;
      } else if (this.urun10 == true) {
        this.selectedProduct10 = true;
      } else if (this.urun11 == true) {
        this.selectedProduct11 = true;
      } else if (this.urun12 == true) {
        this.selectedProduct12 = true;
      } else if (this.urun13 == true) {
        this.selectedProduct13 = true;
      } else if (this.urun14 == true) {
        this.selectedProduct14 = true;
      } else if (this.urun15 == true) {
        this.selectedProduct15 = true;
      } else if (this.urun16 == true) {
        this.selectedProduct16 = true;
      }
    },

    newProduct() {
      this.clearProduct();
      this.title = "Yeni Ürün";
      this.urunDialog = !this.urunDialog;
    },
    editProduct(product) {
      this.title = "Ürün Güncelleme";
      this.product = product;
      this.editUrunDialog = !this.editUrunDialog;
      // console.log(this.product)
    },
    updateProduct() {
      let vm = this;
      vm.updateLoader = true;
      db.collection("urun")
        .doc(vm.product.docName)
        .update({
          urunadi: vm.product.urunadi,
          urunaciklama: vm.product.urunaciklama,
          fiyat: vm.product.fiyat,
          paraBirimi: vm.product.paraBirimi,
        })
        .then(function () {
          console.log("Document successfully updated!");
          vm.updateLoader = false;
          vm.snackbar.status = true;
          vm.snackbar.color = "sari";
          vm.snackbar.text = "Ürün Bilgileri Güncellendi";
          vm.editUrunDialog = !vm.editUrunDialog;
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },

    clearProduct() {
      this.product = {
        id: null,
        urunadi: "",
        urunaciklama: "",
        resim: "",
        docName: "",
        fiyat: "",
        active: false,
        activeDurum: "Pasif",
        fileName: "",
        paraBirimi: "",
      };
    },
    urunAdiEkle() {
      var charMap = {
        Ç: "C",
        Ö: "O",
        Ş: "S",
        İ: "I",
        I: "i",
        Ü: "U",
        Ğ: "G",
        ç: "c",
        ö: "o",
        ş: "s",
        ı: "i",
        ü: "u",
        ğ: "g",
      };
      var str = this.product.urunadi;
      var str_array = str.split("");
      for (var i = 0, len = str_array.length; i < len; i++) {
        str_array[i] = charMap[str_array[i]] || str_array[i];
      }
      str = str_array.join("");

      var clearStr = str
        .replace(" ", "")
        .replace("-", "")
        .replace(/[^a-z0-9-.çöşüğı]/gi, "");
      this.product.docName = clearStr;
      //var res = new Array();
      //res = this.kelime.split(" ");
      //var sonuc = res.join("");
      //console.log(sonuc);
    },
  },

  computed: {
    filteredList() {
      if (this.searchKey) {
        return this.urunler.filter((urun) => {
          return urun.urunadi
            .toLowerCase()
            .startsWith(this.searchKey.toLowerCase());
        });
      } else {
        return this.urunler;
      }
    },
  },
};
</script>

<style scoped>
.ze {
  width: 100%;
  height: 550px;
  position: absolute;
  top: 0;
  left: 0;
  background: url("https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg")
    no-repeat center center;
  background-size: cover;
  background-color: red;
  transform: scale(1.1);
}
.zeliha {
  background: url(../../assets/arka-plan/1.png);

  background-size: cover;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}

.brosurA4 {
  margin-top: 10px;
}
.selectedProductUrunAdiA4 {
  color: white;
  margin-top: 5px;
  margin-left: 5px;
}
.selectedProductUrunAdiA5 {
  color: white;
  margin-top: 3px;
  margin-left: 3px;
}
.selectedProductFiyatTextUrun1A4 {
  margin-top: 35px;
  margin-left: 30px;
  font-weight: 900;
  font-size: 45px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}

.selectedProductFiyatTextUrun1A5 {
  margin-top: 35px;
  margin-left: 30px;
  font-weight: 900;
  font-size: 45px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.selectedProductFiyatTextA4 {
  margin-top: 25px;
  margin-left: 23px;
  font-weight: 900;
  font-size: 25px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.selectedProductFiyatTextA5 {
  margin-top: 25px;
  margin-left: 22px;
  font-weight: 900;
  font-size: 22px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}
.turkLirasiUrun1 {
  font-size: 30px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  font-weight: 900;
}

.cubukA4 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.cubukA5 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.brosurBackground {
  position: "relative";
}
.brosurTitle {
  margin-top: 8px;
}

@media screen and (min-width: 601px) {
  .alGoturToGo {
    margin-left: 20px;
    margin-top: 70px;
  }
  .urunListBosluk {
    margin-top: 50px;
  }
  .urunListFiyat {
    margin-right: 10px;
  }
  .yesil {
    background-color: #ffe414;
    float: left;
    margin-left: 80px;
    margin-top: 20px;
  }
  .selectedProductFiyatAvatar {
    margin-top: 200px;
    margin-left: -17px;
  }
  .selectedProductFiyatAvatarA2 {
    margin-top: 100px;
    margin-left: -10px;
  }

  .selectedProductFiyatText {
    margin-top: 25px;
    margin-left: 18px;
    font-weight: 900;
    font-size: 30px;
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  }
  .turkLirasi {
    font-size: 20px;
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
    font-weight: 900;
  }

  .editAdres {
    margin-bottom: 15px;
  }
  .editAdresA2 {
    margin-bottom: 15px;
  }
  .ambalaj {
    font-size: small;
    font-weight: bolder;
    margin-left: 5px;
  }
  .productImage {
    height: 250px;
  }
  .productImageRow {
    height: 30px;
  }
}

@media (min-width: 300px) and (max-width: 601px) {
  .selectedProductImage {
    height: 150px;
  }
  .selectedProductUrunadi {
    font-size: x-small;
    margin-bottom: 6px;
  }
  .selectedProductUrunAciklama {
    font-size: 8px;
    margin-top: -16px;
  }
  .selectedProductFiyatAvatar {
    width: 65px;
    height: 65px;
    margin-top: 42px;
    margin-left: -7px;
  }
  .selectedProductFiyatText {
    font-size: 11px;
    font-weight: bolder;
    margin-top: 25px;
    margin-left: 11px;
  }

  .selectedAdresTextMedium {
    font-size: x-small;
    font-weight: bolder;
  }
  .selectedAdresTextSmall {
    font-size: xx-small;
    margin-bottom: 0px;
  }
  .yesil {
    background-color: #e3dd2f;
  }
  .bosluk {
    margin-top: 0px;
    margin-bottom: -13px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .bosluk8 {
    margin-top: 0px;
    margin-bottom: -10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .urunListFiyat {
    margin-right: 3px;
  }
  .alGoturToGo {
    font-size: 9px;
    font-weight: bolder;
  }
  .urunListBosluk {
    margin-top: 20px;
  }
  .editAdres {
    margin-bottom: 3px;
  }
  .alGoturCard {
    height: 150px;
    width: 250;
  }
  .ambalaj {
    font-size: xx-small;
    font-weight: bolder;
    margin-top: -15px;
    margin-left: 1px;
  }
  .alGoturToGoText {
    font-size: xx-small;
    font-weight: bolder;
  }
  .alGoturToGoNokta {
    font-size: 5px;
    font-weight: bolder;
  }
  .adresAlanMedium {
    font-size: 7px;
    font-weight: bolder;
  }
  .adresAlanSmall {
    font-size: 7px;
    font-weight: medium;
  }
  .adresAlanBold {
    font-size: 10px;
    font-weight: bolder;
  }
  .brosurTitle8 {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: bolder;
  }
  .brosurBtn {
    font-size: small;
    margin-right: 45px;
  }
  .productImage {
    height: 150px;
  }
  .productImageRow {
    height: 15px;
  }
  .productUrunadi {
    font-size: small;
  }
  .productFiyat {
    font-size: large;
  }
  .productActive {
    font-size: xx-small;
  }
  .productSwitch {
    font-size: xx-small;
  }
  .opacity-0 {
    opacity: 0 !important;
  }
}
</style>
